import React, { useEffect, useState } from "react";
import Content from "../../../components/Content/Content";
import { useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  FaEllipsisV,
  FaRegEdit,
  FaFilter,
  FaRegTrashAlt,
} from "react-icons/fa";
import Table from "../../../components/Table/Table";
import { Link } from "react-router-dom";
import { Environment } from "../../../environment";
import allActions from "../../../store/actions";
import EditUsuario from "./Usuario/EditUsuario";
import { UserService } from "../../../services/users";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import { StorageHelper } from "../../../helpers";

function Usuarios() {
  const [dataResponse, setDataResponse] = useState([
    {
      id: "",
      branch: "",
      companyId: "",
      departmentId: "",
      email: "",
      name: "",
      office: "",
      phone: "",
      role: "",
      status: "ACTIVE",
      type: "USER",
      edit: true,
    },
  ]);
  const { openUser } = useSelector((state) => state.user);
  const [, setPageEdit] = useState(openUser);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();

  const { type } = StorageHelper.getItem("user") || "";

  const [removeLoading, setRemoveLoading] = useState(true);

  function onHandleClickEdit(event) {
    setPageEdit(true);
    dispatch(allActions.user.setUser(true));

    setUser({
      id: event.original.id,
      branch: event.original.branch,
      companyId: event.original.companyId,
      departmentId: event.original.departmentId,
      email: event.original.email,
      name: event.original.name,
      office: event.original.office,
      phone: event.original.phone,
      role: event.original.role,
      status: event.original.status,
      type: event.original.type,
      edit: true,
    });
  }

  async function onHandleClickDelete(event) {
    if (event.original.id !== "") {
      await UserService.deleteById(event.original.id);
      const plans = await UserService.getAll(
        0,
        Environment.LIMITE_DE_LINHAS,
        ""
      );
      setDataResponse(plans.data);
    }
  }

  const newUser = () => {
    setPageEdit(true);
    dispatch(allActions.user.setUser(true));

    setUser({
      id: "",
      branch: "",
      companyId: "",
      departmentId: "",
      email: "",
      name: "",
      office: "",
      phone: "",
      role: "",
      status: "ACTIVE",
      type: "USER",
      edit: true,
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
        Cell: ({ row }) => (
          <Dropdown>
            <Dropdown.Item onClick={() => onHandleClickEdit(row)}>
              <a href="">{row.original.name}</a>
            </Dropdown.Item>
          </Dropdown>
        ),
      },
      {
        Header: "Departamento",
        accessor: "departments.description",
      },
      {
        Header: "Cargo",
        accessor: "office",
      },
      {
        Header: "Perfil",
        accessor: "type",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Status",
        accessor: "status",
        filter: "includes",
        className: "col-status",
        Cell: ({ cell }) => (
          <>
            {cell.value === "ACTIVE" ? "Ativo" : ""}
            {cell.value === "INACTIVE" ? "Inativo" : ""}
          </>
        ),
      },
      {
        Header: "Ação",
        width: 300,
        Cell: ({ row }) => (
          <Dropdown>
            <Dropdown.Toggle
              className="btn dropdown-icon"
              variant="link"
              id="dropdown-basic"
            >
              <FaEllipsisV />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item onClick={() => onHandleClickEdit(row)}>
                <FaRegEdit /> Editar
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onHandleClickDelete(row)}>
                <FaRegTrashAlt /> Excluir
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ],
    []
  );

  /**
   * Get all users
   */
  const getAll = async () => {
    const users = await UserService.getAll(0, Environment.LIMITE_DE_LINHAS, "");
    setDataResponse(users.data);
    setRemoveLoading(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  //Botão Toolbar
  const buttonToolbar = (
    <Link onClick={newUser} className="btn btn-primary ms-2" role="button">
      Novo Usuário
    </Link>
  );

  //Filtro select
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    const options = useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <div className="input-group">
        <span className="input-group-text" id="basic-addon1">
          <FaFilter />
        </span>
        <select
          name={id}
          id={id}
          className="form-select filter-select"
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">Todos Perfis</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <>
      {type === "MASTER" || type === "ADMINISTRATOR" ? (
        <div>
          {!removeLoading && (
            <Content>
              {openUser && <EditUsuario user={user} />}

              {!openUser && (
                <>
                  <h1>Painel de Usuários</h1>

                  <Table
                    columns={columns}
                    data={dataResponse}
                    buttonToolbar={buttonToolbar}
                  />
                </>
              )}
            </Content>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "150px",
            }}
          >
            {removeLoading && <Loading />}
          </div>
        </div>
      ) : (
        <Content>
          <div>Não existe permissão para acessar essa pagina.</div>
        </Content>
      )}
    </>
  );
}

export default Usuarios;
