import '../StyleBS/Modal.css'
import React from 'react';
import { useState, forwardRef, useRef, useEffect } from "react";
import { FaSearch, FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useTable, useRowSelect, useAsyncDebounce, useGlobalFilter, usePagination } from 'react-table'

//Select Row

const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef()
        const resolvedRef = ref || defaultRef
    
        useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])
    
        return (
        <>
            <input type="checkbox" className="form-check-input" ref={resolvedRef} {...rest} />
        </>
        )
    }
)
    
// Filtro Busca

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    }) {
    //const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)
    
    return (
        <>
        <div className="input-group me-3">
          <span className="input-group-text" id="basic-addon1"><FaSearch /></span>
          <input
          value={value || ""}
          onChange={e => {
              setValue(e.target.value);
              onChange(e.target.value);
          }}
          placeholder="Busca"
          className="form-control"
          aria-label="Busca" 
          aria-describedby="basic-addon1"
          />
            
        </div>
        </>
    )
    }
    
function TableCheckbox ({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        preGlobalFilteredRows,
        setGlobalFilter,
        state,
        rows,
        canPreviousPage, 
        canNextPage, 
        pageOptions, 
        pageCount, 
        gotoPage, 
        nextPage, 
        previousPage,
        prepareRow,
    } = useTable(
        {
        columns,
        data,
        },    
        useGlobalFilter,
        usePagination,
        useRowSelect,
        hooks => {
        hooks.visibleColumns.push(columns => [
            // Let's make a column for selection
            {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
                <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
            ),
            },
            ...columns,
        ])
        }
    )
    
    // Render the UI for your table
    return (
        <>        
        <div className="input-group mb-4">
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
        </div>
        <form>
            <div className="table-responsive mb-2">
                <table {...getTableProps()} className="table">
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.slice(0, 10).map((row, i) => {
                        prepareRow(row)
                        return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            {/* Paginação */}
            <div className="footer-grid">
                <div className="info-grid pt-2 pb-2 d-flex align-items-center">
                <span className="me-3 pageindex">
                    Page{' '}
                    <strong>
                    {state.pageIndex + 1} de {pageOptions.length}
                    </strong>{' '}
                </span>
                </div>
                <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-md-end">
                    <li className="page-item">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="page-link">
                        <FaAngleDoubleLeft />
                    </button>{' '}
                    </li>
                    <li className="page-item">
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} className="page-link">                              
                        <FaAngleLeft />
                    </button>{' '}
                    </li>
                    <li className="page-item">
                    <button onClick={() => nextPage()} disabled={!canNextPage} className="page-link">
                        <FaAngleRight />
                    </button>{' '}
                    </li>
                    <li className="page-item">
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="page-link">
                        <FaAngleDoubleRight />
                    </button>{' '}
                    </li>
                </ul>
                </nav>
            </div>
            <div className="d-grid gap-2 d-md-block mt-5">
                <button className="btn btn-primary" type="submit">Relacionar</button>
            </div>
    
        </form>
        </>
    )
}

export default TableCheckbox;