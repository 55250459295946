import React from "react";
import Content from "../../../../components/Content/Content";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { FaEllipsisV, FaRegTrashAlt } from "react-icons/fa";
import { PlanService } from "../../../../services/plans";
import { StorageHelper } from "../../../../helpers";

function EditPlano(data) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ value: data });

  const params = window.location.pathname.split("/");
  const { companyURL } = StorageHelper.getItem("url") || {companyURL: params[1]};
  const masterPlan = `/${companyURL}/master/planos`;

  const onSubmit = async (data) => {
    if (data.id !== "") {
      await PlanService.updateById(data.id, data);
    } else if (data.id === "") {
      await PlanService.create(data);
    }
  };

  return (
    <>
      <div className="d-flex mb-4 justify-content-between align-items-center">
        <h1>Plano</h1>

        <Dropdown>
          <Dropdown.Toggle
            className="btn dropdown-icon"
            variant="link"
            id="dropdown-basic"
          >
            <FaEllipsisV />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href={masterPlan}>
              <FaRegTrashAlt /> Excluir
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    {...register("id")}
                    type="text"
                    defaultValue={data.plan.id}
                    hidden
                  />
                  <label className="label-small" htmlFor="tituloPlano">
                    Título <span className="label-msg">*</span>
                  </label>
                  <input
                    type="text"
                    {...register("title", { required: true })}
                    defaultValue={data.plan.title}
                    className="form-control"
                    id="tituloPlano"
                  />
                  {errors.title && errors.title.type === "required" && (
                    <div role="alert" className="form-alert mb-3">
                      Este campo é obrigatório
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="label-small" htmlFor="descricaoPlano">
                    Descrição
                  </label>
                  <textarea
                    {...register("description", {})}
                    defaultValue={data.plan.description}
                    className="form-control mt-1"
                    id="descricaoPlano"
                    rows="3"
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="label-small"
                    htmlFor="limiteUsuariosCliente"
                  >
                    Limite de Usuários <span className="label-msg">*</span>
                  </label>
                  <input
                    type="number"
                    {...register("limit", { required: true })}
                    defaultValue={data.plan.limit}
                    className="form-control"
                    id="limiteUsuariosCliente"
                  />
                  {errors.limite && errors.limite.type === "required" && (
                    <div role="alert" className="form-alert mb-3">
                      Este campo é obrigatório
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small" htmlFor="valorPlanoCliente">
                    Valor <span className="label-msg">*</span>
                  </label>
                  <div className="input-group me-3">
                    <span className="input-group-text" id="basic-addon1">
                      R$
                    </span>
                    <input
                      type="text"
                      {...register("value", { required: true })}
                      defaultValue={data.plan.value}
                      className="form-control"
                      id="valorPlanoCliente"
                    />
                  </div>
                  {errors.valor && errors.valor.type === "required" && (
                    <div role="alert" className="form-alert mb-3">
                      Este campo é obrigatório
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-5">
                <button type="submit" className="btn btn-primary">
                  Cadastrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditPlano;
