import "../../components/Form/Form.css";
import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { useForm } from "react-hook-form";
import updateAction from "./updateAction";
import { useDispatch, useSelector } from "react-redux";
import ButtonBackForm from "../../components/ButtonBackForm/ButtonBackForm";
import { v4 as uuidv4 } from "uuid";
import { NarrativeService } from "../../services/narratives";
import { StorageHelper } from "../../helpers";
import allActions from "../../store/actions";

const Step5 = () => {
  const history = useNavigate();
  const { narrative } = useSelector((state) => state.narrative);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { state } = useStateMachine({ updateAction });
  const dispatch = useDispatch();
  const params = window.location.pathname.split("/");
  const { companyURL } = StorageHelper.getItem("url") || {companyURL: params[1]};
  const termos = `/${companyURL}/termos`;

  const onSubmit = async () => {
    const params = window.location.pathname.split("/");
    const { companyURL } = StorageHelper.getItem("url") || {companyURL: params[1]};
    const routes = StorageHelper.getItem("routes");
    const newRoute = routes.filter((route) => route.url === params[1]);
    const companyId = newRoute[0].id;
    const { id: idUser } = StorageHelper.getItem("user") || {id: null};
    const { id } = StorageHelper.getItem("user") || 0;
    
    const newCode = uuidv4().split("-").pop().toUpperCase();

    const payload = {
      code: newCode,
      relationshipCompany: narrative.relationshipCompany,
      classification: JSON.stringify(narrative.classification),
      subject: "--", //narrative.subject,
      description: narrative.description,
      recurrent: narrative.recurrent === 'Não Recorrente' ? false : true,
      profile: id ? "ADMINISTRATOR" : "USER",
      involved: narrative.involved,
      nameInvolved: narrative.nameInvolved,
      someOneElseInvolved: narrative.someOneElseInvolved,
      dateOccurred: narrative.dateOccurred,
      noDate: narrative.noDate,
      partDay: narrative.partDay,
      spaceOccurred: narrative.spaceOccurred,
      evidence: narrative.evidence,
      evidenceURL: JSON.stringify(narrative.evidenceURL),
      type: narrative.type,
      status: "NEW",
      messages: [],
      companyId: companyId,
      userId: idUser || null, 
    };

    await NarrativeService.create(payload);
    dispatch(allActions.narrative.setNarrative(false, payload));

    await NarrativeService.sendEmailMessage({
      narrativeId: newCode,
      companyId: companyId,
      isNewMessage: true,
      companyUrl: companyURL
    });

    history(`/${companyURL}/Relato/Enviado`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="mb-5">Resumo do Relato</h2>
      <div className="form-group mt-3">
        <span className="label-result">Caso de:</span>
        <span className="info-result">{state.classification}</span>
        <span className="txt-result">{state.recurrent}</span>
      </div>
      {/* <div className="form-group">
        <span className="label-result">O que ocorreu:</span>
        <span className="info-result">{state.subject}</span>
        <p className="txt-result">{state.description}</p>
      </div> */}
      <div className="form-group">
        <span className="label-result">A situação ocorreu:</span>
        <span className="info-result">{state.involved}</span>
      </div>
      <div className="form-group">
        <span className="label-result">Causador(es) da situação:</span>
        <span className="info-result">
          {state.nameInvolved} - {state.setorEnvolvido}
        </span>
      </div>
      <div className="form-group">
        <span className="label-result">Outro(s) presente(s) na situação:</span>
        <span className="info-result">{state.someOneElseInvolved}</span>
      </div>
      <div className="form-group">
        <span className="label-result">Data do Ocorrido:</span>
        <span className="info-result">
          {state.dateOccurred} - {state.partDay}
        </span>
        <span className="info-result">{state.noDate}</span>
      </div>
      <div className="form-group">
        <span className="label-result">Espaço do Ocorrido:</span>
        <span className="info-result">{state.spaceOccurred}</span>
      </div>
      <div className="form-group">
        <span className="label-result">Evidências:</span>
        <span className="info-result">{narrative.evidence !== "" ? narrative.evidence : "" }</span>
      </div>

      <div className="form-check mb-5 mt-5 form-group">
        <input
          className="form-check-input"
          type="checkbox"
          value="concordo"
          id="checkTermos"
          placeholder="concordo"
          {...register("concordo", { required: true })}
        />
        <label className="form-check-label" htmlFor="checkTermos">
          <span className="form-text mt-0">
            Li e concordo com os <Link target="_blank" to={termos}>Termo de Uso</Link> e{" "}
            <Link target="_blank" to={termos}>Política de Privacidade</Link>
          </span>
        </label>
        {errors.concordo && errors.concordo.type === "required" && (
          <div role="alert" className="form-alert">
            Aceite o termo para proceguir
          </div>
        )}
      </div>

      <div className="d-grid gap-2 d-md-block mt-5">
        <ButtonBackForm />
        <button className="btn btn-primary" type="submit">
          Enviar relato
        </button>
      </div>

      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
    </form>
  );
};

export default Step5;
