import "../Header/Header.css";
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { FaUser, FaSignInAlt, FaQuestionCircle } from "react-icons/fa";
import { StorageHelper } from "../../helpers";

function NavUser() {
  const { name } = !StorageHelper.getItem("user")
    ? ""
    : StorageHelper.getItem("user");

  const params = window.location.pathname.split("/");
  const { companyURL } = StorageHelper.getItem("url") || {companyURL: params[1]};
  const duvida = `/${companyURL}/duvidas`;
  const areausuario = `/${companyURL}/areausuario`;
  const login = `/${companyURL}/login`;

  return (
    <>
      <Link to={duvida} className="btn btn-link" role="button">
        <span className="text-btn">Dúvidas</span>
        <span className="icon-btn">
          <FaQuestionCircle />
        </span>
      </Link>

      {name ? (
        <Link
          to={areausuario}
          className="btn btn-outline-primary"
          role="button"
        >
          <span className="text-btn">{name ? "Meus relatos" : "Entrar"}</span>
          <span className="icon-btn">
            <FaSignInAlt />
          </span>
        </Link>
      ) : (
        <Link to={login} className="btn btn-outline-primary" role="button">
          <span className="text-btn">{name ? "Meus relatos" : "Entrar"}</span>
          <span className="icon-btn">
            <FaSignInAlt />
          </span>
        </Link>
      )}

      {name ? (
        <Dropdown>
          <Dropdown.Toggle
            align="end"
            className="btn"
            variant="link"
            id="dropdown-basic"
          >
            <FaUser />
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            <Dropdown.Item
              href="/"
              onClick={() => StorageHelper.removeItem("user")}
            >
              Sair
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        ""
      )}
    </>
  );
}

export default NavUser;
