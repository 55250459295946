import { combineReducers } from "redux";
import company from "./company.reducers";
import plan from "./plan.reducers";
import user from "./user.reducers";
import narrative from "./narrative.reducers";
import notification from "./notification.reducers";
import loading from "./loading.reducers";

export default combineReducers({
  company,
  plan,
  user,
  narrative,
  notification,
  loading
});
