import React, { useEffect } from "react";
import { createBrowserRouter } from "react-router-dom";
import { StorageHelper } from "../helpers";
import BackAdmin from "../layout/BackAdmin/BackAdmin";
import BackMaster from "../layout/BackMaster/BackMaster";
import Front from "../layout/Front/Front";
import Acompanhamento from "../pages/Acompanhamento/Acompanhamento";
import TimeLine from "../pages/Acompanhamento/TimeLine";
import Admin from "../pages/admin/Admin";
import Configuracoes from "../pages/admin/Configuracoes/Configuracoes";
import Faq from "../pages/admin/Faq/Faq";
import Relatorios from "../pages/admin/Relatorios/Relatorios";
import Relatos from "../pages/admin/Relatos/PainelRelatos";
import ViewRelato from "../pages/admin/Relatos/Relato/ViewRelato";
import Usuarios from "../pages/admin/Usuarios/PainelUsuarios";
import EditUsuario from "../pages/admin/Usuarios/Usuario/EditUsuario";
import AreaUsuario from "../pages/AreaUsuario/AreaUsuario";
import DefinirAcesso from "../pages/DefinirAcesso/DefinirAcesso";
import Duvidas from "../pages/Duvidas/Duvidas";
import { NotFoundPage } from "../pages/Errors";
import Home from "../pages/Home/Home";
import Identificacao from "../pages/Identificacao/Identificacao";
import Login from "../pages/Login/Login";
import EditCliente from "../pages/master/Clientes/Cliente/EditCliente";
import Clientes from "../pages/master/Clientes/PainelClientes";
import ConfiguracoesMaster from "../pages/master/Configuracoes/Configuracoes";
import Planos from "../pages/master/Planos/PainelPlanos";
import EditPlano from "../pages/master/Planos/Plano/EditPlano";
import RelatoriosMaster from "../pages/master/Relatorios/Relatorios";
import UploadUsuarios from "../pages/master/Upload/UploadUsuarios";
import RecuperarAcessoEtapa1 from "../pages/RecuperarAcesso/RecuperarAcessoEtapa1";
import RecuperarAcessoEtapa2 from "../pages/RecuperarAcesso/RecuperarAcessoEtapa2";
import Relacao from "../pages/Relacao/Relacao";
import Relato from "../pages/Relato/Relato";
import SolicitarCadastro from "../pages/SolicitarCadastro/SolicitarCadastro";
import Termos from "../pages/Termos/Termos";
import RedirectRespeiteme from "./RedirectRespeiteme";


const routes = StorageHelper.getItem("routes");
const { companyURL } = StorageHelper.getItem('user') || '';
const params = window.location.pathname.split("/");
let urlDefault = companyURL;

if (routes && routes.length > 0) {
  const newRoute = routes.filter((route) => route.url === params[1]);
  if (newRoute && newRoute.length > 0) {
    StorageHelper.setItem('company', newRoute[0]);
    urlDefault = newRoute[0].url;
  }
}
export const AuthenticatedRoutes = createBrowserRouter([
  {
    path: "/",
    element: <RedirectRespeiteme />,
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}`,
    element: (
      <Front page="layout-front-home">
        <Home />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/duvidas`,
    element: (
      <Front page="layout-front">
        <Duvidas />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/termos`,
    element: (
      <Front page="layout-front">
        <Termos />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/solicitar-cadastro`,
    element: (
      <Front page="layout-front">
        <SolicitarCadastro />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/recuperar-acesso-envio-email`,
    element: (
      <Front page="layout-front">
        <RecuperarAcessoEtapa1 />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/recuperar-acesso/*`,
    element: (
      <Front page="layout-front">
        <RecuperarAcessoEtapa2 />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/acesso`,
    element: (
      <Front page="layout-front">
        <DefinirAcesso />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/identificacao`,
    element: (
      <Front page="layout-front">
        <Identificacao />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/relacao`,
    element: (
      <Front page="layout-front">
        <Relacao />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/acompanhamento`,
    element: (
      <Front page="layout-front">
        <Acompanhamento />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/timeline`,
    element: (
      <Front page="layout-front">
        <TimeLine />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/areausuario`,
    element: (
      <Front page="layout-front">
        <AreaUsuario />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/relato/*`,
    element: (
      <Front page="layout-front">
        <Relato />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/admin`,
    element: (
      <BackAdmin page="layout-admin">
        <Relatos />
        {/* <Admin /> */}
      </BackAdmin>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/admin/relatos`,
    element: (
      <BackAdmin page="layout-admin">
        <Relatos />
      </BackAdmin>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/admin/usuarios`,
    element: (
      <BackAdmin page="layout-admin">
        <Usuarios />
      </BackAdmin>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/admin/usuarios/usuario`,
    element: (
      <BackAdmin page="layout-admin">
        <EditUsuario />
      </BackAdmin>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/admin/relatos/relato`,
    element: (
      <BackAdmin page="layout-admin">
        <ViewRelato />
      </BackAdmin>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/admin/relatorios`,
    element: (
      <BackAdmin page="layout-admin">
        <Relatorios />
      </BackAdmin>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/admin/faq`,
    element: (
      <BackAdmin page="layout-admin">
        <Faq />
      </BackAdmin>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/admin/configuracoes`,
    element: (
      <BackAdmin page="layout-admin">
        <Configuracoes />
      </BackAdmin>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/master/clientes`,
    element: (
      <BackMaster page="layout-master">
        <Clientes />
      </BackMaster>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/master/clientes/cliente`,
    element: (
      <BackMaster page="layout-master">
        <EditCliente />
      </BackMaster>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/master/planos/plano`,
    element: (
      <BackMaster page="layout-master">
        <EditPlano />
      </BackMaster>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/master/planos`,
    element: (
      <BackMaster page="layout-master">
        <Planos />
      </BackMaster>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/master/relatorios`,
    element: (
      <BackMaster page="layout-master">
        <RelatoriosMaster />
      </BackMaster>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/master/configuracoes`,
    element: (
      <BackMaster page="layout-master">
        <ConfiguracoesMaster />
      </BackMaster>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/master/upload`,
    element: (
      <BackMaster page="layout-master">
        <UploadUsuarios />
      </BackMaster>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/login`,
    element: (
      <Front page="layout-front">
        <Login />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
]);