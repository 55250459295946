import company from "./company.actions";
import plan from "./plan.actions";
import user from "./user.actions";
import narrative from "./narrative.actions";
import notification from "./notification.actions";
import loading from "./loading.actions";

const allActions = {
  company,
  plan,
  user,
  narrative,
  notification,
  loading
};

export default allActions;
