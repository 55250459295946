const DataClientes = () => [
    {
        nome: 'Dachser do Brasil Ltda',
        telefone: '(19) 99292-9929',
        emailresponsavel: 'fulano@dachser.com.br',
        planId: 1,
        usuarios: '220',
        status: 'Ativo',
    },
    {
        nome: 'Instituto da Imagem Ltda',
        telefone: '(19) 99292-9929',
        emailresponsavel: 'daniel@instituto.com.br',
        planId: 1,
        usuarios: '30',
        status: 'Ativo',
    },
    {
        nome: 'RES Brasil Eduardo & Cia ltda',
        telefone: '(19) 99292-9929',
        emailresponsavel: 'eduardo@res.com.br',
        planId: 1,
        usuarios: '10',
        status: 'Ativo',
    },
    {
        nome: 'Honda do Brasil SA',
        telefone: '(19) 99292-9929',
        emailresponsavel: 'fulano@honda.com.br',
        planId: 1,
        usuarios: '520',
        status: 'Ativo',
    },
  ];

export default DataClientes;