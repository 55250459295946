import "../../../../components/StyleBS/Modal.css";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button } from "react-bootstrap";
import { FaArchive } from "react-icons/fa";
import { StorageHelper } from "../../../../helpers";
import { NarrativeService } from "../../../../services/narratives";
import allActions from "../../../../store/actions";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

function StatusArquivar(props) {
  const [dataNarrative] = useState(props);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const { id: idUser, companyId, email } = StorageHelper.getItem("user");

    const arrayStatusVerification = {
      interview: {
        show: false,
        description: "Entrevista com envolvidos",
      },
      documents: {
        show: false,
        description: "Análise de documentos",
      },
      investigation: {
        show: false,
        description: "Investigação externa",
      },
      support: {
        show: false,
        description: "Apoio técnico",
      },
      calculationNote: data.calculationNote,
    };
    const arrayStatusClose = {
      approved: {
        show: false,
        description: "",
      },
      measuresTaken: {
        show: false,
        description: "",
      },
      closingNote: {
        show: false,
        description: "",
      },
    };
    const statusFiled = {
      show: true,
      description: data.notaArquivamento,
    };
    const narrative = {
      ...dataNarrative.props.narrative,
      status: "FILED",
      typeVerification: JSON.stringify(arrayStatusVerification),
      typeClosed: JSON.stringify(arrayStatusClose),
      typeFiled: JSON.stringify(statusFiled),
    };

    await NarrativeService.createLog(
      idUser,
      companyId,
      narrative,
      "UPDATED",
      JSON.stringify(arrayStatusVerification)
    );
    await NarrativeService.updateById(narrative.id, narrative);

    dispatch(allActions.narrative.setNarrative(true, narrative));
    handleClose();
    toast.success("Status do relato alterado para: Arquivado");
    handleClose();
  };

  return (
    <>
      <Button className="btn-link-content" onClick={handleShow}>
        <FaArchive /> Arquivar
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Arquivar Relato</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mt-2">
              <label htmlFor="notaArquivamento">Nota</label>
              <span className="label-sub">
                Para uso interno. Não será disponibilizado para o autor do
                relato
              </span>
              <textarea
                className="form-control mt-1"
                id="notaArquivamento"
                rows="3"
                {...register("notaArquivamento")}
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary mt-4">
              Arquivar
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StatusArquivar;
