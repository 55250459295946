import { SET_COMPANY } from "./types";

const setCompany = (openCompany) => ({
  type: SET_COMPANY,
  payload: {
    openCompany,
  },
});

export default {
  setCompany,
};
