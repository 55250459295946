import React from 'react';
import Content from '../../components/Content/Content'
import ButtonBack from '../../components/ButtonBack/ButtonBack';


function Duvidas() {
  return (
    <Content>   
      <div className="row row justify-content-md-center">
        <div className="col-md-8">
            <div className="page-header text-center">
                <ButtonBack />
                <h1>Tire suas dúvidas</h1>
            </div>					
            <div className="card">
                <div className="card-body">
                    <h3>Como fazer um relato?</h3>
                    <p>texto</p>
                    
                    <h3 className="mt-5">Como acompanhar um relato feito?</h3>
                    <p>texto</p>

                    <h3 className="mt-5">Sobre o que posso enviar um relato? O que configura uma situação de conduta indesejada?</h3>
                    <p>Você deve comunicar qualquer tipo de comportamento inapropriado que tenha acontecido com você ou com outra pessoa no seu ambiente de trabalho. Isso pode variar de piadas ofensivas a agressões físicas - ou outras situações na qual você identificar um comportamento que está desalinhado com a cultura da empresa.</p>
                    
                    <h3 className="mt-5">Posso usar a plataforma da Respeite.me mesmo se eu não tiver certeza que a minha experiência condiz com uma situação de má conduta?</h3>
                    <p>Sim. Se você se sentiu qualquer incômodo, registre o seu relato. É normal se sentir confuso sobre isso, afinal de contas existe um estigma social grande relacionado à questões de má conduta no trabalho, especialmente quando se trata de assédio e discriminação. Você não precisa saber definir ou classificar o que aconteceu com você dessa forma. Se o que aconteceu fez você se sentir desconfortável ou que você estava sendo injustamente tratado, use a Respeite.me para fazer um relato e deixe o RH da sua empresa investigar a situação. É importante que você deixe registrado o que você sentiu para que você tenha como recorrer a ajuda e apoio se precisar.</p>
                    
                    <p>Lembre-se que você sempre pode consultar os conteúdos explicativos da Respeite.me para esclarecer alguma dúvida e se mesmo assim não souber como proceder, entre em contato com a gente pelo e-mail ajuda@respeite.me que nós faremos o possível para te ajudar.</p>
                    
                    <h3 className="mt-5">E se eu ainda não estiver pronto para enviar o relato? Posso parar antes de terminar de criar um relato e voltar para editar em outro momento?</h3>
                    <p>Sim, inclusive esse é um dos principais diferenciais da plataforma da Respeite.me. Nós entendemos que a experiência de fazer um relato sobre uma situação de má conduta pode ser desgastante e a plataforma da Respeite.me está aí justamente para ajudar a tornar esse processo o mais confortável possível.</p>
                    <p>Você pode salvar o seu relato ao longo do processo e editar quantas vezes for preciso até a hora do envio, lembrando que todas as edições ficam gravadas no sistema com data e hora marcada para te ajudar a se localizar no processo e também para garantir a credibilidade do seu relato.</p>
                    <p>Sabemos que situações desconfortáveis no trabalho variam em termos de frequência e gravidade e que é comum que uma pessoa se sinta mal depois de uma certa recorrência. Por isso, a Respeite.me funciona como uma espécie de diário onde você pode registrar acontecimentos de forma privada e assim criar uma linha do tempo que ajuda você a entender a sua posição dentro da situação e também ajuda a empresa a tomar a medida correta no momento que você decidir enviar o seu relato.</p>
                    
                    <h3 className="mt-5">Como a Respeite.me protege as minhas informações?</h3>
                    <p>Privacidade e segurança da informação são prioridades no desenvolvimento da plataforma da Respeite.me. A Respeite.me nunca vai compartilhar nenhuma informação privada registrada na plataforma com nenhuma entidade terceira, a menos que o usuário faça esse requerimento ou se tivermos uma obrigação legal. Nós aplicamos práticas de criptografia padronizadas no mercado em todas a comunicação feita por meio da plataforma - nosso servidor é criptografado de ponta a ponta conforme o 256-bit AES (Advanced Encription Standard) e usamos o modelo TLS (Travel Layer Security) para que o trânsito seja feito com segurança. O nosso servidor também está adaptado à LGPD (Lei Geral de Proteção de Dados). Estamos sempre trabalhando para aprimorar e evoluir a os métodos de segurança que usamos para proteger as informações e o anonimato dos nossos usuários e clientes.</p>
                    
                    <h3 className="mt-5">O que acontece se alguém enviar um relato falso?</h3>
                    <p>Especialmente em casos de assédio e discriminação (muito por conta do estigma social relacionado a esses temas) ainda existe uma forte impressão de que falsas alegações são comuns e que muitas pessoas inocentes acabam sofrendo por conta disso. Porém, estatísticas nos mostram que isso não é verdade. Estudos realizados na Europa e nos EUA indicam taxas entre 2% e 6% de alegações enganosas. Essa impressão é reflexo da dificuldade de comprovar casos, muito porque vítimas geralmente demoram para se manifestar e porque a natureza desse tipo de abuso faz com que seja difícil coletar e apresentar provas que se enquadram nos conformes da lei. Outro ponto, que se aplica especialmente à casos de abuso sexual no qual o homem é o agressor e a mulher é a vítima, é que por conta de desigualdade de gênero um caso falso pode tomar proporções midiáticas e legais enormes enquanto 1000 casos verdadeiros são silenciados e normalizados.</p>
                    
                    <p>Entretanto, a nós entendemos o quanto uma alegação falsa pode prejudicar a vida de uma pessoa - e uma vida inocente prejudicada já é demais. Por isso, a Respeite.me tem mecanismos que diminuem as chances de um relato falso ser submetido e investigado. Primeiro, ter um visão geral do comportamento da empresa ajuda a reconhecer padrões que podem sinalizar relatos suspeitos e identificar fraude. Além disso, a função de Connect® ajuda a proteger a empresa de possíveis fraudes já que dá mais garantia que os relatos submetidos são confiáveis. Em caso de relatos anônimos, o RH tem a possibilidade de fazer um follow-up direto, o que serve de desincentivo para pessoas que possam querer fazer um relato anônimo para prejudicar outra pessoa por motivo outro.</p>
                    
                    <h3 className="mt-5">Como garantimos o anonimato no envio do relato?</h3>
                    <p>Antes de enviar o relato, você decide se quer enviá-lo em modo anônimo ou se deseja se identificar. É neste momento que guardamos ou não o seu ID de identificação. Nossa plataforma foi desenvolvida para ser, antes de tudo, segura. Para tal, a arquitetura e a modelagem de dados foram desenhadas para garantir o isolamento dos dados e assegurar a criptografia.</p>
                    <p>Dessa forma, mesmo se você optar por se identificar, não armazenamos sua identidade junto aos dados do relato. Garantimos assim que os dados da sua identificação não estejam relacionados a um relato específico, preservando a sua identidade e segurança durante todo o processo.</p>
                    
                    <h3 className="mt-5">O que acontece quando envio um relato?</h3>
                    <p>Ao enviar um relato em modo anônimo ou identificado, ele chegará no painel das pessoas administradoras em tempo real. Para nós, é fundamental que se sinta ouvido e seguro durante todo o processo, para tanto, desenvolvemos as seguintes funcionalidades:</p>
                    <p>companhamento por status: Qualquer ação que a pessoa administradora fizer em seu relato de acordo com o status (novo, aberto, em análise e resolvido) você receberá uma notificação dentro da plataforma em "Meus relatos". No caso de relatos enviados em modo anônimo, é importante que antes de enviar o relato, você copie e guarde o código do relato em um lugar seguro e de fácil acesso, pois precisará dele para acompanhar qualquer nova ação que for feita dentro do caso.</p>
                    <p>Troca de mensagens após o envio do relato: Todos os relatos enviados terão uma resposta. A plataforma da Respeite.me permite que você converse com a pessoa administradora mesmo em modo anônimo por meio de um chat. Dessa forma você poderá fazer perguntas, fazer upload de arquivos e responder possíveis dúvidas da pessoa administradora até que o caso seja resolvido.</p>
                    
                    <h3 className="mt-5">Qual é o papel da testemunha?</h3>
                    <p>É muito provável que todo mundo se encontre na posição de testemunha de um ato de má conduta em algum momento da sua carreira. Quando presenciamos algum ato de má conduta, passamos por um processo de decisão: fazemos alguma coisa ou simplesmente deixamos passar?</p>
                    <p>A testemunha tem um papel muito importante em casos de má conduta no ambiente de trabalho. Decidir tomar uma atitude, nesse caso, coloca em evidência um comportamento inaceitável ao seu responsável. Se a gente se esforçar para fazer isso sempre, mesmo se não tivermos envolvidos nos casos diretamente, podemos mudar os limites dos tipos de comportamento que são considerados normais e os que devem ser cortados.</p>
                    <p>De modo geral, ser uma testemunha ativa significa desafiar o comportamento inapropriado de alguma forma. Isso pode ser manifestado de várias formas - desde um olhar reprovador como interromper ou distrair alguém, não rir de uma piada homofóbica, machista ou racista até pedir ajuda a terceiros se for o caso. Lembrando que o mais importante, antes de qualquer coisa, é falar com a pessoa que está sendo vitimizada e oferecer apoio e ajuda.</p>
                    
                    <h3 className="mt-5">Como o Respeite.me pode ajudar</h3>
                    <h4>Preconceitos e vieses</h4>
                    <p>Quando falamos sobre viés estamos nos referindo à perspectiva das pessoas e no que elas estão predispostas a acreditar. No contexto de cultura, diversidade e inclusão o termo mais usado para descrever isso é 'preconceito'. O preconceito nem sempre é consciente, mas também nem sempre é completamente inconsciente - e por isso que é tão difícil revertê-lo. O que complica o problema mais ainda é que o preconceito se desenvolve e se reproduz por meio da socialização, sem a nossa plena consciência. Isso dificulta o auto-reconhecimento da extensão dessa distorção de perspectiva, o que por sua vez faz com que o problema continue existindo e gerando impactos negativos na sociedade. Aqui vão alguns exemplos de situações na qual um viés é reproduzido:</p>
                    <ol>
                        <li>O tipo mais comum de viés, ou preconceito, é o preconceito social, ou 'de grupo', que faz com que a gente se identifique e prefira pessoas que são parecidas com a gente. Isso se aplica tanto em relação à características sociais e demográficas quanto a gostos e personalidade.</li>					
                        <li>Um gerente acredita que jovens entendem mais de Instagram e por isso vão saber exercer a função de social media melhor dentro do time de marketing da empresa. Por acreditar nisso, essa pessoa tende a notar mais os resultados positivos do trabalho dos integrantes mais jovens do time enquanto é mais crítico com os erros dos integrantes mais velhos - mesmo se na prática o desempenho seja o mesmo. Esse tipo de preconceito pode ser chamado de 'viés de confirmação', e representa casos nos quais humanos interpretam uma situação de forma distorcida para confirmar alguma coisa que eles já estabeleceram como verdade.</li>
                    </ol>
                    <p>No ambiente de trabalho, a persistência de vários tipos de viés pode levar a situações de assédio e discriminação e acabar danificando a cultura da empresa e a produtividade dos funcionários. O viés pode afetar quem é respeitado na empresa, quem recebe créditos por boas ideias, quem é incluído em eventos sociais, e até quem é promovido e/ou demitido. Por isso, é importante ter consciência que todos nós estamos sujeitos à um pensamento preconceituoso e a tomar decisões injustas com base nele, sendo isso intencional ou não. O primeiro passo para resolver esse problema é reconhecê-lo.</p>
                
                </div>
            </div>
        </div>
    </div>
    </Content>
  );
}

export default Duvidas;
