import React from "react";
import "../../components/Form/Form.css";
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { useNavigate } from "react-router-dom";
import updateAction from "./updateAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import allActions from "../../store/actions";
import ButtonBackForm from "../../components/ButtonBackForm/ButtonBackForm";
import { NarrativeService } from "../../services/narratives";
import { StorageHelper } from "../../helpers";

const Step4 = () => {
  const params = window.location.pathname.split("/");
  const { companyURL } = StorageHelper.getItem("url") || {companyURL: params[1]};
  const { narrative } = useSelector((state) => state.narrative);
  const history = useNavigate();
  const { register, handleSubmit } = useForm();
  const { actions } = useStateMachine({ updateAction });
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const { evidence } = data;
    let uploadURLs3;
    let nameEvidence = "";

    if (evidence.length > 0) {
      uploadURLs3 = await NarrativeService.uploadS3(evidence);
      nameEvidence = data.evidence[0].name;
    }
    const narrativeNew = {
      id: "",
      code: "",
      relationshipCompany: narrative.relationshipCompany,
      classification: narrative.classification,
      subject: narrative.subject,
      description: narrative.description,
      recurrent: narrative.recurrent,
      profile: "",
      involved: narrative.involved,
      nameInvolved: narrative.nameInvolved,
      someOneElseInvolved: narrative.someOneElseInvolved,
      dateOccurred: narrative.dateOccurred,
      noDate: narrative.noDate,
      partDay: narrative.partDay,
      spaceOccurred: narrative.spaceOccurred,
      evidence: nameEvidence,
      evidenceURL: uploadURLs3 || "",
      type: narrative.type,
      status: "",
      messages: "",
      companyId: "",
      company: "Teste ",
    };
    dispatch(allActions.narrative.setNarrative(false, narrativeNew));
    actions.updateAction(data);
    history(`/${companyURL}/relato/step5`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="mb-5">Possui evidências?</h2>
      <div className="form-group mt-5">
        <label htmlFor="evidencias">
          Se sim, fique a vontade para anexar fotos, vídeos, áudios, documentos
          e prints.
        </label>
        <span className="label-sub mb-3">
          jpg, png, pdf, mp3, mp4, doc, txt
        </span>
        <input
          type="file"
          className="form-control ps-3"
          name="evidencias"
          {...register("evidence")}
        />
      </div>
      <p>
        <strong>Atenção: NÃO é obrigatório possuir evidências</strong>
      </p>
      <div className="d-grid gap-2 d-md-block mt-5">
        <ButtonBackForm />
        <button className="btn btn-primary" type="submit">
          Continuar
        </button>
      </div>
    </form>
  );
};

export default Step4;
