import "./Relatorios.css";
import React from "react";
import Content from "../../../components/Content/Content";
import { BsBuilding, BsPeople } from "react-icons/bs";
import { StorageHelper } from "../../../helpers";

function RelatoriosMaster() {
  const { type } = StorageHelper.getItem("user") || "";

  return (
    <>
      {type === "MASTER" ? (
        <div>
          <Content>
            <h1 className="mb-4">Relatórios</h1>
            <div className="row">
              <div className="col-md-4">
                <div className="card mb-4">
                  <div className="card-body d-flex align-items-center">
                    <div className="icon-box me-4">
                      <BsBuilding />
                    </div>
                    <div className="text-box">
                      <h4>Clientes</h4>
                      <span className="big-number d-block">18</span>
                      <span className="small-text d-block">Ativos</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-4">
                  <div className="card-body d-flex align-items-center">
                    <div className="icon-box me-4">
                      <BsPeople />
                    </div>
                    <div className="text-box">
                      <h4>Usuários</h4>
                      <span className="big-number d-block">1243</span>
                      <span className="small-text d-block">Ativos</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-4">
                  <div className="card-body d-flex align-items-center">
                    <div className="icon-box me-4">R$</div>
                    <div className="text-box">
                      <h4>Planos</h4>
                      <span className="big-number d-block">20.000,00</span>
                      <span className="small-text d-block">Valor total</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </div>
      ) : (
        <Content>
          <div>Não existe permissão para acessar essa pagina.</div>
        </Content>
      )}
    </>
  );
}

export default RelatoriosMaster;
