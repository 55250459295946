import '../StyleBS/Modal.css'

import React, {useState} from "react";
import {Modal, Button} from 'react-bootstrap';


function TipoRelato() {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button className="btn-link-content" onClick={handleShow}>
          tipo de relato.
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
                Dúvidas de qual relato escolher?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>Não quer que ninguém saiba sua idadentidade no relato?</h3>
            <p>No <strong>relato anônimo</strong> não será pedido nenhum tipo de informação sobre sua identidade. Para acompanhar o andamento do relato você só consiguirá acessar pelo número de protocólo gerado no envio do relato.</p>
            <h3 class="pt-4">Se identificar apenas para o administrador do seu relato?</h3>
            <p>No <strong>relato identificado</strong> sua identidade será revelada apenas para o adninistrador do relato. Para acompanhar o andamento do relato você poderá acessar pelo número de protocólo gerado no envio do relato e também na sua área de relatos.</p>
          </Modal.Body>
         
        </Modal>
      </>
    );
  }

  export default TipoRelato;