import { ApiService } from "../axios-config";
import { toast } from "react-toastify";
import { StorageHelper } from "../../helpers";

/**
 * Get all companys
 * @param page
 * @param filter
 * @returns
 */
const getAll = async (skip, take, filter, sortDirection) => {
  try {
    const url = `/company`;

    const { data } = await ApiService.get(url, {
      params: { skip, take, filter, sortDirection },
    });
    if (data) {
      return {
        data: data.data,
        totalCount: data.headers,
      };
    }

    return new Error("Erro ao listar os registros.");
  } catch (error) {
    handleApiErrors(error, "Erro ao listar os registros.");
    throw error;
  }
};

/**
 * Create company
 * @param user
 * @returns
 */
const create = async (company) => {
  try {
    const companyNew = {
      status: company.status,
      name: company.name,
      socialReason: company.socialReason,
      url: company.url,
      cnpj: company.cnpj,
      email: company.email,
      phone: company.phone,
      cellphone: company.cellphone,
      responsible: company.responsible,
      emailResponsible: company.emailResponsible,
      plansId: Number(company.plansId),
    };

    const { data } = await ApiService.post("/company", companyNew);
    getAllRoutesCompanys();

    if (data) {
      toast.success("Empresa cadastrada com sucesso.");
      return data.id;
    }


    return new Error("Erro ao criar o registro.");
  } catch (error) {
    handleApiErrors(error, "Erro ao criar o registro.");
    throw error;
  }
};

/**
 * Update company
 * @param id
 * @param company
 */
const updateById = async (id, company) => {
  try {
    const companyUpdate = {
      status: company.status,
      name: company.name,
      socialReason: company.socialReason,
      url: company.url,
      cnpj: company.cnpj,
      email: company.email,
      phone: company.phone,
      cellphone: company.cellphone,
      responsible: company.responsible,
      emailResponsible: company.emailResponsible,
      plansId: Number(company.plansId),
    };

    await ApiService.put(`/company/${Number(id)}`, companyUpdate);
    toast.success("Empresa atualizado com sucesso.");
  } catch (error) {
    handleApiErrors(error, "Erro ao atualizar o registro.");
    throw error;
  }
};

/**
 * findOne company
 * @param id
 * @param company
 */
const findOne = async (id) => {
  try {
    const url = `/company/${Number(id)}`;

    const { data } = await ApiService.get(url);
    
    if (data) {
      return {
        data: data
      };
    }

    return new Error("Erro ao listar os registros.");
  } catch (error) {
    handleApiErrors(error, "Erro ao listar o registro.");
    throw error;
  }
};

/**
 * Delete company
 * @param id
 */
const deleteById = async (id) => {
  try {
    console.log("delete id", id);
    await ApiService.delete(`/company/${id}`);
    toast.success("Empresa deletada com sucesso.");
  } catch (error) {
    handleApiErrors(error, "Erro ao apagar o registro.");
    throw error;
  }
};

/**
 * Handle api errors
 * @param error
 */
export const handleApiErrors = (error, message) => {
  if (error && error.response && error.response.data) {
    switch (error.response.data.statusCode) {
      case 400:
        toast.error(
          "Erro ao processar a requisição, verifique os dados enviados e tente novamente!"
        );
        break;
      case 404:
        toast.error("Empresa não encontrada");
        break;
      case 403:
        toast.error("Atenção!, Email ja cadastrado.");
        break;
      case 500:
        toast.error(
          "Erro, o servidor não conseguiu processar a requisição, por favor tente novamente mais tarde ou contate o suporte!"
        );
        break;
      default:
        toast.error(message);
        break;
    }
  } else {
    console.log("Erro de conexão.");
  }
};

/**
 * Get all URLs companys
 * @param page
 * @param filter
 * @returns
 */
const getAllRoutesCompanys = async () => {
  try {
    const url = `/company/findAllURLs`;

    const routes = await ApiService.get(url);
    if (routes) {
      StorageHelper.setItem("routes", routes.data);
      return routes.data;
    }
  } catch (error) {
    handleApiErrors(error, "Erro ao listar URLs.");
    throw error;
  }
};

export const CompanysService = {
  getAll,
  create,
  updateById,
  deleteById,
  getAllRoutesCompanys,
  findOne,
};
