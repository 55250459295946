import { AppConfig } from '../config';

function getKey(key) {
  return AppConfig.STORAGE_BASE_KEY + key;
}

function getItem(key) {
  key = getKey(key);
  if (!key) return;
  try {
    const item = localStorage.getItem(key) || 0;
    if (!item || item === 0) return;
    if (item) {
      return JSON.parse(item);
    }
  } catch (error) {
    console.error('error', error);
    return '';
  }
}

function setItem(key, data) {
  key = getKey(key);
  localStorage.setItem(key, JSON.stringify(data));
}

function removeItem(key) {
  key = getKey(key);
  localStorage.removeItem(key);
  localStorage.removeItem('@respeiteme_token');
}

export const StorageHelper = {
  getItem,
  setItem,
  removeItem,
};