import { SET_NARRATIVE } from "./types";

const setNarrative = (openNarrative, narrative) => ({
  type: SET_NARRATIVE,
  payload: {
    openNarrative,
    narrative,
  },
});

export default {
  setNarrative,
};
