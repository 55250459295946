import '../Header/Header.css'
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaUser } from 'react-icons/fa';
import { StorageHelper } from '../../helpers';

function NavMaster() {    
    return (

        <div className="nav-master">
            <Dropdown>
                <Dropdown.Toggle className="btn" variant="link" id="dropdown-basic">
                    <FaUser />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                    <Dropdown.Item href="/" onClick={() => StorageHelper.removeItem('user')}>Sair</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
  }
  
  export default NavMaster;