import './ButtonBack.css'
import '../Button/Button.css'
import React from 'react';
import { BsChevronLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

function ButtonBack() {
  return (
    <>
        <Link role="button" className="btn btn-voltar btn-link" to={-1}><BsChevronLeft /></Link>
    </>
  );
}

export default ButtonBack;