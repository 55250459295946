import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CompanysService } from "../../../../services/empresas";
import { PlanService } from "../../../../services/plans";
import { Environment } from "../../../../environment";
import { StorageHelper } from "../../../../helpers";

function EditCliente(data) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [dataPlan, setDataPlan] = useState();
  const [selectedRadio, setSelectedRadio] = useState(
    data.company.status || "ACTIVE"
  );

  const { companyURL } = StorageHelper.getItem("url") || "";
  const master = `/${companyURL}/master/clientes`;

  const history = useNavigate();

  if (data.company.edit === null) {
    history(master);
  }

  const onSubmit = async (data) => {
    data.status = selectedRadio;
    if (data.id !== "") {
      await CompanysService.updateById(data.id, data);
    } else if (data.id === "") {
      await CompanysService.create(data);
    }
  };

  const getAllPlan = async () => {
    const plan = await PlanService.getAll(0, Environment.LIMITE_DE_LINHAS, "");
    setDataPlan(plan.data);
  };

  useEffect(() => {
    getAllPlan();
    console.log("data -> ", data.company);
  }, []);

  const handleEmail = () => {
    // toast.success("E-mail enviado!");
  };

  const handleChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  return (
    <>
      <h1 className="mb-4">Cliente</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small mb-3">Status do Cliente</label>
                  <div className="form-check">
                    <input
                      {...register("id")}
                      type="text"
                      defaultValue={data.company.id}
                      hidden
                    />
                    <input
                      {...register("status")}
                      type="radio"
                      className="form-check-input"
                      name="statusUsuario"
                      id="statusAtivo"
                      value="ACTIVE"
                      checked={selectedRadio === "ACTIVE"}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="statusAtivo">
                      Ativo
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      {...register("status")}
                      type="radio"
                      className="form-check-input"
                      name="statusUsuario"
                      id="statusInativo"
                      value="INACTIVE"
                      checked={selectedRadio === "INACTIVE"}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="statusInativo">
                      Inativo
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box-destaque">
                  <div className="form-group">
                    <label className="label-small" htmlFor="planoCliente">
                      Plano <span className="label-msg">*</span>
                    </label>
                    <div className="form-group">
                      <select
                        {...register("plansId", { required: true })}
                        value={data.plansId}
                        className="form-select"
                        id="planoCliente"
                      >
                        {dataPlan &&
                          dataPlan.map((plan) => (
                            <option
                              value={plan.id}
                              key={plan.id}
                              selected={Number(plan.id) === Number(data.company.plansId)}
                            >
                              {plan.title}
                            </option>
                          ))}
                        {data.plansId === "" && (
                          <option value="" key="">
                            Selecione
                          </option>
                        )}
                      </select>
                      {errors.plansId && errors.plansId.type === "required" && (
                        <div role="alert" className="form-alert mb-3">
                          Selecione um plano
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="label-small" htmlFor="nomeCliente">
                    Nome da Empresa <span className="label-msg">*</span>
                  </label>
                  <input
                    type="text"
                    {...register("name", { required: true })}
                    defaultValue={data.company.name}
                    className="form-control"
                    id="nomeCliente"
                  />
                  {errors.name && errors.name.type === "required" && (
                    <div role="alert" className="form-alert mb-3">
                      Este campo é obrigatório
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="label-small" htmlFor="socialReason">
                    Razão Social
                  </label>
                  <input
                    type="text"
                    {...register("socialReason")}
                    defaultValue={data.company.socialReason}
                    className="form-control"
                    id="socialReason"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small" htmlFor="urlCliente">
                    URL <span className="label-msg">*</span>
                  </label>
                  <div className="input-group me-3">
                    <span className="input-group-text" id="basic-addon1">
                      http://respeite.me/
                    </span>
                    <input
                      type="text"
                      {...register("url", { required: true })}
                      defaultValue={data.company.url}
                      className="form-control"
                      id="urlCliente"
                      aria-label="URL"
                      aria-describedby="basic-addon1"
                    />
                    {errors.url && errors.url.type === "required" && (
                      <div role="alert" className="form-alert mb-3">
                        Este campo é obrigatório
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small" htmlFor="cnpj">
                    CNPJ
                  </label>
                  <InputMask
                    mask="99.999.999/9999-99"
                    type="text"
                    {...register("cnpj")}
                    defaultValue={data.company.cnpj}
                    className="form-control"
                    id="cnpj"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <h3 className="mt-3 mb-3">Contatos Empresa</h3>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small" htmlFor="email">
                    E-mail
                  </label>
                  <input
                    type="email"
                    {...register("email")}
                    className="form-control"
                    defaultValue={data.company.email}
                    id="email"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small" htmlFor="phone">
                    Telefone
                  </label>
                  <InputMask
                    mask="(99) 9999-9999"
                    type="tel"
                    {...register("phone")}
                    defaultValue={data.company.phone}
                    className="form-control"
                    id="phone"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small" htmlFor="cellphone">
                    Celular
                  </label>
                  <InputMask
                    mask="(99) 99999-9999"
                    type="tel"
                    {...register("cellphone")}
                    defaultValue={data.company.cellphone}
                    className="form-control"
                    id="cellphone"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <h3 className="mt-3 mb-3">Contato Responsável</h3>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small" htmlFor="responsible">
                    Nome do Responsável
                  </label>
                  <input
                    type="text"
                    {...register("responsible")}
                    defaultValue={data.company.responsible}
                    className="form-control"
                    id="responsible"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small" htmlFor="emailResponsible">
                    E-mail do Responsável
                  </label>
                  <input
                    type="email"
                    {...register("emailResponsible")}
                    defaultValue={data.company.emailResponsible}
                    className="form-control"
                    id="emailResponsible"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {/* Apenas no Edit */}.
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="password" className="form-label label-small">
                    Enviar um link para o responsável{" "}
                    <strong>redefinir a senha</strong>
                  </label>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm d-block mt-1"
                    onClick={handleEmail}
                  >
                    Enviar link para redefinição
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-5">
                <button type="submit" className="btn btn-primary">
                  Cadastrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditCliente;
