import "../Header/Header.css";
import React, { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FaUser, FaBell } from "react-icons/fa";
import { StorageHelper } from "../../helpers";
import { Environment } from "../../environment";
import { useState } from "react";
import { NotificationService } from "../../services/notification";
import allActions from "../../store/actions";
import { useDispatch } from "react-redux";

function NavAdmin() {
  const [notification, setNotification] = useState([]);
  const dispatch = useDispatch();

  /**
   * Get all notification
   */
  const getAllNotification = async () => {
    const getNotification = await NotificationService.getAll(
      0,
      Environment.LIMITE_DE_LINHAS,
      ""
    );

    if (getNotification.data.length > 0) {
      // const transform = getNotification.data.map((item) => {
      //     const typeVerification = JSON.parse(item.narrative.typeVerification);
      //     const typeClosed = JSON.parse(item.narrative.typeClosed);

      //     const notificationTransform = {
      //         ...item,
      //         narrative: { ...item.narrative, typeVerification, typeClosed }
      //     }
      //     return notificationTransform;
      // })

      setNotification(getNotification.data);

      const { companyId } = StorageHelper.getItem("user");

      const notification = {
        qtd: (getNotification.data.filter((item) => item.narrative.status === 'NEW' && item.companyId === companyId).length),
      }
      dispatch(allActions.notification.setNotification(notification));
      console.log("getNotification -> ", getNotification.data);
    }
  };

  async function handlerNotification(item) {
    dispatch(allActions.narrative.setNarrative(true, item.narrative));
    const newNotification = {
      description: item.description,
      companyId: item.companyId,
      userId: item.userId,
      narrativeId: item.narrativeId,
      type: item.type,
      visualized: true
    };
    await NotificationService.updateById(item.id, newNotification);
    dispatch(allActions.notification.setNotification({ qtd: (notification.length - 1) }));
    getAllNotification();
  }

  useEffect(() => {
    getAllNotification();
  }, []);

  return (
    <div className="nav-admin d-flex">
      {/* <Dropdown>
        <Dropdown.Toggle
          className="btn dropdown-icon"
          variant="link"
          id="dropdown-basic"
        >
          <span className="position-relative">
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-aberto py-1 px-1">
              <span className="visually-hidden">Notificação</span>
            </span>
            <FaBell />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          {notification.map((item) => (
            <>
              {!item.visualized && (
                <Dropdown.Item
                  key={item.id}
                  onClick={() => handlerNotification(item)}
                >
                  <small>{`${item.description}`}</small>
                </Dropdown.Item>
              )}
            </>
          ))}
        </Dropdown.Menu>
      </Dropdown> */}

      <Dropdown>
        <Dropdown.Toggle className="btn" variant="link" id="dropdown-basic">
          <FaUser />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            href="/"
            onClick={() => StorageHelper.removeItem("user")}
          >
            Sair
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default NavAdmin;
