import { ApiService } from "../axios-config";
import { toast } from 'react-toastify';

/**
 * Get all notification
 * @param page
 * @param filter
 * @returns
 */
const getAll = async (skip, take, filter, sortDirection) => {
  try {
    const url = `/notification`;

    const { data } = await ApiService.get(url, {
      params: { skip, take, filter, sortDirection },
    });
    if (data) {
      return {
        data: data.data,
        totalCount: data.headers,
      };
    }

    return new Error("Erro ao listar os registros.");
  } catch (error) {
    handleApiErrors(error, "Erro ao listar os registros.");
    throw error;
  }
};

/**
 * Create notification
 * @param narrative, idUser, idNarrative
 * @returns
 */
const create = async (narrative, idUser, idNarrative) => {
  try {
    const notification = {
      description: `Novo relato: ${narrative.code}`,
      companyId: narrative.companyId,
      userId: idUser || null,
      narrativeId: idNarrative,
      type: narrative.type,
      visualized: false,
    };
    await ApiService.post("/notification", notification);
  } catch (error) {
    handleApiErrors(error, "Erro ao enviar notification.");
    throw error;
  }
};

/**
 * Update notification
 * @param id
 * @param user
 */
const updateById = async (id, notification) => {
  try {

    await ApiService.put(`/notification/${Number(id)}`, notification);
    toast.success("Notificação atualizado com sucesso.");
  } catch (error) {
    handleApiErrors(error, "Erro ao atualizar o registro.");
    throw error;
  }
};

/**
 * Handle api errors
 * @param error
 */
export const handleApiErrors = (error, message) => {
  if (error && error.response && error.response.data) {
    switch (error.response.data.statusCode) {
      case 400:
        console.log(
          "Erro ao processar a requisição, verifique os dados enviados e tente novamente!"
        );
        break;
      case 404:
        console.log("Empresa não encontrada");
        break;
      case 500:
        console.log(
          "Erro, o servidor não conseguiu processar a requisição, por favor tente novamente mais tarde ou contate o suporte!"
        );
        break;
      default:
        console.log(message);
        break;
    }
  } else {
    console.log("Erro de conexão.");
  }
};

export const NotificationService = {
  getAll,
  create,
  updateById
};
