import { useEffect } from "react";

const RedirectRespeiteme = () => {
  useEffect(() => {
    window.location.href = "https://respeiteme.com.br";
  }, []);

  return null;
};

export default RedirectRespeiteme;