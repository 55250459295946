import "./Chat.css";
import React from "react";
import SendMensagem from "./SendMensagem";
import { StorageHelper } from "../../helpers";
import moment from 'moment';

function Chat(narrative) {
  const { name } = StorageHelper.getItem("user");

  function getMsg(message, classType) {
    return (
      <div className={classType}>
        <span className="autor-mensagem">
          {message.responsible}, {moment(message.createAt).format('DD/MM/YYYY')}
        </span>
        <div className="txt-mensagem mb-3">
          <p>{message.description}</p>
          {!!message.uploadName && message.uploadName !== "not file" && (
            <p>upload: <a href={message.uploadURL} target="_blank" rel="noopener noreferrer"> {message.uploadName}</a></p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="box-chat mt-4">
      {narrative.data && narrative.data.messages && narrative.data.messages.map((message) => {
        if (name === message.responsible) {
          return getMsg(message, "box-mensagem mensagem-enviada");
        } else {
          return getMsg(message, "box-mensagem mensagem-recebida");
        }
      })}

      <SendMensagem narrative={narrative.data} />
    </div>
  );
}

export default Chat;
