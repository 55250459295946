import { SET_PLAN } from "./types";

const setPlan = (openPlan) => ({
  type: SET_PLAN,
  payload: {
    openPlan,
  },
});

export default {
  setPlan,
};
