import React from 'react';
import loading from '../../assets/img/loading.gif';
import styles from './loading.css';

function Loading() {

    return (
        <div className={styles.loader_container}>
            <img className={styles.loader} src={loading} alt="Loading" />
        </div>
    )
}

export default Loading