import "./SideBar.css";
import React, { useEffect, useState } from "react";
import { BsEnvelope, BsPeople, BsBarChart, BsGear } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import allActions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Environment } from "../../environment";
import { NotificationService } from "../../services/notification";
import { StorageHelper } from "../../helpers";

function SideBarAdmin() {
  const dispatch = useDispatch();
  const { notification } = useSelector((state) => state.notification);

  const { companyURL } = StorageHelper.getItem("url") || "";
  const adminRelatos = `/${companyURL}/admin/relatos`;
  const adminUsuarios= `/${companyURL}/admin/usuarios`;
  const adminRelatorios= `/${companyURL}/admin/relatorios`;
  const adminConfiguracoes= `/${companyURL}/admin/configuracoes`;

  return (
    <div className="sidebar d-flex flex-column flex-shrink-0">
      <ul className="nav nav-pills nav-flush mb-auto text-center">
        <li className="nav-item">
          <NavLink
            to={adminRelatos}
            className={(isActive) =>
              "nav-link py-3" + (!isActive ? " unselected" : "")
            }
          >
            <span className="position-relative">
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-aberto">
                {notification === undefined ? 0 : notification.qtd}
              </span>
              <BsEnvelope />
            </span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={adminUsuarios}
            onClick={() => dispatch(allActions.company.setPlan(false))}
            className={(isActive) =>
              "nav-link py-3" + (!isActive ? " unselected" : "")
            }
          >
            <BsPeople />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={adminRelatorios}
            className={(isActive) =>
              "nav-link py-3" + (!isActive ? " unselected" : "")
            }
          >
            <BsBarChart />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={adminConfiguracoes}
            className={(isActive) =>
              "nav-link py-3" + (!isActive ? " unselected" : "")
            }
          >
            <BsGear />
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default SideBarAdmin;
