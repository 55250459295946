import "./Home.css";
import icone1 from "../../assets/img/icone1.png";
import icone2 from "../../assets/img/icone2.png";
import icone3 from "../../assets/img/icone3.png";
import React, { useEffect } from "react";
import Content from "../../components/Content/Content";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { StorageHelper } from "../../helpers";
import { CompanysService } from "../../services/empresas";

function Home() {
  const params = window.location.pathname.split("/");
  const { companyURL } = StorageHelper.getItem("url") || {companyURL: params[1]};
  const identificacao = `/${companyURL}/identificacao`;
  const acompanhamento = `/${companyURL}/acompanhamento`;

  async function loadULRs() {
    await CompanysService.getAllRoutesCompanys();
  }

  useEffect(() => {
    loadULRs();
  }, []);

  return (
    <Content>
      <div className="home column-container">
        <div className="card">
          <div className="card-body">
            <Carousel variant="dark" nextIcon={false} prevIcon={false}>
              <Carousel.Item>
                <figure>
                  <img
                    src={icone1}
                    alt="Respeite.me"
                    className="d-block carousel-icon"
                  />
                </figure>
                <Carousel.Caption>
                  <h2>Sua identidade preservada</h2>
                  <p className="pb-4">
                    Fique tranquilo, sua identidade não será divulgada e nem
                    exposta ao(s) envolvido(s). Nosso ambiente é totalmente
                    seguro, feito para te ouvir e resolver o ocorrido com o
                    máximo de discrição.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <figure>
                  <img
                    src={icone2}
                    alt="Respeite.me"
                    className="d-block carousel-icon"
                  />
                </figure>
                <Carousel.Caption>
                  <h2>Acompanhe em tempo real</h2>
                  <p className="pb-4">
                    Você pode acompanhar o relato durante toda a investigação do
                    caso. É possível fazer perguntas e responder dúvidas após o
                    envio do relato, mesmo em modo anônimo.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <figure>
                  <img
                    src={icone3}
                    alt="Respeite.me"
                    className="d-block carousel-icon"
                  />
                </figure>
                <Carousel.Caption>
                  <h2>Em qualquer lugar</h2>
                  <p className="pb-4">
                    Faça o relato e acompanhe do lugar que preferir e de
                    qualquer dispositivo que tenha internet. A plataforma é
                    totalmente segura e criptografada de ponta a ponta.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
            <div className="button-group">
              <Link
                to={identificacao}
                className="btn btn-lg btn-block btn-primary"
                role="button"
              >
                Fazer Relato
              </Link>
              <Link
                to={acompanhamento}
                className="btn btn-lg btn-block btn-outline-primary"
                role="button"
              >
                Acompanhar Relato
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
}

export default Home;
