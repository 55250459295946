import React, { useEffect, useState } from "react";
import Content from "../../../components/Content/Content";
import { useMemo } from "react";
import EditPlano from "./Plano/EditPlano";
import allActions from "../../../store/actions";
import Dropdown from "react-bootstrap/Dropdown";
import { FaEllipsisV, FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import Table from "../../../components/Table/Table";
import { Link } from "react-router-dom";
import DataPlanos from "../../../data/DataPlanos";
import { PlanService } from "../../../services/plans";
import { Environment } from "../../../environment";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import { StorageHelper } from "../../../helpers";

function Planos() {
  const [dataResponse, setDataResponse] = useState(DataPlanos);
  const { openPlan } = useSelector((state) => state.plan);
  const [, setPageEdit] = useState(openPlan);
  const [plan, setPlan] = useState(null);
  const dispatch = useDispatch();

  const { type } = StorageHelper.getItem("user") || "";

  const [removeLoading, setRemoveLoading] = useState(true);

  function onHandleClickEdit(event) {
    setPageEdit(true);
    dispatch(allActions.plan.setPlan(true));

    setPlan({
      id: event.original.id,
      title: event.original.title,
      description: event.original.description,
      limit: event.original.limit,
      value: event.original.value,
      totalMembership: event.original.totalMembership,
      companyId: event.original.companyId,
      edit: true,
    });
  }

  async function onHandleClickDelete(event) {
    if (event.original.id !== "") {
      await PlanService.deleteById(event.original.id);
      const plans = await PlanService.getAll(
        0,
        Environment.LIMITE_DE_LINHAS,
        ""
      );
      setDataResponse(plans.data);
    }
  }

  const newPlan = () => {
    setPageEdit(true);
    dispatch(allActions.plan.setPlan(true));
    setPlan({
      id: "",
      title: "",
      description: "",
      limit: "",
      value: "",
      totalMembership: "",
      companyId: "",
      edit: true,
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Título",
        accessor: "title",
        Cell: ({ row }) => (
          <Dropdown>
            <Dropdown.Item onClick={() => onHandleClickEdit(row)}>
              <a href="">{row.original.title}</a>
            </Dropdown.Item>
          </Dropdown>
        ),
      },
      {
        Header: "Limite de usuários",
        accessor: "limit",
      },
      {
        Header: "Valor",
        accessor: "value",
      },
      {
        Header: "Total de Adesão",
        accessor: "totalMembership",
      },
      {
        Header: "Ação",
        width: 300,
        Cell: ({ row }) => (
          <Dropdown>
            <Dropdown.Toggle
              className="btn dropdown-icon"
              variant="link"
              id="dropdown-basic"
            >
              <FaEllipsisV />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item onClick={() => onHandleClickEdit(row)}>
                <FaRegEdit /> Editar
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onHandleClickDelete(row)}>
                <FaRegTrashAlt /> Excluir
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ],
    []
  );

  /**
   * Get all plans
   */
  const getAll = async () => {
    const plans = await PlanService.getAll(0, Environment.LIMITE_DE_LINHAS, "");
    console.log("plans", plans);
    setDataResponse(plans.data);
    setRemoveLoading(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  //Botão Toolbar
  const buttonToolbar = (
    <Link onClick={newPlan} className="btn btn-primary ms-2" role="button">
      Novo Plano
    </Link>
  );

  return (
    <>
      {type === "MASTER" ? (
        <div>
          {!removeLoading && (
            <Content>
              {openPlan && <EditPlano plan={plan} />}

              {!openPlan && (
                <>
                  <h1>Painel de Planos</h1>

                  <Table
                    columns={columns}
                    data={dataResponse}
                    buttonToolbar={buttonToolbar}
                  />
                </>
              )}
            </Content>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "150px",
            }}
          >
            {removeLoading && <Loading />}
          </div>
        </div>
      ) : (
        <Content>
          <div>Não existe permissão para acessar essa pagina.</div>
        </Content>
      )}
    </>
  );
}

export default Planos;
