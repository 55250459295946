import '../StyleBS/Modal.css'
import React from 'react';
import { useState } from "react";
import { Modal, Button } from 'react-bootstrap';


function DuvidaClassificacao() {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>       
        <Button className="btn-link-content" onClick={handleShow}>
            clique aqui
        </Button>
  
        <Modal show={show} onHide={handleClose} >
          <Modal.Header closeButton>
            <Modal.Title>
                Dúvidas para classificar o relato?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <h3>
                O que é discriminação?
            </h3>
            <p>
                A discriminação no ambiente de trabalho acontece quando há distinção de tratamento e/ou oportunidades dadas à funcionários por razões que não estão relacionadas aos requisitos do seu cargo/função. Essa distinção é feita em relação ao contexto social da vítima - os tipos mais comuns de discriminação são ataques à raça (racismo), identidade de gênero (machismo, transfobia) e orientação sexual (homofobia). Além destes atributos, deficiência física ou intelectual, classe social, nacionalidade, estado civil/familiar, religião, idade - entre outros marcadores sociais - também podem ser alvo de ações discriminatórias. As situações mais discutidas são atos de discriminação direta.
            </p>
            <h3>
                O que é assédio sexual?
            </h3>
            <p>
                O Assédio sexual é caracterizado por qualquer comportamento indesejado de cunho sexual que tenha como objetivo perturbar, constranger, humilhar e/ou intimidar uma pessoa. Um ato de assédio sexual pode ser físico ou verbal. O que caracteriza o assédio sexual é um comportamento invasivo que ataca a liberdade sexual da vítima.
            </p>
            <h3>
                O que é assédio moral?
            </h3>
            <p>
                Casos de assédio moral consistem em situações na qual uma pessoa ofende, constrange, humilha outra pessoa, atacando a sua dignidade e personalidade. Como com assédio sexual, situações de assédio moral tem como objetivo fragilizar, ridicularizar e menosprezar a vítima.
            </p>
            <h3>
                O que é considerado bullying?
            </h3>
            <p>
                O bullying é a prática de atos intencionais e repetidos que podem causar danos físicos e/ou psicológicas à alguém. Esses atos podem incluir tocar, bater, socar, zombar, ridicularizar, expor etc e geralmente são praticados na frente de outras pessoas com o objetivo de humilhar a vítima. É comum que a vítima não consiga se defender e se sinta intimidada pela situação por motivos ligadas ao meio social que ela está inserida. Atos de bullying geralmente começam pequenos e vão ficando mais recorrentes. Outro sinal do bullying é o elemento coletivo, que amplifica significativamente os efeitos negativos sobre a vítima. Em boa parte dos casos de bullying, o principal responsável acaba influenciando outras pessoas mais fracas que estão ao seu redor a participarem das agressões. Isso ajuda o bully a se esconder de modo que se o comportamento for identificado, o grupo passa a representar o problema - e não só ele como indivíduo.
            </p>				
            <h3>
                O que caracteriza o abuso de poder?
            </h3>
            <p>
                Abuso de poder significa uma pessoa se aproveitar do seu cargo ou função na empresa para satisfazer interesses pessoais. Na maioria dos casos, quem abusa do poder está numa posição hierárquica superior dentro da empresa a quem está sendo abusado(a), mas manifestações de abuso de poder também podem acontecer por conta de diferenças de contexto social e não só em relação à cargo/função profissional. O abuso de poder pode ser manifestado de forma verbal, física, psicológica e até financeira.
            </p>			
            
            <h3>
                Fraude e corrupção
            </h3>
            <p>
                Fraude significa um ato que tem como intenção enganar ou prejudicar alguém. A diferença entre fraude e corrupção é que o fraude não está necessariamente ligado à dinheiro. Exemplos de tipos de fraude que podem acontecer no ambiente de trabalho são:
            </p>
            <ul>
                <li>Manipulação, falsificação ou alteração de registros ou documentos;</li>
                <li>Omissão de transações e/ou adoção de práticas contábeis inadequadas;</li>
                <li>Burlar algum tipo de controle/processo interno para ganho próprio;</li>
                <li>A corrupção é um tipo de fraude. Um ato corrupto é um tipo de comportamento desonesto e/ou ilegal que envolve a troca de dinheiro, valores ou serviços para benefício próprio. No ambiente de trabalho atos de corrupção geralmente se enquadram nas seguintes categorias:</li>
                <li>Favorecer alguém prejudicando outros;</li>
                <li>Desviar verbas da empresa para benefício próprio;</li>
                <li>Oferecer ou aceitar suborno.</li>
            </ul>
            <h3>
                Diferenças entre casos de má conduta
            </h3>
            <p>
                Assédio e bullying, em teoria, significam quase a mesma coisa - são atos de abuso de poder. A diferença, em teoria, é que atos de assédio estão sempre ligados à algum atributo social, seja classe, raça, gênero, idade, etc da vítima ou posição de hierarquia na empresa. O bullying, por sua vez, pode incluir também agressões ligadas à características de personalidade da vítima e não precisa necessariamente está ligado à alguma posição de poder social ou organizacional. Na prática, a diferença está no uso dos termos. O termo bullying é geralmente usado para se referir à situações de agressão recorrente porém percebidas como menos graves, já o termo assédio é usado para descrever incidentes vistos como mais severos - sejam eles recorrentes ou não.
            </p>

          </Modal.Body>
        </Modal>
      </>
    );
  }

  export default DuvidaClassificacao;