import "./Form.css";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { handleApiErrors } from "../../services/login";
import { useAuthContext } from "../../contexts";
import Loading from "../Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../store/actions";

function FormLogin() {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { login } = useAuthContext();

  const { loading } = useSelector((state) => state.loading);

  const [removeLoading, setRemoveLoading] = useState(loading);
  const dispatch = useDispatch();

  const onSubmit = async (value) => {
    setRemoveLoading(true);
    dispatch(allActions.loading.setLoading(true))
    try {
      await login(value.email, value.password, dispatch);      
    } catch (error) {
      setRemoveLoading(false);  
      handleApiErrors(error, "E-mail e/ou senha são inválidos");    
    }
  };

  return (
    <>
      {!removeLoading && <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label htmlFor="loginEmail" className="form-label label-small">
            E-mail
          </label>
          <input
            type="email"
            {...register("email", { required: true })}
            className="form-control"
            id="loginEmail"
          />
          {errors.email && errors.email.type === "required" && (
            <div role="alert" className="form-alert mb-3">
              Este campo é obrigatório
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="loginSenha" className="form-label label-small">
            Senha
          </label>
          <input
            type="password"
            {...register("password", { required: true })}
            className="form-control"
            id="loginSenha"
            aria-label="Senha"
          />
          {errors.password && errors.password.type === "required" && (
            <div role="alert" className="form-alert mb-3">
              Este campo é obrigatório
            </div>
          )}
        </div>
        <div className="form-group form-check mb-4">
          <input type="checkbox" className="form-check-input" id="lembreMe" />
          <label className="form-check-label" htmlFor="lembreMe">
            <span className="form-text mt-0">Lembre-me</span>
          </label>
        </div>
        <button type="submit" className="btn btn-primary">
          Entrar
        </button>
      </form>}

      {removeLoading && <Loading />}
    </>
  );
}

export default FormLogin;
