const DataRelatos = () => [
    {
      status: 'Novo',
      codigo: 'RA752354VS',
      classificacao: 'Fraude',
      assunto: 'Nota Fiscal falsa no departamento',
      perfil: 'Colaborador',
      criado: '29/04/2022',
    },
    {
      status: 'Novo',
      codigo: 'RA84663443',
      classificacao: 'Assédio Sexual',
      assunto: 'Assédio na sala de reuniões',
      perfil: 'Colaborador',
      criado: '28/04/2022',
    },
    {
      status: 'Visualizado',
      codigo: 'RA677763443',
      classificacao: 'Fraude',
      assunto: 'Caso de Caixa 2',
      perfil: 'Colaborador',
      criado: '28/04/2022',
    },
  ];

export default DataRelatos;