import React, { useState } from "react";
import "../../components/Form/Form.css";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { useNavigate } from "react-router-dom";
import updateAction from "./updateAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import allActions from "../../store/actions";
import ButtonBackForm from "../../components/ButtonBackForm/ButtonBackForm";
import { v4 as uuidv4 } from "uuid";
import { FaTrash } from "react-icons/fa";
import { StorageHelper } from "../../helpers";

const Step2 = () => {
  const params = window.location.pathname.split("/");
  const { companyURL } = StorageHelper.getItem("url") || {companyURL: params[1]};
  const { narrative } = useSelector((state) => state.narrative);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { actions, state } = useStateMachine({ updateAction });
  const history = useNavigate();
  const dispatch = useDispatch();
  const [namesInvolved, setNamesInvolved] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState(narrative.involved || 'Comigo');

  const { fields, append, remove } = useFieldArray({
    control,
    name: "involveds",
  });

  const onSubmit = (data) => {
    const { someOneElseInvolved, nameInvolved, involved } = data;
    const narrativeNew = {
      id: "",
      code: "",
      relationshipCompany: narrative.relationshipCompany,
      classification: narrative.classification,
      subject: narrative.subject,
      description: narrative.description,
      recurrent: narrative.recurrent,
      profile: "",
      involved,
      nameInvolved,
      someOneElseInvolved,
      dateOccurred: "",
      noDate: "",
      partDay: "",
      spaceOccurred: "",
      evidence: "",
      type: narrative.type,
      status: "",
      messages: "",
      companyId: "",
      company: "Teste ",
      userId: "",
    };
    dispatch(allActions.narrative.setNarrative(false, narrativeNew));
    actions.updateAction(data);
    history(`/${companyURL}/relato/step3`);
  };

  function addNamesEnvolved() {
    setNamesInvolved([
      ...namesInvolved,
      {
        id: uuidv4(),
        name: "teste",
      },
    ]);
  }

  function removeItem(item) {
    setNamesInvolved(namesInvolved.filter((value) => item.id !== value.id));
  }

  function listNames() {
    if (fields.length > 0) {
      return fields.map((item, index) => (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
              <input
                key={item.id}
                type="text"
                className="form-control mb-1"
                placeholder="Nome e Sobrenome"
                {...register(`involveds.${index}.description`)}
              />
              <FaTrash
                style={{ marginLeft: 10, marginTop: 10 }}
                onClick={() => remove(index)}
              />
          </div>
        </>
      ));
    }

    return <></>;
  }

  const handleChange = event => {
    setSelectedRadio(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="mb-5">Quem são so envolvidos?</h2>
      <div className="form-group mt-5">
        <label htmlFor="quemEnvolvido" className="mb-3">
          A situação ocorreu com quem? <span className="label-msg">*</span>
        </label>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="quemOcorrido"
            id="euEnvolvido"
            {...register("involved", { required: true })}
            value="Comigo"
            checked={selectedRadio === 'Comigo'}
            onChange={handleChange}
          />
          <label className="form-check-label label-small" htmlFor="euEnvolvido">
            Comigo
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="quemOcorrido"
            id="outraEnvolvido"
            {...register("involved", { required: true })}
            value="Com outra(s) pessoa(s)"
            checked={selectedRadio === 'Com outra(s) pessoa(s)'}
            onChange={handleChange}
          />
          <label
            className="form-check-label label-small"
            htmlFor="outraEnvolvido"
          >
            Com outra(s) pessoa(s)
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="quemOcorrido"
            id="euOutraEnvolvido"
            {...register("involved", { required: true })}
            value="Comigo e com outra(s) pessoa(s)"
            checked={selectedRadio === 'Comigo e com outra(s) pessoa(s)'}
            onChange={handleChange}
          />
          <label
            className="form-check-label label-small"
            htmlFor="euOutraEnvolvido"
          >
            Comigo e com outra(s) pessoa(s)
          </label>
        </div>
        {errors.involved && errors.involved.type === "required" && (
          <div role="alert" className="form-alert mb-3">
            Selecione o envolvido
          </div>
        )}
      </div>

      <div className="form-group mt-5">
        <label htmlFor="nomeEnvolvido" className="mb-3">
          Quem causou a situação?
        </label>
        <input
          type="text"
          className="form-control mb-1"
          id="nomeEnvolvido"
          placeholder="Nome e Sobrenome"
          {...register("nameInvolved")}
          defaultValue={state.nameInvolved}
        />

        <select
          className="form-select"
          id="setorEnvolvido"
          {...register("setorEnvolvido")}
          defaultValue={state.setorEnvolvido}
        >
          <option value="">Setor onde ele(a) trabalha</option>
          <option value="Administrativo">Administrativo</option>
          <option value="Comercial">Comercial</option>
          <option value="Diretoria">Diretoria</option>
          <option value="Financeiro">Financeiro</option>
          <option value="Jurídico">Jurídico</option>
          <option value="Produção">Produção</option>
          <option value="Recursos Humanos">Recursos Humanos</option>
          <option value="TI">TI</option>
          <option value="8Outros">Outros</option>
          <option value="Não sei">Não sei</option>
        </select>
      </div>
      <button
        type="button"
        className="btn btn-block btn-link add ps-0"
        onClick={() => append({ id: uuidv4(), description: "" })}
      >
        Adicionar mais nomes
      </button>

      <div className="form-group mt-5">{listNames()}</div>

      <div className="form-group mt-5">
        <label htmlFor="presenciouOcorrido">
          Alguém mais presenciou o ocorrido?
        </label>
        <span className="label-sub mb-3">
          Se sim, descreva quem e qual o comportamento dessa(s) pessoa(s)
        </span>
        <textarea
          className="form-control"
          id="presenciouOcorrido"
          rows="3"
          {...register("someOneElseInvolved")}
          defaultValue={state.someOneElseInvolved}
        ></textarea>
      </div>
      <div className="d-grid gap-2 d-md-block mt-5">
        <ButtonBackForm />
        <button className="btn btn-primary" type="submit">
          Continuar
        </button>
      </div>
    </form>
  );
};

export default Step2;
