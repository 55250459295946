import React, { useEffect } from "react";
import { RouterProvider } from 'react-router-dom';
import { useAuthContext } from '../contexts';
import { CompanysService } from "../services/empresas";
import { AuthenticatedRoutes } from './authenticatedRoutes';
import { UnauthenticatedRoutes } from './unauthenticatedRoutes';

export function AppRoutes() {
  const { token, user } = useAuthContext();
  const isAuth = !!token && !!user;

  // useEffect(async () => {
  //   await CompanysService.getAllRoutesCompanys();
  // }, []);

  return (
    <RouterProvider
      router={isAuth ? AuthenticatedRoutes : UnauthenticatedRoutes}
    />
  );
}