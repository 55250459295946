import React from 'react';
// import { ToastContainer } from 'react-toastify';

function Content(props) {    
    return (
        <main className="content" role="main">
            <div className="container">
                {props.children}
            </div>
            
            {/* <ToastContainer position="top-center" /> */}
        </main>
    );
  }
  
  export default Content;