import { SET_NOTIFICATION } from "./types";

export default function notification(
  state = false,
  action = { type: "" }
) {
  const { payload } = action;

  if (action.type === SET_NOTIFICATION) {
    return {
      ...state,
      ...payload,
    };
  }
  return state;
}