import "./ViewRelato.css";
import React from "react";
import InfosRelato from "../../../../components/InfosRelato/InfosRelato";
import Chat from "../../../../components/Chat/Chat";
import LogAtividades from "../../../../components/LogAtividades/LogAtividades";
import RelacionarRelatos from "../../../../components/RelacionarRelatos/RelacionarRelatos";
import Dropdown from "react-bootstrap/Dropdown";
import { FaEllipsisV } from "react-icons/fa";
import StatusApurar from "./StatusApurar";
import StatusEncerrar from "./StatusEncerrar";
import StatusArquivar from "./StatusArquivar";

function ViewRelato(props) {
  let typeVerification;
  let typeClosed;
  let typeFiled;
  if (!!props.narrative.typeVerification) {
    typeVerification = JSON.parse(props.narrative.typeVerification);
  }
  if (!!props.narrative.typeClosed) {
    typeClosed = JSON.parse(props.narrative.typeClosed);
  }
  if (!!props.narrative.typeFiled) {
    typeFiled = JSON.parse(props.narrative.typeFiled);
  }
  console.log("props ", props);

  return (
    <>
      <div className="header-relato">
        <div className="title-relato d-flex mb-3">
          <h1 className="me-2">{props.narrative.code}</h1>
          {props.narrative.status === "NEW" && (
            <div className="status-group">
              <span className="status status-visualizado">Novo</span>
            </div>
          )}
          {props.narrative.status === "VIEWED" && (
            <div className="status-group">
              <span className="status status-visualizado">Visualizado</span>
            </div>
          )}
          {props.narrative.status === "VERIFICATION" && (
            <div className="status-group">
              <span className="status status-analise">Em apuração</span>
            </div>
          )}
          {props.narrative.status === "FILED" && (
            <div className="status-group">
              <span className="status status-cancelado">Arquivado</span>
            </div>
          )}
          {props.narrative.status === "CLOSED" && (
            <div className="status-group">
              <span className="status status-encerrado">Encerrado</span>
            </div>
          )}
        </div>
        <div className="btn-header d-flex mb-3">
          {props.narrative.status === "VIEWED" && (
            <StatusApurar props={props} />
          )}
          {props.narrative.status === "NEW" && <StatusApurar props={props} />}
          {props.narrative.status === "VERIFICATION" && (
            <StatusEncerrar props={props} />
          )}

          <Dropdown>
            <Dropdown.Toggle
              className="btn dropdown-icon"
              variant="link"
              id="dropdown-basic"
            >
              <FaEllipsisV />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.ItemText className="d-grid gap-2">
                <StatusArquivar props={props} />
              </Dropdown.ItemText>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="row main-relato">
        <div className="col-md-8">
          <div className="card mb-4">
            <div className="card-body">
              <h3>Informações do relato</h3>

              <InfosRelato narrative={props.narrative} />
            </div>
          </div>

          {/* Após mudança de status para EM APURAÇÃO */}
          {(props.narrative.status === "VERIFICATION" ||
            props.narrative.status === "CLOSED") && (
            <div className="card mb-4">
              <div className="card-body">
                <h3>
                  {props.narrative.status === "VERIFICATION" || "CLOSED"
                    ? "Em Apuração"
                    : "Andamento"}
                </h3>

                <h4 className="mt-4">Plano de investigação</h4>
                <ul>
                  {typeVerification.interview.show && (
                    <li key="1">{typeVerification.interview.description}</li>
                  )}
                  {typeVerification.documents.show && (
                    <li key="2">{typeVerification.documents.description}</li>
                  )}
                  {typeVerification.investigation.show && (
                    <li key="3">
                      {typeVerification.investigation.description}
                    </li>
                  )}
                  {typeVerification.support.show && (
                    <li key="4">{typeVerification.support.description}</li>
                  )}
                </ul>
                <h4 className="mt-4">Nota</h4>
                {typeVerification.calculationNote !== "" && (
                  <p>{typeVerification.calculationNote}</p>
                )}
              </div>
            </div>
          )}

          {/* Após mudança de status para ENCERRADOx */}
          {props.narrative.status === "CLOSED" && (
            <div className="card mb-4">
              <div className="card-body">
                <h3>
                  {props.narrative.status === "CLOSED" ? "Conclusão" : ""}
                </h3>

                <h4 className="mt-4">Procedência</h4>
                {typeClosed.approved.show && (
                  <p>{typeClosed.approved.description}</p>
                )}

                {typeClosed.measuresTaken.show && (
                  <p>{typeClosed.measuresTaken.description}</p>
                )}

                <h4 className="mt-4">Nota de conclusão</h4>

                <p>{typeClosed.closingNote}</p>
              </div>
            </div>
          )}

          {props.narrative.status === "FILED" && (
            <div className="card mb-4">
              <div className="card-body">
                <h3>Arquivado</h3>

                <h4 className="mt-4">Nota</h4>
                {typeFiled && typeFiled.show && <p>{typeFiled.description}</p>}
              </div>
            </div>
          )}
        </div>

        <div className="col-md-4">
          <div className="card chat-admin mb-4">
            <div className="card-body">
              <h3>Mensagens</h3>

              <Chat data={props.narrative} />
            </div>
          </div>
          <div className="card mb-4">
            <div className="card-body">
              <h3>Atividades</h3>

              <LogAtividades data={props.narrative} />
            </div>
          </div>
          {/* <div className="card mb-4">
            <div className="card-body">
              <h3>Relacionadas</h3>

              <RelacionarRelatos />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default ViewRelato;
