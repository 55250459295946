import './Header.css'
import simbolo from '../../assets/img/simbolo-respeiteme3.svg'
import React from 'react';
import { Link } from "react-router-dom";
import NavMaster from '../NavMaster/NavMaster';

function HeaderMaster() {
    const master = `/`;

    return (
        <header className="header">
            <div className="logo">
			    <Link to={master} className="d-block">
                    <img src={simbolo} alt="Respeite.me" className="simbolo-respeiteme" />
				</Link>
			</div>
			<nav className="nav">
                <NavMaster />
			</nav>
        </header>
    );
  }
  
  export default HeaderMaster;