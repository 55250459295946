import "./TimeLine.css";
import "../../components/Form/Form.css";
import "../../components/StyleBS/Accordion.css";
import React from "react";
import {
  BsCheck,
  BsCheckAll,
  BsChatText,
  BsFileText,
  BsFlag,
} from "react-icons/bs";
import Content from "../../components/Content/Content";
import Accordion from "react-bootstrap/Accordion";
import InfosRelato from "../../components/InfosRelato/InfosRelato";
import { StorageHelper } from "../../helpers";
import Chat from "../../components/Chat/Chat";
import SendMensagem from "../../components/Chat/SendMensagem";
import moment from 'moment';

function TimeLine(props, type) {
  const narrative = {
    ...props.props,
  };
  let typeClosed;
  let typeVerification;
  if (props.props.typeClosed) {
    typeClosed = JSON.parse(props.props.typeClosed);
  }
  if (props.props.typeVerification) {
    typeVerification = JSON.parse(props.props.typeVerification);
  }

  const params = window.location.pathname.split("/");

  return (
    <Content>
      <div className="page-header header-group-center pb-5">
        <h1>
          <small>Relato Nº:</small> {props.props.code}
        </h1>
        <h4 style={{ marginLeft: 20 }}>
          <span className={
                props.props.status === "NEW"
                  ? "status-aberto"
                  : null || props.props.status === "VIEWED"
                  ? "status-visualizado"
                  : null || props.props.status === "VERIFICATION"
                  ? "status-analise"
                  : null || props.props.status === "CLOSED"
                  ? "status-encerrado"
                  : null || props.props.status === "FILED"
                  ? "status-cancelado"
                  : null
              }>
            {props.props.status === "NEW" ? " Novo" : ""}
            {props.props.status === "VIEWED" ? " Visualizado" : ""}
            {props.props.status === "VERIFICATION" ? " Em apuração" : ""}
            {props.props.status === "CLOSED" ? " Encerrado" : ""}
            {props.props.status === "FILED" ? " Arquivado" : ""}
          </span>
        </h4>
      </div>
      <div className="row">
        <div className="col-md-10">
          <ul className="cbp_tmtimeline">
            {typeClosed && typeClosed.approved.show && (
              <li>
                <time className="cbp_tmtime">
                  <span>{moment(props.props.dateClosed).format('DD/MM/YYYY') || ""}</span>
                </time>
                <div className="cbp_tmicon">
                  <BsFlag />
                </div>
                {typeClosed && typeClosed.approved.show && (
                  <div className="cbp_tmlabel">
                    <h2>Relato Finalizado</h2>
                    {typeClosed && typeClosed.approved.show && (
                      <p>{typeClosed.approved.description}</p>
                    )}

                    {/* {typeClosed && typeClosed.measuresTaken.show && (
                      <p>{typeClosed.measuresTaken.description}</p>
                    )} */}

                    {type !== 0 && type !== "USER" && params[2] !== 'acompanhamento' && (
                      <>
                        <h4 className="mt-4">Nota de conclusão</h4>
                        <p>{typeClosed.closingNote.description}</p>
                      </>
                    )}
                  </div>
                )}
              </li>
            )}
            {typeVerification && typeVerification.calculationNote !== "" && (
              <li>
                <time className="cbp_tmtime">
                  <span>{moment(props.props.dateAnalysis).format('DD/MM/YYYY') || ""}</span>
                </time>
                <div className="cbp_tmicon">
                  <BsFileText />
                </div>
                <div className="cbp_tmlabel">
                  <h2>Relato em análise</h2>
                  <p>Seu relato está sendo apurado.</p>
                  {/* <ul>
                    {typeVerification && typeVerification.interview.show && (
                      <li key="1">{typeVerification.interview.description}</li>
                    )}
                    {typeVerification && typeVerification.documents.show && (
                      <li key="2">{typeVerification.documents.description}</li>
                    )}
                    {typeVerification &&
                      typeVerification.investigation.show && (
                        <li key="3">
                          {typeVerification.investigation.description}
                        </li>
                      )}
                    {typeVerification && typeVerification.support.show && (
                      <li key="4">{typeVerification.support.description}</li>
                    )}
                  </ul> */}
                  {type !== 0 &&
                    type !== "USER" &&
                    typeVerification &&
                    typeVerification.calculationNote !== "" &&
                    params[2] !== 'acompanhamento' && (
                      <>
                        <h4 className="mt-4">Nota</h4>
                        <p>{typeVerification.calculationNote}</p>
                      </>
                    )}
                </div>
              </li>
            )}

            {props.props.messages !== undefined &&
              props.props.messages.length > 0 && (
                <>
                  <li className="chat">
                    <time className="cbp_tmtime">
                      {props.props.messages !== undefined &&
                        props.props.messages.length > 0 && (
                          <>
                            <span>{moment(props.props.messages[0].createAt).format('DD/MM/YYYY')}</span>
                          </>
                        )}
                    </time>
                    <div className="cbp_tmicon">
                      <BsChatText />
                    </div>
                    <div className="cbp_tmlabel">
                      <h2>Mensagem</h2>
                      {props.props.messages !== undefined &&
                        props.props.messages.length > 0 &&
                        props.props.messages.map((item) => (
                          <>
                            <div className="box-mensagem mensagem-enviada">
                              <span className="autor-mensagem">
                                {item.responsible}, {moment(item.createAt).format('DD/MM/YYYY') || ''}
                              </span>
                              <div className="txt-mensagem mb-3">
                                <p>{item.description}</p>
                                {!!item.uploadName && !!item.uploadURL && (
                                  <p>
                                    upload:{" "}
                                    <a
                                      href={item.uploadURL}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {" "}
                                      {item.uploadName}
                                    </a>
                                  </p>
                                )}
                              </div>
                            </div>

                            {/* <p>Descrição: {item.description}</p>
                            <p>responsável: {item.description}</p>
                            <a href={item.uploadURL} target="_blank">
                              Arquivo: {item.uploadName}{" "}
                            </a> */}
                          </>
                        ))}

                      <SendMensagem narrative={narrative} />
                    </div>
                  </li>
                </>
            )}

            
            <li>
            {props.props.dateView && props.props.dateView !== "" && (
              <>
                <time className="cbp_tmtime">
                  <span>{moment(props.props.dateView).format('DD/MM/YYYY') || ""}</span>
                </time>
                <div className="cbp_tmicon">
                  <BsCheckAll />
                </div>
                <div className="cbp_tmlabel">
                  <h2>Relato Visualizado</h2>
                  <p>
                    Seu relato foi visualizado pelo administrador e está
                    aguardando para início de análises.
                  </p>
                </div>
              </>
            )}
            </li>
            <li>
              <time className="cbp_tmtime">
                <span>{moment(props.props.createAt).format('DD/MM/YYYY') || ""}</span>
              </time>
              <div className="cbp_tmicon">
                <BsCheck />
              </div>
              <div className="cbp_tmlabel">
                <h2>Relato aberto</h2>
                <p>Seu relato foi aberto e está aguardando análise.</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="row mt-5  justify-content-md-center">
        <div className="col-md-8">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Detalhes do Relato</Accordion.Header>
              <Accordion.Body>
                <InfosRelato narrative={narrative} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </Content>
  );
}

export default TimeLine;
