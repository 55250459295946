import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StorageHelper } from '../../helpers';

function Enviado() {

  const { narrative } = useSelector((state) => state.narrative);
  const params = window.location.pathname.split("/");
  const { companyURL } = StorageHelper.getItem("url") || {companyURL: params[1]};
  const duvidas = `/${companyURL}/duvidas`;

  return (
    <>
        <div className="card-title">
            <h2>Seu relato foi enviado ao responsável!</h2>
        </div>
        <div className="card-body">
            <p>Você poderá acompanhar o andamento do seu relato através código abaixo:</p>
            <span className="big-number">{narrative && narrative.code !== "" ? narrative.code : ""}</span>
        </div>
        <div className="card-footer">
        <Link to={duvidas} className="btn btn-link ps-0" role="button">Saiba como acompanhar</Link>
        </div>
    </>
  );
}

export default Enviado;