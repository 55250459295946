import { SET_PLAN } from "./types";

export default function plan(
  state = false,
  action = { type: "" }
) {
  const { payload } = action;

  if (action.type === SET_PLAN) {
    return {
      ...state,
      ...payload,
    };
  }
  return state;
}