import '../../components/Form/Form.css'
import React from 'react';
import Content from '../../components/Content/Content'
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import allActions from '../../store/actions';
import { StorageHelper } from '../../helpers';

function Relacao() {

  const params = window.location.pathname.split("/");
  const { companyURL } = StorageHelper.getItem("url") || {companyURL: params[1]};
  let { narrative } = useSelector((state) => state.narrative);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const history = useNavigate();
  const dispatch = useDispatch(); 

  const onSubmit = (data) => {
    const { relationshipCompany } = data;

    if(!narrative) {
      narrative = {type: 'IDENTIFIED'};
    }

    const narrativeNew = {
      id: "",
      code: "",
      relationshipCompany,
      classification: "",
      subject: "",
      description: "",
      profile: "",
      recurrent: "",
      involved: "",
      nameInvolved: "",
      someOneElseInvolved: "",
      dateOccurred: "",
      noDate: "",
      partDay: "",
      spaceOccurred: "",
      evidence: "",
      type: narrative.type || '',
      status: "",
      messages: "",
      companyId: "",
      company: "Teste ",
      userId: "",
    };
    dispatch(allActions.narrative.setNarrative(false, narrativeNew));
    history(`/${companyURL}/relato`);
  };  

  return (
    <Content>   
      <div className="column-container">	
        <div className="card">
          <div className="card-title">
              <h2>Qual sua relação com a <span className="text-decoration-underline">{companyURL}</span>?</h2> 
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group mb-5">
                  <select className="form-select" {...register("relationshipCompany", { required: true })} >
                      <option value="">Selecione uma opção...</option>
                      <option value="colaborador">Colaborador</option>
                      <option value="freelancer">Freelancer</option>
                      <option value="candidato">Candidato</option>
                      <option value="fornecedor">Fornecedor</option>
                      <option value="cliente">Cliente / Consumidor</option>								
                  </select>
                  {errors.relationshipCompany && errors.relationshipCompany.type === "required" && <div role="alert" className="form-alert" >Selecione uma das opções do campo acima</div>}

              </div>
              
              <button type="submit" className="btn btn-primary" >Continuar</button>
            </form>
          </div>
        </div>
      </div> 
    </Content>
  );
}

export default Relacao;