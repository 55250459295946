import '../../../../components/StyleBS/Modal.css'
import React from 'react';
import { useState } from "react";
import { useForm } from 'react-hook-form';
import { Modal, Button } from 'react-bootstrap';
import { NarrativeService } from '../../../../services/narratives';
import allActions from '../../../../store/actions';
import { StorageHelper } from '../../../../helpers';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


function StatusEncerrar(props) {
    const [show, setShow] = useState(false);
    const [dataNarrative] = useState(props); 
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();

    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {
      const { id: idUser, companyId, email } = StorageHelper.getItem('user');

      const arrayStatusClosed = {
        approved: { 
          show: data.approved,
          description: "O Relato é procedente"
        }, 
        measuresTaken: { 
          show: true,
          description: data.measuresTaken
        }, 
        closingNote: data.closingNote
      }
      const typeVerification =  JSON.parse(dataNarrative.props.narrative.typeVerification);
      const arrayStatusVerification = {
        interview: { 
          show: typeVerification.interview.show,
          description: typeVerification.interview.description
        }, 
        documents: {
          show: typeVerification.documents.show,
          description: typeVerification.documents.description
        },
        investigation: {
          show: typeVerification.investigation.show, 
          description: typeVerification.investigation.description
        },
        support: {
          show: typeVerification.support.show, 
          description: typeVerification.support.description
        },          
        calculationNote: typeVerification.calculationNote
      }
      const statusFiled = {
        show: false,
        description: ''
      }
      const narrative = { 
        ...dataNarrative.props.narrative, 
        status: "CLOSED", 
        typeVerification: JSON.stringify(arrayStatusVerification),
        typeClosed: JSON.stringify(arrayStatusClosed),
        typeFiled: JSON.stringify(statusFiled),
      }

      console.log('dataNarrative', dataNarrative)
      console.log('dataNarrative JSON', JSON.stringify(dataNarrative))

      await NarrativeService.createLog(idUser, companyId, narrative, "UPDATED", JSON.stringify(arrayStatusClosed));
      await NarrativeService.updateById(narrative.id, narrative);

      dispatch(allActions.narrative.setNarrative(true, narrative));
      handleClose();      
      toast.success("Status do relato alterado para: Encerrado.");
    }

    const [checked, setChecked] = useState(false)

    const handleChange = () => {
      setChecked(!checked)
    }
  
    return (
      <>       
        <Button className="btn btn-primary ms-3" onClick={handleShow}>
            Encerrar
        </Button>
  
        <Modal show={show} onHide={handleClose} >
          <Modal.Header closeButton>
            <Modal.Title>
                Mudar status para: <span className="status status-encerrado">Encerrado</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-check form-switch mt-2">
                    <input className="form-check-input" type="checkbox" role="switch" id="relatoProcedente" aria-checked {...register("approved")} checked={checked} onChange={handleChange} />
                    <label className="form-check-label" htmlFor="relatoProcedente">O Relato é procedente</label>
                </div>

                {checked && (
                  <div className="form-group mt-4">
                      <label htmlFor="relatoMedidas" className="mb-3">Medidas tomadas</label>                    
                      <select className="form-select" id="relatoMedidas" {...register("measuresTaken")}>
                          <option value="">Selecione</option>
                          <option value="Advertência">Advertência</option>
                          <option value="Suspensão">Suspensão</option>
                          <option value="Demissão">Demissão</option>
                          <option value="Disciplinares">Disciplinares</option>
                          <option value="Cautelares">Cautelares</option>
                          <option value="Sigílosa">Sigílosa</option>
                          <option value="Outras">Outras</option>                   
                      </select>
                  </div>
                )}

                <div className="form-group mt-4">
                    <label htmlFor="notaConclusao">Nota de Conclusão</label>
                    <span className="label-sub">Para uso interno. Não será disponibilizado para o autor do relato</span>
                    <textarea className="form-control mt-1" id="notaConclusao" rows="3" {...register("closingNote")}></textarea>
                </div>
                <button type="submit" className="btn btn-primary mt-4" >Encerrar</button>
            </form>

          </Modal.Body>
        </Modal>
      </>
    );
  }

  export default StatusEncerrar;