import "../StyleBS/Modal.css";
import React from "react";
import { useState } from "react";
import { FaPaperclip, FaRegPaperPlane } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NarrativeService } from "../../services/narratives";
import { StorageHelper } from "../../helpers";
import { NotificationService } from "../../services/notification";

function SendMensagem(props) {
  const [show, setShow] = useState(false);
  const { companyURL } = StorageHelper.getItem("url")

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = async (value) => {
    const user = StorageHelper.getItem("user");
    let nameUser = "";
    let idUser = "";

    if (user) {
      const { name, id } = user;
      nameUser = name;
      idUser = id;
    }
    const idNarrative = props.narrative.id;
    let uploadURLs3 = "";
    let uploadName = "not file";

    if (value.upload.length > 0) {
      uploadURLs3 = await NarrativeService.uploadS3(value.upload);
      uploadName = value.upload[0].name;
    }
    const message = {
      title: "title",
      description: value.description,
      responsible: nameUser || "Anonymous",
      companyId: props.narrative.companyId,
      narrativeId: props.narrative.id,
      uploadName,
      uploadURL: uploadURLs3,
    };
    props.narrative.messages.push(message);
    await NarrativeService.updateById(idNarrative, props.narrative);

    if(user && user.type && user.type === 'USER') {
      await NarrativeService.sendEmailMessage({
        narrativeId: props.narrative.code,
        companyId: props.narrative.companyId,
        isNewMessage: false,
        companyUrl: companyURL
      });
    }

    window.location.reload();
  };

  return (
    <>
      <div className="mt-3">
        <Button className="btn btn-primary" onClick={handleShow}>
          Enviar Mensagem
        </Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar Mensagem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="formMensagem" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mt-1">
              <label htmlfor="description">Sua mensagem</label>
              <textarea
                className="form-control"
                id="description"
                {...register("description", { required: true })}
                rows="3"
              ></textarea>
              {errors.description && errors.description.type === "required" && (
                <div role="alert" className="form-alert mb-3">
                  Este campo é obrigatório
                </div>
              )}
            </div>

            <div className="mb-3">
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <FaPaperclip />
                </span>
                <input
                  className="form-control"
                  type="file"
                  id="anexoMensagem"
                  {...register("upload")}
                />
              </div>
            </div>

            <Button type="submit" className="btn btn-primary">
              <FaRegPaperPlane className="me-2" /> Enviar
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SendMensagem;
