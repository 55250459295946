import "../../components/Form/Form.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { useNavigate } from "react-router-dom";
import updateAction from "./updateAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import allActions from "../../store/actions";
import ButtonBackForm from "../../components/ButtonBackForm/ButtonBackForm";
import { StorageHelper } from "../../helpers";

const Step3 = () => {
  const params = window.location.pathname.split("/");
  const { companyURL } = StorageHelper.getItem("url") || {companyURL: params[1]};
  const { narrative } = useSelector((state) => state.narrative);
  const { register, handleSubmit } = useForm();
  const [selectedRadio, setSelectedRadio] = useState(narrative.partDay || 'Manhã');

  const { actions, state } = useStateMachine({ updateAction });
  const history = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const { spaceOccurred, partDay, noDate, dateOccurred } = data;
    const narrativeNew = {
      id: "",
      code: "",
      relationshipCompany: narrative.relationshipCompany,
      classification: narrative.classification,
      subject: narrative.subject,
      description: narrative.description,
      recurrent: narrative.recurrent,
      profile: "",
      involved: narrative.involved,
      nameInvolved: narrative.nameInvolved,
      someOneElseInvolved: narrative.someOneElseInvolved,
      dateOccurred,
      noDate: noDate === 'Não tenho certeza' ? true : false,
      partDay,
      spaceOccurred,
      evidence: "",
      type: narrative.type,
      status: "",
      messages: "",
      companyId: "",
      company: "Teste ",
      userId: "",
    };
    dispatch(allActions.narrative.setNarrative(false, narrativeNew));
    actions.updateAction(data);
    history(`/${companyURL}/relato/step4`);
  };

  const handleChange = event => {
    setSelectedRadio(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="mb-5">Quando e onde aconteceu?</h2>
      <div className="form-group">
        <div className="row">
          <div className="col-6">
            <input
              type="date"
              className="form-control"
              {...register("dateOccurred")}
              defaultValue={state.dateOccurred}
            />
          </div>
        </div>
      </div>

      <div className="form-group mt-4">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="semData"
            {...register("noDate")}
            defaultValue="Não tenho certeza"
          />
          <label className="form-check-label" htmlFor="semData">
            Não tenho certeza
          </label>
        </div>
      </div>

      <div className="form-group mt-5">
        <label htmlFor="parteDia" className="mb-3">
          Em que parte do dia aconteceu?
        </label>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="parteDia"
            id="parteDiaManha"
            {...register("partDay")}
            value="Manhã"
            checked={selectedRadio === 'Manhã'}
            onChange={handleChange}
          />
          <label
            className="form-check-label label-small"
            htmlFor="parteDiaManha"
          >
            Manhã
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="parteDia"
            id="parteDiaTarde"
            {...register("partDay")}
            value="Tarde"
            checked={selectedRadio === 'Tarde'}
            onChange={handleChange}
          />
          <label
            className="form-check-label label-small"
            htmlFor="parteDiaTarde"
          >
            Tarde
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="parteDia"
            id="parteDiaNoite"
            {...register("partDay")}
            value="Noite"
            checked={selectedRadio === 'Noite'}
            onChange={handleChange}
          />
          <label
            className="form-check-label label-small"
            htmlFor="parteDiaNoite"
          >
            Noite
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="parteDia"
            id="parteDiaSem"
            {...register("partDay")}
            value="Não sei"
            checked={selectedRadio === 'Não sei'}
            onChange={handleChange}
          />
          <label className="form-check-label label-small" htmlFor="parteDiaSem">
            Não sei
          </label>
        </div>
      </div>

      <div className="form-group mt-5">
        <label htmlFor="espacoOcorrido">
          Em qual espaço a situação aconteceu?
        </label>
        <span className="label-sub mb-3">
          Seja ele um ambiente físico ou canal digital
        </span>
        <input
          type="text"
          className="form-control"
          id="espacoOcorrido"
          {...register("spaceOccurred")}
          defaultValue={state.spaceOccurred}
        />
      </div>

      <div className="d-grid gap-2 d-md-block mt-5">
        <ButtonBackForm />
        <button className="btn btn-primary" type="submit">
          Continuar
        </button>
      </div>
    </form>
  );
};

export default Step3;
