import "./SideBar.css";
import React from "react";
import {
  BsBuilding,
  BsBarChart,
  BsGear,
  BsCardChecklist,
  BsUpload,
} from "react-icons/bs";
import { NavLink } from "react-router-dom";
import allActions from "../../store/actions";
import { useDispatch } from "react-redux";
import { StorageHelper } from "../../helpers";

function SideBarMaster() {
  const dispatch = useDispatch();

  const { companyURL } = StorageHelper.getItem("url") || "";
  const masterClient = `/master/clientes`;
  const masterPlan = `/master/planos`;
  const masterUpload = `/master/upload`;
  const masterReport = `/master/relatorios`;
  const masterConfig = `/master/configuracoes`;

  return (
    <div className="sidebar d-flex flex-column flex-shrink-0">
      <ul className="nav nav-pills nav-flush mb-auto text-center">
        <li>
          <NavLink
            to={masterClient}
            onClick={() => dispatch(allActions.company.setCompany(false))}
            className={(isActive) =>
              "nav-link py-3" + (!isActive ? " unselected" : "")
            }
          >
            <BsBuilding />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={masterPlan}
            onClick={() => dispatch(allActions.company.setPlan(false))}
            className={(isActive) =>
              "nav-link py-3" + (!isActive ? " unselected" : "")
            }
          >
            <BsCardChecklist />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={masterUpload}
            className={(isActive) =>
              "nav-link py-3" + (!isActive ? " unselected" : "")
            }
          >
            <BsUpload />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={masterReport}
            className={(isActive) =>
              "nav-link py-3" + (!isActive ? " unselected" : "")
            }
          >
            <BsBarChart />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={masterConfig}
            className={(isActive) =>
              "nav-link py-3" + (!isActive ? " unselected" : "")
            }
          >
            <BsGear />
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default SideBarMaster;