import './BackAdmin.css'
import React from 'react';
import { Outlet } from 'react-router-dom';
import HeaderAdmin from '../../components/Header/HeaderAdmin';
import SideBarAdmin from '../../components/SideBar/SideBarAdmin';
import FooterAdmin from '../../components/Footer/FooterAdmin';

function BackAdmin(props) {
    return (
        <div className={props.page}>
            <HeaderAdmin />
            <SideBarAdmin />
            {props.children}
            <Outlet />            
            <FooterAdmin />
        </div>
    )
}

export default BackAdmin