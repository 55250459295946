import React from 'react';
import Content from '../../components/Content/Content'
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { UserService } from '../../services/users';
import { toast } from "react-toastify";


function DefinirAcesso() {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const url = window.location.pathname.split('/')[1];

  const history = useNavigate();

  const onSubmit = data => {

    if(data.senha === data.senhaRepetir) {
      UserService.resetPassword({
        email: data.emailLogin,
        password: data.senhaRepetir
      });
    
      history(`/${url}`);
    } else {
      toast.error("A senha de confirmação não corresponde.");
    }
  }

  return (
    <div>
      <Content>	
      <div className="column-container">
        <div className="card">
            <div className="card-title">
                <h2>Definir Senha</h2>
            </div>
            <div className="card-body">
                <h3>Crie uma senha de acesso ao Respeite.me.</h3>
                <p>A senha deve conter no mínimo 8 caracteres com letras e números</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mt-4">
                        <label className="label-small" htmlFor="emailLogin">E-mail:</label>
                        <input type="text" {...register("emailLogin", { required: true })} className="form-control" id="emailLogin" />
                    </div>
                    <div className="form-group mt-4">
                        <label className="label-small" htmlFor="senha">Senha:</label>
                        <input type="password" {...register("senha", { required: true })} className="form-control" id="senha" />
                        {errors.senha && errors.senha.type === "required" && <div role="alert" className="form-alert mb-3" >Digite uma senha válida</div>}
                    </div>
                    <div className="form-group mt-3">
                        <label className="label-small" htmlFor="senhaRepetir">Repetir Senha:</label>
                        <input type="password" {...register("senhaRepetir", { required: true })} className="form-control" id="senhaRepetir" />
                        {errors.senhaRepetir && errors.senhaRepetir.type === "required" && <div role="alert" className="form-alert mb-3" >Senha não confere com digitada a cima</div>}
                    </div> 
                    <div className="row"> 
                        <div className="col-md-12 mt-5">
                            <button type="submit" className="btn btn-primary" >Criar</button>
                        </div>
                    </div>      
                </form>
            </div>
        </div>
      </div> 
    </Content>
    </div>
  );
}

export default DefinirAcesso;
