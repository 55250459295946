import './Login.css'
import React, { useEffect, useState } from 'react';
import Content from '../../components/Content/Content'
import { Link } from 'react-router-dom';
import FormLogin from '../../components/Form/FormLogin';
import { CompanysService } from '../../services/empresas';

function Login() {  

  async function loadULRs() {  
    await CompanysService.getAllRoutesCompanys();
  }

  useEffect(() => {
    loadULRs();
  }, []);

  return (
    <Content>	
      <div className="column-container">	
        <div className="card">
            <div className="card-title">
                <h2>Entrar no Respeite.me</h2> 
            </div>
            <div className="card-body">
              <FormLogin />

              <ul className="list-block mt-4">
                <li>
                  <Link to="/recuperar-acesso"><small>Esqueceu a Senha?</small></Link>
                </li>
                <li>
                  <Link to="/solicitar-cadastro"><small>Não tem o cadastro. Peça aqui</small></Link>
                </li>
              </ul>
            </div>
        </div>
      </div> 
    </Content>
  );
}

export default Login;
