import './Footer.css'
import React from 'react';
import { Link } from "react-router-dom";
import { StorageHelper } from '../../helpers';

function Footer() {    
    const params = window.location.pathname.split("/");
  const { companyURL } = StorageHelper.getItem("url") || {companyURL: params[1]};
    const duvida = `/${companyURL}/duvidas`;
    const termos = `/${companyURL}/termos`;

    return (
        <footer className="footer text-muted text-center text-small">
            <p className="mb-1">© 2022 Respeite.me</p>		    
            <ul id="footerNav" className="list-inline">
                <li className="list-inline-item">
                    <Link to={duvida}>Dúvidas</Link>
                </li>
                <li className="list-inline-item">
                    <Link to={termos}>Termo de Uso</Link>
                </li>
                <li className="list-inline-item">
                    <Link to="/termos">Política de Privacidade</Link>
                </li>
            </ul>
        </footer>
    );
  }
  
  export default Footer;