import '../Button/Button.css'
import React from 'react';
import { Link } from "react-router-dom";

function ButtonBackForm() {
  return (
    <>
        <Link role="button" className="btn btn-link" to={-1}>Voltar</Link>
    </>
  );
}

export default ButtonBackForm;