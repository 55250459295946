import "../../components/Form/Form.css";
import React, { useState } from "react";
import Content from "../../components/Content/Content";
import { Link } from "react-router-dom";
import { NarrativeService } from "../../services/narratives";
import { Environment } from "../../environment";
import TimeLine from "./TimeLine";
import { useForm } from "react-hook-form";
import { StorageHelper } from "../../helpers";

function Acompanhamento() {
  const { register, handleSubmit } = useForm();

  const { type } = StorageHelper.getItem("type") || 0;
  const [openAccompaniment, setOpenAccompaniment] = useState(false);
  const [dataResponse, setDataResponse] = useState({
    id: "",
    code: "",
    relationshipCompany: "",
    classification: "",
    subject: "",
    description: "",
    profile: "",
    recurrent: "",
    involved: "",
    nameInvolved: "",
    someOneElseInvolved: "",
    dateOccurred: "",
    noDate: "",
    partDay: "",
    spaceOccurred: "",
    evidence: "",
    evidenceURL: "",
    type: "",
    status: "",
    messages: "",
    companyId: "",
    Company: "",
    userId: "",
    typeVerification: "",
    typeClosed: "",
    edit: false,
  });

  const onSubmit = async (data) => {
    const narratives = await NarrativeService.findByCodeNarrative(
      0,
      Environment.LIMITE_DE_LINHAS,
      data.code
    );
    setDataResponse(narratives.data);
    setOpenAccompaniment(true);
  };

  return (
    <>
      {!openAccompaniment && (
        <Content>
          <div className="column-container">
            <div className="card">
              <div className="card-title">
                <h2>Acompanhar Relato</h2>
              </div>
              <div className="card-body">
                <form id="formRelacao" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label htmlFor="assuntoRelato" className="mb-3">
                      Número do relato
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("code")}
                      id="numeroRelato"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary mt-3"
                    role="button"
                  >
                    Acompanhar
                  </button>
                </form>

                <div className="mt-5">
                  <p>
                    <small>
                      Não tem o número do relato?{" "}
                      <Link
                        to="/duvidas"
                        className="btn-link-content"
                        role="button"
                      >
                        Veja o que fazer
                      </Link>
                      .
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Content>
      )}

      {openAccompaniment && <TimeLine props={dataResponse} type={type} />}
    </>
  );
}

export default Acompanhamento;
