import React from 'react';
import Content from '../../components/Content/Content'
import ButtonBack from '../../components/ButtonBack/ButtonBack';


function Termos() {
  return (
    <Content>   
      <div className="row row justify-content-md-center">
        <div className="col-md-8">
            <div className="page-header text-center">
                <ButtonBack />
                <h1>Termos de uso e política de privacidade</h1>
            </div>					
            <div className="card">
                <div className="card-body">
                    <h3>Título</h3>
                    <p>texto</p>
                </div>
            </div>
        </div>
    </div>
    </Content>
  );
}

export default Termos;
