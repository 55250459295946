import "../../../components/StyleBS/Tabs.css";
import "../../../components/Form/Form.css";
import React, { useEffect, useState } from "react";
import Content from "../../../components/Content/Content";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import InfosClientes from "../../../components/InfosCliente/InfosCliente";
import { useForm } from "react-hook-form";
import { StorageHelper } from "../../../helpers";
import { CompanysService } from "../../../services/empresas";
import Loading from "../../../components/Loading/Loading";

const company = {
  cellphone: "(11) 45345-4545",
  cnpj: "42.342.342/3423-42",
  createAt: "2023-01-18T11:06:44.126Z",
  deleteAt: null,
  email: "jonataserpa2@gmail.com",
  emailResponsible: "jonataserpa2@gmail.com",
  followup: null,
  id: 1,
  name: "Tague",
  phone: "(98) 7989-8798",
  plansId: 1,
  responsible: "jonata",
  socialReason: "Tague",
  status: "ACTIVE",
  updateAt: "2023-01-18T11:06:44.126Z",
  url: "tague",
};

function Configuracoes() {
  const { register, handleSubmit } = useForm({
    defaultValues: {
        administrativo: true,
        comercial: true,
        financeiro: true,
        juridico: true,
        marketing: true,
        operacional: true,
        rh: true,
        outros: true,
    },
  });
  const [dataResponse, setDataResponse] = useState();
  const [removeLoading, setRemoveLoading] = useState(true);
  const { companyId, type } = StorageHelper.getItem("user");

  const onSubmit = (data) => {
    console.log(data);
  };

  /**
   * Get company
   */
  const getCompany = async () => {
    const company = await CompanysService.findOne(companyId);
    console.log("get COmpany: ", company);
    setDataResponse(company.data);
    setRemoveLoading(false);
  };

  useEffect(() => {
    getCompany();
  }, []);

  function getStatus(status) {
    if (status === "ACTIVE") {
      return "ATIVO";
    } else if (status === "INACTIVE") {
      return "INATIVO";
    }

    console.log("status ", status);

    return "ativo";
  }

  return (
    <>
      {type === "MASTER" ? (
        <div>
          {!removeLoading && (
            <Content>
              <h1 className="mb-4">Configurações</h1>
              <div className="card">
                <div className="card-body">
                  <Tabs
                    defaultActiveKey="informacoes"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="informacoes" title="Informações da Conta">
                      <div className="form-group mt-3">
                        <span className="info-result">
                          <span className="badge rounded-pill bg-analise">
                            {dataResponse && dataResponse.status === "ACTIVE"
                              ? "Ativo"
                              : "Inativo"}
                          </span>
                        </span>
                      </div>
                      <div className="row">
                        <div className="col">
                          <InfosClientes company={dataResponse} />
                        </div>
                        <div className="col">
                          <div className="box-destaque mt-3">
                            <div className="row">
                              <div className="col">
                                <div className="form-group mt-3">
                                  <span className="label-result">
                                    {dataResponse.plan.title}
                                  </span>
                                  <span className="info-result">
                                    Até {dataResponse.plan.limit} usuários
                                  </span>
                                </div>
                                <div className="form-group mt-3">
                                  <span className="label-result">
                                    Usuários ativos
                                  </span>
                                  <span className="info-result">{dataResponse.plan.totalMembership}</span>
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm d-block mt-3 align-right"
                                  >
                                    Mudar de Plano
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>

                    <Tab eventKey="departamentos" title="Departamentos">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                          <div className="row">
                            <div className="col">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="administrativo"
                                  checkedDefault="checked"
                                  {...register("administrativo")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="administrativo"
                                >
                                  Administrativo
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="comercial"
                                  defaultValue={true}
                                  {...register("comercial")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="comercial"
                                >
                                  Comercial
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="financeiro"
                                  checkedDefault="checked"
                                  {...register("financeiro")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="financeiro"
                                >
                                  Financeiro
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="juridico"
                                  checkedDefault="checked"
                                  {...register("juridico")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="juridico"
                                >
                                  Jurídico
                                </label>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="marketing"
                                  checkedDefault="checked"
                                  {...register("marketing")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="marketing"
                                >
                                  Marketing
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="operacional"
                                  checkedDefault="checked"
                                  {...register("operacional")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="operacional"
                                >
                                  Operacional
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="rh"
                                  checkedDefault="checked"
                                  {...register("rh")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="rh"
                                >
                                  Recursos Humanos
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="outros"
                                  checkedDefault="checked"
                                  {...register("outros")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="outros"
                                >
                                  Outros
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Salvar
                        </button>
                      </form>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </Content>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "150px",
            }}
          >
            {removeLoading && <Loading />}
          </div>
        </div>
      ) : (
        <Content>
          <div>Não existe permissão para acessar essa pagina.</div>
        </Content>
      )}
    </>
  );
}

export default Configuracoes;
