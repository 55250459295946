import './LogAtividades.css'
import React, { useEffect, useState } from 'react';
import { NarrativeService } from '../../services/narratives';
import { Environment } from '../../environment';
import moment from 'moment';

const log = [{
  id: "",
  registration: "",
  changedInformation: "",
  type: "",
  companyId: "",
  userId: "",
  narrativeId: "",
  plansId: "",
  departmentsId: "",
  createAt: "",
}]

function LogAtividades(data) {
  const [dataLog, setDataLog] = useState(log);

  /**
   * Get all logs
   */
   const getAll = async () => {
    const logs = await NarrativeService.getAllLogs(
      0,
      Environment.LIMITE_DE_LINHAS,
      data.data.code
    );
    setDataLog(logs.data);
  };

  useEffect(() => {
    getAll();
  }, []);

  function getTranslateType(type) {
    if (type === 'VIEWED') {
      return 'Visualizado'
    } else if (type === 'NEW') {
      return 'Novo'
    } else if (type === 'UPDATED') {
      return 'Relato atualizado'
    }
    return '';
  }

  return (
    <>
        <ul className="list-log pt-2" style={{ height: "200px", overflow: "auto" }}>
          {dataLog.length > 1 && dataLog.map((item) => (
            <li key={item.id}>
                <span className="s-log"><strong>{item.user === null ? "" : item.user.name}</strong>  </span>
                <span className="e-log">{getTranslateType(item.type)}</span>
                <span className="e-log">{moment(item.createAt).format("DD/MM/YYYY HH:mm")}</span>
            </li>
          ))}
        </ul>
    </>
  );
}

export default LogAtividades;