import React, { useEffect, useState } from "react";
import allActions from "../../../store/actions";
import Content from "../../../components/Content/Content";
import { useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  FaEllipsisV,
  FaRegEdit,
  FaFilter,
  FaRegBookmark,
} from "react-icons/fa";
import Table from "../../../components/Table/Table";
import { Link } from "react-router-dom";
import DataClientes from "../../../data/DataClientes";
import { CompanysService } from "../../../services/empresas";
import { Environment } from "../../../environment";
import EditCliente from "./Cliente/EditCliente";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import { StorageHelper } from "../../../helpers";

function Clientes() {
  const { openCompany } = useSelector((state) => state.company);
  const [dataResponse, setDataResponse] = useState(DataClientes);
  const [, setPageEdit] = useState(openCompany);
  const [company, setCompany] = useState(null);
  const dispatch = useDispatch();

  const { type } = StorageHelper.getItem("user") || "";

  const [removeLoading, setRemoveLoading] = useState(true);

  function onHandleClickEdit(event) {
    setPageEdit(true);
    dispatch(allActions.company.setCompany(true));

    // setSearchParams({ cliente: event.id }, { replace: true });
    setCompany({
      cellphone: event.original.cellphone,
      cnpj: event.original.cnpj,
      createAt: event.original.createAt,
      deleteAt: event.original.deleteAt,
      email: event.original.email,
      emailResponsible: event.original.emailResponsible,
      followup: event.original.followup,
      id: event.original.id,
      name: event.original.name,
      phone: event.original.phone,
      plansId: event.original.plansId,
      responsible: event.original.responsible,
      socialReason: event.original.socialReason,
      status: event.original.status,
      url: event.original.url,
      edit: true,
    });
  }

  async function onHandleClickDelete(event) {
    if (event.original.id !== "") {
      await CompanysService.deleteById(event.original.id);
      const company = await CompanysService.getAll(
        0,
        Environment.LIMITE_DE_LINHAS,
        ""
      );
      setDataResponse(company.data);
    }
  }

  const newCompany = () => {
    setPageEdit(true);
    dispatch(allActions.company.setCompany(true));
    // setSearchParams({ cliente: '' }, { replace: true });
    setCompany({
      cellphone: "",
      cnpj: "",
      createAt: "",
      deleteAt: "",
      email: "",
      emailResponsible: "",
      followup: "",
      id: "",
      name: "",
      phone: "",
      plansId: "",
      responsible: "",
      socialReason: "",
      status: "ACTIVE",
      url: "",
      edit: true,
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
        Cell: ({ row }) => (
          <Dropdown>
            <Dropdown.Item onClick={() => onHandleClickEdit(row)}>
              <a href="">{row.original.name}</a>
            </Dropdown.Item>
          </Dropdown>
        ),
      },
      {
        Header: "Telefone",
        accessor: "phone",
      },
      {
        Header: "Responsável",
        accessor: "responsible",
      },
      {
        Header: "Plano",
        accessor: "plan.description",
      },
      {
        Header: "Usuários",
        accessor: "user",
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "includes",
        Cell: ({ cell }) => (
          <>
            {cell.row.values.status === "ACTIVE" ? "Ativo" : ""}
            {cell.row.values.status === "INACTIVE" ? "Inativo" : ""}
          </>
        ),
      },
      {
        Header: "Ação",
        width: 300,
        Cell: ({ row }) => (
          <Dropdown>
            <Dropdown.Toggle
              className="btn dropdown-icon"
              variant="link"
              id="dropdown-basic"
            >
              <FaEllipsisV />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item onClick={() => onHandleClickEdit(row)}>
                <FaRegEdit /> Editar
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onHandleClickDelete(row)}>
                <FaRegBookmark /> Excluir
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ],
    []
  );

  /**
   * Get all company
   */
  const getAll = async () => {
    const company = await CompanysService.getAll(
      0,
      Environment.LIMITE_DE_LINHAS,
      ""
    );
    setDataResponse(company.data);
    setRemoveLoading(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  //Botão Toolbar
  const buttonToolbar = (
    <Link className="btn btn-primary ms-2" role="button" onClick={newCompany}>
      Novo Cliente
    </Link>
  );

  //Filtro select
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    const options = useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <div className="input-group">
        <span className="input-group-text" id="basic-addon1">
          <FaFilter />
        </span>
        <select
          name={id}
          id={id}
          className="form-select filter-select"
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">Todos Status</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <>
      {type === "MASTER" ? (
        <div>
          {!removeLoading && (
            <Content>
              {openCompany && <EditCliente company={company} />}

              {!openCompany && (
                <>
                  <h1>Painel de Clientes</h1>
                  <Table
                    columns={columns}
                    data={dataResponse}
                    buttonToolbar={buttonToolbar}
                  />
                </>
              )}
            </Content>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "150px",
            }}
          >
            {removeLoading && <Loading />}
          </div>
        </div>
      ) : (
          <Content>
            <div>
              Não existe permissão para acessar essa pagina.
            </div>
          </Content>
      )}
    </>
  );
}

export default Clientes;
