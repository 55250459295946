import './Front.css'
import React from 'react';
import { Outlet } from 'react-router-dom'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

function Front(props) {
    return (
        <div className={props.page}>
            <Header />
                {props.children}
            <Outlet />            
            <Footer />            
        </div>
    )
}

export default Front