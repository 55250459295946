import React from 'react';
import Content from '../../components/Content/Content'
import { useNavigate, Link } from 'react-router-dom';
import FormLogin from '../../components/Form/FormLogin';


function Admin() {

  const history = useNavigate();

  const onSubmit = () => {
    history('/admin/relatos');
  };

  return (
    <Content>	
      <div className="column-container">	
        <div className="card">
            <div className="card-title">
                <h2>Administrador Respeite.me</h2> 
            </div>
            <div className="card-body">
              <form onSubmit={onSubmit}>
                <FormLogin />
              </form>

              <ul className="list-block mt-4">
                <li>
                  <Link to="/recuperar-senha"><small>Esqueceu a Senha?</small></Link>
                </li>
              </ul>
            </div>
        </div>
      </div> 
    </Content>
  );
}

export default Admin;
