import React from "react";
import "../../components/Form/Form.css";
import { Routes, Route } from "react-router-dom";
import { StateMachineProvider, createStore } from "little-state-machine";
import Content from "../../components/Content/Content";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Enviado from "./Enviado";

createStore({});

function Relato() {
  return (
    <Content>
      <div className="relato column-container">
        <div className="card">
          <div className="card-body">
            <StateMachineProvider>
              <Routes>
                <Route path="/" exact element={<Step1 />} />
                <Route path="/step2" element={<Step2 />} />
                <Route path="/step3" element={<Step3 />} />
                <Route path="/step4" element={<Step4 />} />
                <Route path="/step5" element={<Step5 />} />
                <Route path="/enviado" element={<Enviado />} />
              </Routes>
            </StateMachineProvider>
          </div>
        </div>
      </div>
    </Content>
  );
}

export default Relato;
