import React, { useEffect, useState } from "react";
import Content from "../../../components/Content/Content";
import { useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  FaEllipsisV,
  FaRegFileAlt,
  FaFilter,
  FaRegTrashAlt,
  FaRegEdit,
} from "react-icons/fa";
import Table from "../../../components/Table/Table";
import { NarrativeService } from "../../../services/narratives";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../store/actions";
import { Environment } from "../../../environment";
import ViewRelato from "./Relato/ViewRelato";
import { StorageHelper } from "../../../helpers";
import Loading from "../../../components/Loading/Loading";
import moment from "moment";

const arrayStatusVerification = {
  interview: {
    show: false,
    description: "",
  },
  documents: {
    show: false,
    description: "",
  },
  investigation: {
    show: false,
    description: "",
  },
  support: {
    show: false,
    description: "",
  },
  calculationNote: "",
};

const arrayStatusClose = {
  approved: {
    show: false,
    description: "",
  },
  measuresTaken: {
    show: false,
    description: "",
  },
  closingNote: {
    show: false,
    description: "",
  },
};

const statusFiled = {
  show: false,
  description: ""
}

function Relatos() {
  const { id: idUser } = StorageHelper.getItem("user") || {id: null}

  const { companyURL, companyId } = StorageHelper.getItem("url") || "";
  const { type } = StorageHelper.getItem("user") || "";
  console.log('type: ', type);

  const urlRelation = `/${companyURL}/relacao`;

  const [removeLoading, setRemoveLoading] = useState(true);

  const [dataResponse, setDataResponse] = useState([
    {
      id: "",
      code: "",
      relationshipCompany: "",
      classification: "",
      subject: "",
      description: "",
      profile: "",
      recurrent: "",
      involved: "",
      nameInvolved: "",
      someOneElseInvolved: "",
      dateOccurred: "",
      noDate: "",
      partDay: "",
      spaceOccurred: "",
      evidence: "",
      evidenceURL: "",
      type: "",
      status: "",
      messages: "",
      companyId: "",
      Company: "",
      userId: "",
      typeVerification: JSON.stringify(arrayStatusVerification),
      typeClosed: JSON.stringify(arrayStatusClose),
      typeFiled: JSON.stringify(statusFiled),
      edit: false,
    },
  ]);
  const { openNarrative, narrative: narrativeCurrent } = useSelector(
    (state) => state.narrative
  );
  const [, setPageEdit] = useState(openNarrative);
  const [narrative, setNarrative] = useState(narrativeCurrent);
  const dispatch = useDispatch();

  async function onHandleClickEdit(event) {
    setPageEdit(true);
    dispatch(allActions.narrative.setNarrative(true, narrative));

    setNarrative({
      id: event.original.id,
      code: event.original.code,
      relationshipCompany: event.original.relationshipCompany,
      classification: event.original.classification,
      subject: event.original.subject,
      description: event.original.description,
      profile: event.original.profile,
      recurrent: event.original.recurrent,
      involved: event.original.involved,
      nameInvolved: event.original.nameInvolved,
      someOneElseInvolved: event.original.someOneElseInvolved,
      dateOccurred: event.original.dateOccurred,
      noDate: event.original.noDate,
      partDay: event.original.partDay,
      spaceOccurred: event.original.spaceOccurred,
      evidence: event.original.evidence,
      evidenceURL: event.original.evidenceURL,
      type: event.original.type,
      status: event.original.status === 'NEW' ? 'VIEWED' : event.original.status,
      messages: event.original.messages,
      companyId: event.original.companyId,
      Company: event.original.Company,
      userId: event.original.userId,
      typeVerification:
        event.original.typeVerification === null
          ? JSON.stringify(arrayStatusVerification)
          : event.original.typeVerification,
      typeClosed:
        event.original.typeClosed === null
          ? JSON.stringify(arrayStatusClose)
          : event.original.typeClosed,
      typeFiled:
        event.original.typeFiled === null
          ? JSON.stringify(statusFiled)
          : event.original.typeFiled,
      edit: true,
    });

    if (event.original.status === "NEW") {
      await NarrativeService.updateStatusView(
        event.original.id,
        event.original
      );
    }

    await NarrativeService.createLog(
      idUser,
      companyId,
      event.original,
      "VIEWED",
      "VIEWED"
    );
  }

  const columns = useMemo(
    () => [
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "includes",
        className: "col-status",
        Cell: ({ cell }) => (
          <span
            className={
              cell.value === "NEW"
                ? "status-aberto"
                : null || cell.value === "VIEWED"
                ? "status-visualizado"
                : null || cell.value === "VERIFICATION"
                ? "status-analise"
                : null || cell.value === "CLOSED"
                ? "status-encerrado"
                : null || cell.value === "FILED"
                ? "status-cancelado"
                : null
            }
          >
            {cell.value === "NEW" ? "Novo" : ""}
            {cell.value === "VIEWED" ? "Visualizado" : ""}
            {cell.value === "VERIFICATION" ? "Em apuração" : ""}
            {cell.value === "CLOSED" ? "Encerrado" : ""}
            {cell.value === "FILED" ? "Arquivado" : ""}
          </span>
        ),
      },
      {
        Header: "Código",
        accessor: "code",
        Cell: ({ row }) => (
          <Dropdown>
            <Dropdown.Item onClick={() => onHandleClickEdit(row)}>
              <a href="">{row.original.code}</a>
            </Dropdown.Item>
          </Dropdown>
        ),
      },
      {
        Header: "Classificação",
        accessor: "classification",
        Cell: ({ cell }) => (
          <>
            {cell.row.values.classification
              .replaceAll("[", "")
              .replaceAll("]", "")
              .replaceAll('"', "")}
          </>
        ),
      },
      // {
      //   Header: "Assunto",
      //   accessor: "subject",
      // },
      // {
      //   Header: "Perfil",
      //   accessor: "profile",
      // },
      {
        Header: "Criado",
        accessor: "createAt",
        Cell: ({ cell }) => (
          <>{moment(cell.row.values.createAt).format("DD/MM/YYYY") || ""}</>
        ),
      },
      {
        Header: "Ação",
        width: 300,
        Cell: ({ row }) => (
          <Dropdown>
            <Dropdown.Toggle
              className="btn dropdown-icon"
              variant="link"
              id="dropdown-basic"
            >
              <FaEllipsisV />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              {/* <Dropdown.Item href="/admin/relatos/relato"><FaRegFileAlt /> Visualizar</Dropdown.Item> */}
              <Dropdown.Item onClick={() => onHandleClickEdit(row)}>
                <FaRegEdit /> Visualizar
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ],
    []
  );

  /**
   * Get all narratives
   */
  const getAll = async () => {
    const narratives = await NarrativeService.getAll(
      0,
      Environment.LIMITE_DE_LINHAS,
      "",
      idUser
    );
    setDataResponse(narratives.data);
    setRemoveLoading(false);
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    setNarrative(narrativeCurrent);
  }, [narrativeCurrent]);

  //Filtro select
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    const options = useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <div className="input-group">
        <span className="input-group-text" id="basic-addon1">
          <FaFilter />
        </span>
        <select
          name={id}
          id={id}
          className="form-select filter-select"
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">Todos Status</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option === "NEW" ? "Novo" : ""}
              {option === "VIEWED" ? "Visualizado" : ""}
              {option === "VERIFICATION" ? "Em apuração" : ""}
              {option === "CLOSED" ? "Fechado" : ""}
              {option === "FILED" ? "Arquivado" : ""}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <>
      {type === "MASTER" || type === "ADMINISTRATOR" ? (
        <div>
          {!removeLoading && (
            <Content>
              {openNarrative && (
                <ViewRelato narrative={narrative || narrativeCurrent} />
              )}

              {!openNarrative && (
                <>
                  <h1>Painel de Relatos</h1>

                  <Table columns={columns} data={dataResponse} />
                </>
              )}
            </Content>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "150px",
            }}
          >
            {removeLoading && <Loading />}
          </div>
        </div>
      ) : (
        <Content>
          <div>Não existe permissão para acessar essa pagina.</div>
        </Content>
      )}
    </>
  );
}

export default Relatos;
