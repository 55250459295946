import '../StyleBS/Modal.css'
import React from 'react';
import { useState, useMemo } from "react";
import { Modal, Button } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa";
import TableCheckbox from '../Table/TableCheckbox';
import DataRelatos from "../../data/DataRelatos";


function RelacionarRelatos() {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const columns = useMemo(
        () => [
          {
            Header: 'Código',
            accessor: 'codigo',
            Cell: ({ cell }) => (
              <a href="/admin/relatos/relato" target="_blank">{ cell.value }</a>
            )
          },
          {
            Header: 'Classificação',
            accessor: 'classificacao',
          },
          {
            Header: 'Assunto',
            accessor: 'assunto',
          },          
        ],
        []
      );
    
    
    const data = useMemo(() => DataRelatos(), []);
  
    return (
      <>
        
        <ul className="list-log pt-2 pb-2">
            <li>
                RF99478SH66
            </li>
        </ul>        
        
        <div className="mt-4">
          <Button className="btn btn-outline-primary" onClick={handleShow}>
            <FaPlus />
          </Button>
        </div>
  
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
                Relacioar Relatos
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <TableCheckbox columns={columns} data={data} />

          </Modal.Body>
        </Modal>
      </>
    );
  }

  export default RelacionarRelatos;