import React from 'react';
import Content from '../../components/Content/Content'
import { useForm } from 'react-hook-form';
import { NarrativeService } from '../../services/narratives';
import { StorageHelper } from '../../helpers';

function RecuperarAcessoEtapa1() {

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async data => {
    console.log(data);
  }

  return (
    <Content>	
      <div className="column-container">	
        <div className="card">
            <div className="card-title">
                <h2>Enviar email para redefinir a senha.</h2>
            </div>
            <div className="card-body">
                <p>Digite seu e-mail de login para envio do link para redefinação de senha.</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mt-3">
                        <label className="label-small" htmlFor="emailLogin">Seu e-mail de acesso:</label>
                        <input type="email" {...register("emailLogin", { required: true })} className="form-control" id="emailLogin" />
                        {errors.emailLogin && errors.emailLogin.type === "required" && <div role="alert" className="form-alert mb-3" >Este campo é obrigatório</div>}
                    </div> 
                    <div className="row"> 
                        <div className="col-md-12 mt-5">
                            <button type="submit" className="btn btn-primary" >Enviar</button>
                        </div>
                    </div>      
                </form>
            </div>
        </div>
      </div> 
    </Content>
  );
}

export default RecuperarAcessoEtapa1;
