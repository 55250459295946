import { ApiService } from "../axios-config";
import { toast } from 'react-toastify';

/**
 * Get all plans
 * @param page
 * @param filter
 * @returns
 */
const getAll = async (skip, take, filter, sortDirection) => {
  try {
    const url = `/plan`;

    const { data } = await ApiService.get(url, {
      params: { skip, take, filter, sortDirection },
    });
    if (data) {
      return {
        data: data.data,
        totalCount: data.headers,
      };
    }

    return new Error("Erro ao listar os registros.");
  } catch (error) {
    handleApiErrors(error, "Erro ao listar os registros.");
    throw error;
  }
};

/**
 * Create company
 * @param user
 * @returns
 */
const create = async (plan) => {
  try {
    const planNew = {
      title: plan.title,
      description: plan.description,
      limit: plan.limit,
      value: plan.value,
      totalMembership: "01",
    }

    const { data } = await ApiService.post("/plan", planNew);
    
    if (data) {
      toast.success("Plano cadastrada com sucesso.");
      return data.id;

    }

    return new Error("Erro ao criar o registro.");
  } catch (error) {
    handleApiErrors(error, "Erro ao criar o registro.");
    throw error;
  }
};

/**
 * Update company
 * @param id
 * @param company
 */
const updateById = async (id, plan) => {
  try {

    const planUpdate = {
      title: plan.title,
      description: plan.description,
      limit: plan.limit,
      value: plan.value,
      totalMembership: plan.totalMembership,
      companyId: Number(plan.companyId),
    }

    await ApiService.put(`/plan/${Number(id)}`, planUpdate);
    toast.success("Plano atualizado com sucesso.");
  } catch (error) {
    handleApiErrors(error, "Erro ao atualizar o registro.");
    throw error;
  }
};

/**
 * Delete company
 * @param id
 */
const deleteById = async (id) => {
  try {
    console.log("delete id", id);
    await ApiService.delete(`/plan/${id}`);
    toast.success("Empresa deletada com sucesso.");

  } catch (error) {
    handleApiErrors(error, "Erro ao apagar o registro.");
    throw error;
  }
};

/**
 * Handle api errors
 * @param error
 */
export const handleApiErrors = (error, message) => {
  if (error && error.response && error.response.data) {
    switch (error.response.data.statusCode) {
      case 400:
        console.log(
          "Erro ao processar a requisição, verifique os dados enviados e tente novamente!"
        );
        break;
      case 404:
        console.log("Empresa não encontrada");
        break;
      case 500:
        console.log(
          "Erro, o servidor não conseguiu processar a requisição, por favor tente novamente mais tarde ou contate o suporte!"
        );
        break;
      default:
        console.log(message);
        break;
    }
  } else {
    console.log("Erro de conexão.");
  }
};

export const PlanService = {
  getAll,
  create,
  updateById,
  deleteById,
};
