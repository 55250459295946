import { ApiService } from "../axios-config";
import { toast } from 'react-toastify';
import { StorageHelper } from "../../helpers";
import { useNavigate } from "react-router-dom";
import allActions from "../../store/actions";
import { useDispatch } from "react-redux";

const login = async(username, password, companyId) => {
  try {
    const { data } = await ApiService.post('/auth/login', { username, password, companyId });
    let companyDefault = `/${data.user.companyURL}`;

    const relatation = StorageHelper.getItem('relatation');

    if(data.user.type === 'ADMINISTRATOR') {
        companyDefault = 'admin';
    }
    
    if(data.user.type === 'MASTER') {
      companyDefault = 'clientes';
    }
    
    if(data.user.type === 'USER') {
      companyDefault = 'areausuario';
    }

    if(relatation && relatation.isLoginRelacao) {
      companyDefault = 'relacao';
    }

    StorageHelper.removeItem('relatation');
    
    if (data) {
      toast.success("Login com sucesso.");
      window.location.href = companyDefault
      return data;
    }

    return new Error("Erro ao logar o registro.");
  } catch (error) {
    throw error;
  }
}

const signUp = async(data) => {
  try {

    const { data } = await ApiService.post('/v1/users', data);
    
    if (data) {
      toast.success("SignUp");
      return data;
    }

    return new Error("Erro ao deslogar o registro.");
  } catch (error) {
    handleApiErrors(error, "Erro ao deslogar o registro.");
    throw error;
  }
}

/**
 * Handle api errors
 * @param error
 */
 export const handleApiErrors = (error, message) => {
    if (error && error.response && error.response.data) {
      switch (error.response.data.statusCode) {
        case 400:
          toast.error(
            "Erro ao processar a requisição, verifique os dados enviados e tente novamente!"
          );
          break;
        case 404:
          toast.error("Empresa não encontrada");
          break;
        case 500:
          toast.error(
            "Erro, o servidor não conseguiu processar a requisição, por favor tente novamente mais tarde ou contate o suporte!"
          );
          break;
        default:
          toast.error(message);
          break;
      }
    } else {
      console.log("Erro de conexão.");
    }
  };

export const AuthService = {
  login,
  signUp,
};
