import React from 'react';

function InfosClientes(data) {    

    console.log('InfosClientes', data.company);
    
    return (
        <>
            <div className="form-group mt-3">
                <span className="label-result">URL de divulgação:</span>
                <span className="info-result"><a href="https://respeite.me/empresa" target="_blank" rel="noopener noreferrer">respeite.me/{data.company.url}</a></span>
            </div>
            <div className="form-group mt-3">
                <span className="label-result">Nome da conta:</span>
                <span className="info-result">{data.company.name}</span>
            </div>
            <div className="form-group mt-3">
                <span className="label-result">CNPJ:</span>
                <span className="info-result">{data.company.cnpj}</span>
            </div>
            <div className="form-group mt-3">
                <span className="label-result">Seguimento:</span>
                <span className="info-result">{data.company.followup}</span>
            </div>
            <div className="form-group mt-3">
                <span className="label-result">E-mail:</span>
                <span className="info-result">{data.company.email}</span>
            </div>
            <div className="form-group mt-3">
                <span className="label-result">Telefone:</span>
                <span className="info-result">{data.company.phone}</span>
            </div>
            <div className="form-group mt-3">
                <span className="label-result">Contato Responsável:</span>
                <span className="info-result">{data.company.responsible}</span>
                <span className="info-result">{data.company.emailResponsible}</span>
            </div>
        </>
    );
  }
  
export default InfosClientes;