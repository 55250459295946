import "./Footer.css";
import React from "react";
import { Link } from "react-router-dom";
import { StorageHelper } from "../../helpers";

function FooterAdmin() {
  const { companyURL } = StorageHelper.getItem("url") || "";
  const faq = `/${companyURL}/admin/faq`;

  return (
    <footer className="footer text-muted d-flex justify-content-end text-small">
      <p className="mb-1 me-4">© 2022 Respeite.me</p>
      <ul id="footerNav" className="list-inline me-4">
        <li className="list-inline-item">
          <a href="mailto:suporte@respeite.me">Suporte</a>
        </li>
        <li className="list-inline-item">
          <Link to={faq}>FAQ</Link>
        </li>
      </ul>
    </footer>
  );
}

export default FooterAdmin;
