import React from "react";
import { IntlProvider } from "react-intl";

import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Provider } from "react-redux";
import store from "./store";
import { AppContexts } from "./contexts";
import { AppRoutes } from "./routes";

function App() {
  return (
    <IntlProvider locale="pt-BR">
      <ToastContainer
        position="top-center"
        theme="colored"
        transition={Slide}
      />

      <Provider store={store}>
        <AppContexts>
          <AppRoutes />
        </AppContexts>
      </Provider>
    </IntlProvider>
  );
}

export default App;
