import React, { useEffect, useState } from "react";
import Content from "../../../components/Content/Content";
import { useForm } from "react-hook-form";
import { StorageHelper } from "../../../helpers";
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { CompanysService } from "../../../services/empresas";
import { Environment } from "../../../environment";
import { UserService } from '../../../services/users'
// import 'react-toastify/dist/ReactToastify.css';

function UploadUsuarios() {
  const [excelFile, setExcelFile] = useState();
  const [typeError, setTypeError] = useState(null);

  const [excelData, setExcelData] = useState(null);
  const [companys = [], setCompanys] = useState();
  const [selectedCompanyId, setSelectedCompanyId] = useState();

  const { register, handleSubmit } = useForm();

  const { type } = StorageHelper.getItem("user") || "";

  const onSubmit = (data) => {
    handleFileSubmit();
    toast.success("Carga sendo realizada");
  };

  // onchange event
  const handleFile = (e) =>{
    let fileTypes = ['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','text/csv'];
    let selectedFile = e.target.files[0];
      if(selectedFile){
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload=(e)=>{
          setExcelFile(e.target.result);
        }
      }
      else{
        setTypeError('Please select only excel file types');
        setExcelFile(null);
      }
  }
  
  // submit event
  const handleFileSubmit = () => {
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile,{type: 'buffer'});
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);

      data.forEach((func) => {

        UserService.create({
          branch: new String(func.ramal),
          companyId: selectedCompanyId,
          departmentId: func.departamento,
          email: func.email,
          name: func.nome,
          office: func.cargo,
          phone: func.celular,
          role: 'master',
          status: 'ACTIVE',
          type: func.tipo
        });

      });
    }
  }

  const getAllDepartament = async () => {
    const response = await CompanysService.getAll(
      0,
      Environment.LIMITE_DE_LINHAS,
      "",
      ""
    );

    setCompanys(response.data);
  }

  const handleCompanyId = (e) => {
    setSelectedCompanyId(e.target.value);
  }

  useEffect(() => {
    getAllDepartament();
  }, []);

  return (
    <>
      {type === "MASTER" ? (
        <div>
          <Content>
            <h1 className="mb-4">Upload Usuários</h1>
            <div className="row">
              <div className="col-md-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="label-small"
                              htmlFor="selectEmpresa"
                            >
                              Empresa
                            </label>
                            <select
                              className="form-select"
                              id="selectEmpresa"
                              {...register("Empresa")}
                              onChange={handleCompanyId}
                            >
                              <option value="">Selecione a empresa</option>
                              {companys &&
                              companys.map((company) => (
                                <option value={company.id} key={company.id}>
                                  {company.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mt-3">
                            <label
                              className="label-small"
                              htmlFor="arquivoUsuarios"
                            >
                              Arquivo
                            </label>
                            <input
                              type="file"
                              className="form-control ps-3"
                              name="arquivoUsuarios"
                              onChange={handleFile}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="d-grid gap-2 d-md-block mt-5">
                            <button className="btn btn-primary" type="submit">
                              Upload
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </div>
      ) : (
        <Content>
          <div>Não existe permissão para acessar essa pagina.</div>
        </Content>
      )}
    </>
  );
}

export default UploadUsuarios;
