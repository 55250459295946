import React from "react";
import Content from "../../components/Content/Content";
import { useForm } from "react-hook-form";
import { UserService } from "../../services/users";
import { StorageHelper } from "../../helpers";
import { useNavigate } from "react-router-dom";

function RecuperarAcessoEtapa2() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const history = useNavigate();

  async function reset(data) {
    await UserService.resetPassword(data);
  }

  const onSubmit = async (data) => {
    const paramsUrl = window.location.pathname.split("/");
  const { companyURL } = StorageHelper.getItem("url") || {companyURL: paramsUrl[1]};
    const params = window.location.pathname.split("redefine-senha/");
    const payload = {
      password: data.password,
      email: params[1],
    };
    console.log(payload);
    await reset(payload);
    StorageHelper.removeItem('user');
    history(`/${companyURL}/login`);
  };

  return (
    <Content>
      <div className="column-container">
        <div className="card">
          <div className="card-title">
            <h2>Redefinir Senha</h2>
          </div>
          <div className="card-body">
            <p>Digite a nova senha por favor.</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group mt-3">
                <label className="label-small" htmlFor="password">
                  Nova senha de acesso:
                </label>
                <input
                  type="password"
                  {...register("password", { required: true })}
                  className="form-control"
                  id="password"
                />
                {errors.password && errors.password.type === "required" && (
                  <div role="alert" className="form-alert mb-3">
                    Este campo é obrigatório
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-12 mt-5">
                  <button type="submit" className="btn btn-primary">
                    Enviar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Content>
  );
}

export default RecuperarAcessoEtapa2;
