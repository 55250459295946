import { ApiService } from "../axios-config";
import { toast } from "react-toastify";
import { StorageHelper } from "../../helpers";

/**
 * Get all user
 * @param page
 * @param filter
 * @returns
 */
const getAll = async (skip, take, filter, sortDirection) => {
  try {
    const url = `/user`;

    const { companyId } = StorageHelper.getItem("url");

    const { data } = await ApiService.get(url, {
      params: { skip, take, filter, sortDirection, companyId },
    });
    if (data) {
      return {
        data: data.data,
        totalCount: data.headers,
      };
    }

    return new Error("Erro ao listar os registros.");
  } catch (error) {
    handleApiErrors(error, "Erro ao listar os registros.");
    throw error;
  }
};

/**
 * Create user
 * @param user
 * @returns
 */
const create = async (user) => {
  try {
    const { companyURL, companyId } = StorageHelper.getItem("url");

    let idCompany = 0;
    if(user.companyId) {
      idCompany = Number(user.companyId);
    } else {
      idCompany = Number(companyId);
    }
  
    const userNew = {
      branch: user.branch,
      companyId: idCompany,
      departmentId: user.departmentId,
      email: user.email,
      name: user.name,
      office: user.office,
      phone: user.phone,
      role: user.role,
      status: user.status,
      type: user.type,
    };

    const { data } = await ApiService.post("/user", userNew);

    if (data) {
      toast.success("Usuario cadastrada com sucesso.");

      if(!user.companyId) {
        window.location.pathname = `${companyURL}/admin/usuarios`;
      }

      return data.id;
    }

    return new Error("Erro ao criar o registro.");
  } catch (error) {
    handleApiErrors(error, "Erro ao criar o registro.");
    throw error;
  }
};

/**
 * Update user
 * @param id
 * @param user
 */
const updateById = async (id, user) => {
  try {

    const {companyURL, companyId } = StorageHelper.getItem("url");
    const userUpdate = {
      branch: user.branch,
      companyId: companyId,
      departmentId: user.departmentId,
      email: user.email,
      name: user.name,
      office: user.office,
      phone: user.phone,
      role: user.role,
      status: user.status,
      type: user.type,
    };

    await ApiService.put(`/user/${Number(id)}`, userUpdate);
    toast.success("Usuario atualizado com sucesso.");
    
    window.location.pathname = `${companyURL}/admin/usuarios`;
    
  } catch (error) {
    handleApiErrors(error, "Erro ao atualizar o registro.");
    throw error;
  }
};

/**
 * Delete user
 * @param id
 */
const deleteById = async (id) => {
  try {
    console.log("delete id", id);
    await ApiService.delete(`/user/${id}`);
    toast.success("Usuario deletada com sucesso.");
  } catch (error) {
    handleApiErrors(error, "Erro ao apagar o registro.");
    throw error;
  }
};

/**
 * Reset password
 * @param email
 * @param password
 */
const resetPassword = async (payload) => {
  try {
    await ApiService.post(`/user/resetPassword`, payload);
    toast.success("Senha criada com sucesso.");
  } catch (error) {
    handleApiErrors(error, "Erro ao criar senha.");
    throw error;
  }
};

/**
 * Handle api errors
 * @param error
 */
export const handleApiErrors = (error, message) => {
  if (error && error.response && error.response.data) {
    switch (error.response.data.statusCode) {
      case 400:
        toast.error(
          "Erro ao processar a requisição, verifique os dados enviados e tente novamente!"
        );
        break;
      case 404:
        toast.error("Empresa não encontrada");
        break;
      case 500:
        toast.error(
          "Erro, o servidor não conseguiu processar a requisição, por favor tente novamente mais tarde ou contate o suporte!"
        );
        break;
      case 403:
        toast.error("Atenção, email já cadastrado! por favor insira um novo.");
        break;
      default:
        toast.error(message);
        break;
    }
  } else {
    console.log("Erro de conexão.");
  }
};

export const UserService = {
  getAll,
  create,
  updateById,
  deleteById,
  resetPassword,
};
