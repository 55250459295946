const DataPlanos = () => [
    {
        titulo: 'Planos C',
        descricao: 'Até 1000 usuário ativos no sistema',
        limite: '1000',
        valor: '3.000,00',
        adesao: '2',
    },
    {
        titulo: 'Planos B',
        descricao: 'Até 500 usuário ativos no sistema',
        limite: '500',
        valor: '2.000,00',
        adesao: '3',
    },
    {
        titulo: 'Planos A',
        descricao: 'Até 100 usuário ativos no sistema',
        limite: '100',
        valor: '1.000,00',
        adesao: '10',
    },
  ];

export default DataPlanos;