import React from 'react';
import Content from '../../components/Content/Content'
import { useForm } from 'react-hook-form';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

function SolicitarCadastro() {

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = data => {
    console.log(data);
    // toast.success("Pedido Enviado!");
  }

  return (
    <Content>	
      <div className="column-container">	
        <div className="card">
            <div className="card-title">
                <h2>Solicitar Cadastro</h2>
            </div>
            <div className="card-body">
                <p>Não tem cadastro? <br />Preencha o formulário abaixo para enviar uma solicitação de login e senha.</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mt-3">
                        <label className="label-small" htmlFor="nomeCadastro">Nome Completo</label>
                        <input type="text" {...register("nome", { required: true })} className="form-control" id="nomeCadastro" />
                        {errors.nome && errors.nome.type === "required" && <div role="alert" className="form-alert mb-3" >Este campo é obrigatório</div>}
                    </div>
                    <div className="form-group mt-3">
                        <label className="label-small" htmlFor="emailCadastro">E-mail</label>
                        <input type="email" {...register("email", { required: true })} className="form-control" id="emailCadastro" />
                        {errors.email && errors.email.type === "required" && <div role="alert" className="form-alert mb-3" >Este campo é obrigatório</div>}
                    </div> 
                    <div className="row"> 
                        <div className="col-md-12 mt-5">
                            <button type="submit" className="btn btn-primary" >Solicitar</button>
                        </div>
                    </div>      
                </form>
            </div>
        </div>
      </div> 
    </Content>
  );
}

export default SolicitarCadastro;
