import './BackMaster.css'
import React from 'react';
import { Outlet } from 'react-router-dom';
import HeaderMaster from '../../components/Header/HeaderMaster';
import FooterMaster from '../../components/Footer/FooterMaster';
import SideBarMaster from '../../components/SideBar/SideBarMaster';

function BackMaster(props) {
    return (
        <div className={props.page}>
            <HeaderMaster />
            <SideBarMaster />
            {props.children}
            <Outlet />            
            <FooterMaster />
        </div>
    )
}

export default BackMaster