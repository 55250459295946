import './Header.css'
import logo from '../../assets/img/logo-respeiteme.svg'
import React from 'react';
import NavUser from '../NavUser/NavUser';
import { Link } from "react-router-dom";
import { StorageHelper } from "../../helpers";

function Header() {    
    // const { companyURL } = StorageHelper.getItem("url") || "";
    const params = window.location.pathname.split("/");
    const { companyURL } = StorageHelper.getItem("url") || {companyURL: params[1]};
    const header = `/${companyURL}`;
    return (
        <header className="header">
            <div className="logo">
			    <Link to={header} className="d-block">
                    <img src={logo} alt="Respeite.me" className="logo-respeiteme" />
				</Link>
			</div>
			<nav className="nav">
                <NavUser />
			</nav>
        </header>
    );
  }
  
  export default Header;