import './Footer.css'
import React from 'react';

function FooterMaster() {    
    return (
        <footer className="footer text-muted text-center text-small">
            <p className="mb-1">© 2022 Respeite.me</p>
        </footer>
    );
  }
  
  export default FooterMaster;