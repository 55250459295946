import "../../../../components/StyleBS/Modal.css";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button } from "react-bootstrap";
import { StorageHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import allActions from "../../../../store/actions";
import { useDispatch } from "react-redux";
import { NarrativeService } from "../../../../services/narratives";

function StatusApurar(props) {
  const [dataNarrative] = useState(props);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const { id: idUser, companyId, email } = StorageHelper.getItem("user");

    const arrayStatusVerification = {
      interview: {
        show: data.interview,
        description: "Entrevista com envolvidos",
      },
      documents: {
        show: data.documents,
        description: "Análise de documentos",
      },
      investigation: {
        show: data.investigation,
        description: "Investigação externa",
      },
      support: {
        show: data.support,
        description: "Apoio técnico",
      },
      calculationNote: data.calculationNote,
    };
    const arrayStatusClose = {
      approved: {
        show: false,
        description: "",
      },
      measuresTaken: {
        show: false,
        description: "",
      },
      closingNote: {
        show: false,
        description: "",
      },
    };
    const statusFiled = {
      show: false,
      description: "",
    };
    const narrative = {
      ...dataNarrative.props.narrative,
      status: "VERIFICATION",
      typeVerification: JSON.stringify(arrayStatusVerification),
      typeClosed: JSON.stringify(arrayStatusClose),
      typeFiled: JSON.stringify(statusFiled),
    };

    await NarrativeService.createLog(
      idUser,
      companyId,
      narrative,
      "UPDATED",
      JSON.stringify(arrayStatusVerification)
    );
    await NarrativeService.updateById(narrative.id, narrative);

    dispatch(allActions.narrative.setNarrative(true, narrative));
    handleClose();
    toast.success("Status do relato alterado para: EM APURAÇÃO");
  };

  return (
    <>
      <Button className="btn btn-primary" onClick={handleShow}>
        Apurar
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Mudar status para:{" "}
            <span className="status status-analise">Em apuração</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-check form-switch mt-4">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="investigacaoEntrevista"
                aria-checked
                {...register("interview")}
              />
              <label
                className="form-check-label"
                htmlFor="investigacaoEntrevista"
              >
                Entrevista com envolvidos
              </label>
            </div>
            <div className="form-check form-switch mt-1">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="investigacaoDocumentos"
                aria-checked
                {...register("documents")}
              />
              <label
                className="form-check-label"
                htmlFor="investigacaoDocumentos"
              >
                Análise de documentos
              </label>
            </div>
            <div className="form-check form-switch mt-1">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="investigacaoExterna"
                aria-checked
                {...register("investigation")}
              />
              <label className="form-check-label" htmlFor="investigacaoExterna">
                Investigação externa
              </label>
            </div>
            <div className="form-check form-switch mt-1">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="investigacaoTecnico"
                aria-checked
                {...register("support")}
              />
              <label className="form-check-label" htmlFor="investigacaoTecnico">
                Apoio técnico
              </label>
            </div>
            <div className="form-group mt-3">
              <label htmlFor="notaStatusAnalise">Nota</label>
              <span className="label-sub">
                Para uso interno. Não será disponibilizado para o autor do
                relato
              </span>
              <textarea
                className="form-control"
                id="notaStatusAnalise"
                rows="3"
                {...register("calculationNote")}
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">
              Mudar
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StatusApurar;
