import "../../components/Form/Form.css";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { useNavigate } from "react-router-dom";
import updateAction from "./updateAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import allActions from "../../store/actions";
import DuvidaClassificacao from "../../components/DuvidaClassificacao/DuvidaClassificacao";
import { StorageHelper } from "../../helpers";

const Step1 = () => {
  const params = window.location.pathname.split("/");
  const { companyURL } = StorageHelper.getItem("url") || {companyURL: params[1]};
  const { narrative } = useSelector((state) => state.narrative);
  const [selectedRadio, setSelectedRadio] = useState(narrative.recurrent || 'Não Recorrente');

  const history = useNavigate();
  const {
    register,    
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return narrative;
    }, [narrative])
  });
  const { actions, state } = useStateMachine({ updateAction });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const { subject, classification, description, recurrent} = data;
    const narrativeNew = {
      id: "",
      code: "",
      relationshipCompany: narrative.relationshipCompany,
      classification,
      subject,
      description,
      recurrent,
      profile: "",
      involved: "",
      nameInvolved: "",
      someOneElseInvolved: "",
      dateOccurred: "",
      noDate: "",
      partDay: "",
      spaceOccurred: "",
      evidence: "",
      type: narrative.type,
      status: "",
      messages: "",
      companyId: "",
      company: "Teste ",
      userId: "",
    };
    dispatch(allActions.narrative.setNarrative(false, narrativeNew));
    actions.updateAction(data);    
    history(`/${companyURL}/relato/step2`);
  };

  const handleChange = event => {
    setSelectedRadio(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="mb-5">O que e como aconteceu?</h2>
      <div className="form-group">
        <label htmlFor="classificacaoOcorrido">
          Classifique o ocorrido <span className="label-msg">*</span>
        </label>
        <span className="label-sub mb-3">
          Precisa de ajuda para classificar <DuvidaClassificacao />.
        </span>
        {errors.classificacao && errors.classificacao.type === "required" && (
          <div role="alert" className="form-alert mb-3">
            Selecione pelo menos uma classificação
          </div>
        )}
        <div className="row">
          <div className="col">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoDiscriminacao"
                {...register("classification", { required: true })}
                defaultValue="Discriminação"
              />
              <label
                className="form-check-label"
                htmlFor="classificacaoDiscriminacao"
              >
                Discriminação
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoAssedioSexual"
                {...register("classification", { required: true })}
                defaultValue="Assédio Sexual"
              />
              <label
                className="form-check-label"
                htmlFor="classificacaoAssedioSexual"
              >
                Assédio Sexual
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoRacismo"
                {...register("classification", { required: true })}
                defaultValue="Racismo"
              />
              <label
                className="form-check-label"
                htmlFor="classificacaoRacismo"
              >
                Racismo
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoLGBTQIAfobia"
                {...register("classification", { required: true })}
                defaultValue="LGBTQIAfobia"
              />
              <label
                className="form-check-label"
                htmlFor="classificacaoLGBTQIAfobia"
              >
                LGBTQIAfobia
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoBullying"
                {...register("classification", { required: true })}
                defaultValue="Bullying"
              />
              <label
                className="form-check-label"
                htmlFor="classificacaoBullying"
              >
                Bullying
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoAssedioMoral"
                {...register("classification", { required: true })}
                defaultValue="Assédio Moral"
              />
              <label
                className="form-check-label"
                htmlFor="classificacaoAssedioMoral"
              >
                Assédio Moral
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoAgressao"
                {...register("classification", { required: true })}
                defaultValue="Agressão"
              />
              <label
                className="form-check-label"
                htmlFor="classificacaoAgressao"
              >
                Agressão
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoAbusoPoder"
                {...register("classification", { required: true })}
                defaultValue="Abuso de Poder"
              />
              <label
                className="form-check-label"
                htmlFor="classificacaoAbusoPoder"
              >
                Abuso de Poder
              </label>
            </div>
          </div>
          <div className="col">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoCorrupcao"
                {...register("classification", { required: true })}
                defaultValue="Corrupção"
              />
              <label
                className="form-check-label"
                htmlFor="classificacaoCorrupcao"
              >
                Corrupção
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoFraude"
                {...register("classification", { required: true })}
                defaultValue="Fraude"
              />
              <label className="form-check-label" htmlFor="classificacaoFraude">
                Fraude
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoSuborno"
                {...register("classification", { required: true })}
                defaultValue="Suborno"
              />
              <label
                className="form-check-label"
                htmlFor="classificacaoSuborno"
              >
                Suborno
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoConflitoInteresses"
                {...register("classification", { required: true })}
                defaultValue="Conflito de Interesses"
              />
              <label
                className="form-check-label"
                htmlFor="classificacaoConflitoInteresses"
              >
                Conflito de Interesses
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoDesconforto"
                {...register("classification", { required: true })}
                defaultValue="Desconforto"
              />
              <label
                className="form-check-label"
                htmlFor="classificacaoDesconforto"
              >
                Desconforto
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoInsulto"
                {...register("classification", { required: true })}
                defaultValue="Insulto"
              />
              <label
                className="form-check-label"
                htmlFor="classificacaoInsulto"
              >
                Insulto
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoNaoSei"
                {...register("classification", { required: true })}
                defaultValue="Não Sei"
              />
              <label className="form-check-label" htmlFor="classificacaoNaoSei">
                Não sei
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="classificacaoOutros"
                {...register("classification", { required: true })}
                defaultValue="Outros"
              />
              <label className="form-check-label" htmlFor="classificacaoOutros">
                Outros
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="form-group mt-5">
        <label htmlFor="subject" className="mb-3">
          Em poucas palavras, do que se trata o relato?{" "}
          <span className="label-msg">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="subject"
          {...register("subject", { required: true })}
          defaultValue={state.assuntoRelato}
        />
        {errors.subject && errors.subject.type === "required" && (
          <div role="alert" className="form-alert mb-3">
            Este campo é obrigatório
          </div>
        )}
      </div> */}

      <div className="form-group mt-5">
        <label htmlFor="descricaoRelato">
          Como aconteceu? <span className="label-msg">*</span>
        </label>
        <span className="label-sub mb-3">
          Descreva tudo que conseguir lembrar
        </span>
        <textarea
          className="form-control"
          rows="3"
          id="descricaoRelato"
          {...register("description", { required: true })}
          defaultValue={state.description}
        ></textarea>
        {errors.description && errors.description.type === "required" && (
          <div role="alert" className="form-alert mb-3">
            Este campo é obrigatório
          </div>
        )}
      </div>

      <div className="form-group mt-5">
        <label htmlFor="episodioRecorrente" className="mb-3">
          Este episódio é recorrente?
        </label>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            id="naoRecorrente"
            name="episodioRecorrente"
            {...register("recurrent")}
            value="Não Recorrente"
            checked={selectedRadio === 'Não Recorrente'}
            onChange={handleChange}
          />
          <label
            className="form-check-label label-small"
            htmlFor="naoRecorrente"
          >
            Não, esta é a primeira vez.
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            id="simRecorrente"
            name="episodioRecorrente"
            {...register("recurrent")}
            value="Recorrente"
            checked={selectedRadio === 'Recorrente'}
            onChange={handleChange}
          />
          <label
            className="form-check-label label-small"
            htmlFor="simRecorrente"
          >
            Sim, já aconteceu antes.
          </label>
        </div>
      </div>
      <div className="d-grid gap-2 d-md-block mt-5">
        <button className="btn btn-primary" type="submit">
          Continuar
        </button>
      </div>
    </form>
  );
};

export default Step1;
