import './Table.css';
import React from 'react';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaSearch } from "react-icons/fa";
import { useState } from 'react'
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination, useAsyncDebounce } from 'react-table'

// Filtro Busca

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    //const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
  
    return (
      <>
        <div className="input-group me-3">
          <span className="input-group-text" id="basic-addon1"><FaSearch /></span>
          <input
          value={value || ""}
          onChange={e => {
              setValue(e.target.value);
              onChange(e.target.value);
          }}
          placeholder="Busca"
          className="form-control"
          aria-label="Busca" 
          aria-describedby="basic-addon1"
          />
            
        </div>
      </>
    )
  }




function Table({ columns, data, buttonToolbar }) {

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, state, preGlobalFilteredRows, setGlobalFilter, canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize, } =
    useTable({
      columns,
      data,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
    )

  
  return (
    <>
        <div className="toolbar d-flex justify-content-md-between flex-wrap flex-md-nowrap align-items-center mt-4">						
            <div className="filter-toolbar mb-3">

                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                
                {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) =>
                    column.Filter ? (
                        <div key={column.id} className="input-group me-3">
                            {column.render("Filter")}
                        </div>
                    ) : null
                    )
                )}
                
            </div>

            <div className="btn-toolbar mb-3">
              {buttonToolbar}
            </div>
        </div>
        <div className="card">															
            <div className="card-body">
                <div className="grid">
                    <div className="table-responsive mb-2">
                      <table {...getTableProps()} className="table">
                          <thead>
                              {headerGroups.map((headerGroup) => (
                              <tr {...headerGroup.getHeaderGroupProps()}>
                                  {headerGroup.headers.map((column) => (
                                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>
                                      {column.isSorted
                                        ? column.isSortedDesc
                                          ? ' ▼'
                                          : ' ▲'
                                        : ''}
                                    </span>
                                  </th>
                                  ))}
                              </tr>
                              ))}
                          </thead>
                          <tbody {...getTableBodyProps()}>
                              {page.map((row, i) => {
                              prepareRow(row);
                              return (
                                  <tr {...row.getRowProps()}>
                                  {row.cells.map((cell) => {
                                      return <td {...cell.getCellProps(
                                        [{
                                          className: cell.column.className,
                                        }]
                                      )}>{cell.render("Cell")}</td>;
                                  })}
                                  </tr>
                              );
                              })}
                          </tbody>
                      </table>
                    </div>

                    {/* Paginação */}
                    <div className="footer-grid">
                      <div className="info-grid pt-2 pb-2 d-flex align-items-center">
                        <span className="me-3 pageindex">
                          Page{' '}
                          <strong>
                            {state.pageIndex + 1} de {pageOptions.length}
                          </strong>{' '}
                        </span>
                        <select
                          className="form-select form-select-sm"
                          value={state.pageSize}
                          onChange={e => {
                              setPageSize(Number(e.target.value))
                          }}
                        >
                          {[1, 10, 20].map(pageSize => (
                              <option key={pageSize} value={pageSize}>
                              Mostra {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                      <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-md-end">
                          <li className="page-item">
                            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="page-link">
                              <FaAngleDoubleLeft />
                            </button>{' '}
                          </li>
                          <li className="page-item">
                            <button onClick={() => previousPage()} disabled={!canPreviousPage} className="page-link">                              
                              <FaAngleLeft />
                            </button>{' '}
                          </li>
                          <li className="page-item">
                            <button onClick={() => nextPage()} disabled={!canNextPage} className="page-link">
                              <FaAngleRight />
                            </button>{' '}
                          </li>
                          <li className="page-item">
                            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="page-link">
                              <FaAngleDoubleRight />
                            </button>{' '}
                          </li>
                        </ul>
                      </nav>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default Table;