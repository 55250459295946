import React from "react";
import BlurText from "../BlurText/BlurText";

function InfosRelato(props) {
  console.log("InfosRelato", props);
  return (
    <>
      {props.narrative && (
        <>
          <div className="form-group mt-4">
            <span className="label-result">Autor do relato:</span>
            <span className="info-result">
              {props.narrative.type === "ANONYMOUS" ? " Anônimo" : ""}
              {props.narrative.type === "IDENTIFIED" ? " Identificado" : ""}
              </span>
            <span className="txt-result">Colaborador</span>
          </div>
          <div className="form-group">
            <span className="label-result">Caso de:</span>
            <span className="info-result">
              {props.narrative.classification}
            </span>
            <span className="txt-result">
              {props.narrative.recurrent
                ? "Sim recorrente"
                : "Não recorrente"}
            </span>
          </div>
          {/* <div className="form-group">
            <span className="label-result">O que ocorreu:</span>
            <span className="info-result">{props.narrative.subject}</span>
            <p className="txt-result">{props.narrative.spaceOccurred}</p>
          </div> */}
          <div className="form-group">
            <span className="label-result">A situação ocorreu:</span>
            <span className="info-result">
              {props.narrative.description}
            </span>
          </div>
          <div className="form-group">
            <span className="label-result">Causador(es) da situação:</span>
            <span className="info-result">
              <BlurText>{props.narrative.nameInvolved}</BlurText>
            </span>
          </div>
          <div className="form-group">
            <span className="label-result">
              Outro(s) presente(s) na situação:
            </span>
            <span className="info-result">
              <BlurText>{props.narrative.someOneElseInvolved}</BlurText>
            </span>
          </div>
          <div className="form-group">
            <span className="label-result">Data do Ocorrido:</span>
            <span className="info-result">
              {props.narrative.dateOccurred}
            </span>
          </div>
          <div className="form-group">
            <span className="label-result">Espaço do Ocorrido:</span>
            <span className="info-result">
              {props.narrative.spaceOccurred}
            </span>
          </div>
          <div className="form-group">
            <span className="label-result">Evidências:</span>
            <ul>
              <li>
                <a href={props.narrative.evidenceURL} target="_blank">
                  {props.narrative.evidence}
                </a>
              </li>
              {/* <li><a href="foto.jpg">video992.mp4</a></li> */}
            </ul>
          </div>
        </>
      )}
    </>
  );
}

export default InfosRelato;
