// eslint-disable-next-line
import { AxiosRequestConfig } from "axios";

/**
 * Intercept all request submissions to add bearer token
 * @param request
 * @returns
 */
export function authInterceptor(
  request
) {
  // const { authenticationResult } = store.getState().auth;
  // if (!authenticationResult) {
  //   return request;
  // }

  // request.headers.Authorization = `Bearer ${authenticationResult.idToken}`;
  request.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjIsInVzZXJuYW1lIjoiQXJ0aHVyIiwicm9sZSI6IlVTRVIiLCJpYXQiOjE2NjY1NDk5MTYsImV4cCI6MTY2NjU0OTk3Nn0.FFcQSK17krj42wBDCxWK27dXgLtjP6JSNAoxvm9z7VU`;
  return request;
}

export function loadingBefore(request) {
  // store.dispatch(allActions.loadingActions.setLoading(true));
  return request;
}

export function loadingAfter(response) {
  // store.dispatch(allActions.loadingActions.setLoading(false));
  return response;
}
