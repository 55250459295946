import "./AreaUsuario.css";
import "../../components/Button/Button.css";
import React, { useEffect, useState } from "react";
import Content from "../../components/Content/Content";
import { Link } from "react-router-dom";
import { NarrativeService } from "../../services/narratives";
import TimeLine from "../Acompanhamento/TimeLine";
import { StorageHelper } from "../../helpers";

function AreaUsuario() {
  const { companyURL } = StorageHelper.getItem("url") || "";
  const identificacao = `/${companyURL}/identificacao`;

  const [openTimeLine, setOpenTimeLine] = useState(false);
  const [narratives, setNarratives] = useState([
    {
      id: "",
      code: "",
      relationshipCompany: "",
      classification: "",
      subject: "",
      description: "",
      profile: "",
      recurrent: "",
      involved: "",
      nameInvolved: "",
      someOneElseInvolved: "",
      dateOccurred: "",
      noDate: "",
      partDay: "",
      spaceOccurred: "",
      evidence: "",
      evidenceURL: "",
      type: "",
      status: "",
      messages: "",
      companyId: "",
      Company: "",
      userId: "",
      typeVerification: "",
      typeClosed: "",
      createAt: "",
      updateAt: "",
      edit: false,
    },
  ]);

  const [narrative, setNarrative] = useState({
    id: "",
    code: "",
    relationshipCompany: "",
    classification: "",
    subject: "",
    description: "",
    profile: "",
    recurrent: "",
    involved: "",
    nameInvolved: "",
    someOneElseInvolved: "",
    dateOccurred: "",
    noDate: "",
    partDay: "",
    spaceOccurred: "",
    evidence: "",
    evidenceURL: "",
    type: "",
    status: "",
    messages: "",
    companyId: "",
    Company: "",
    userId: "",
    typeVerification: "",
    typeClosed: "",
    createAt: "",
    updateAt: "",
    edit: false,
  });

  /**
   * Get all narratives
   */
  const getAllNarrativesByUser = async () => {
    const getNarratives = await NarrativeService.findNarrativeByUser();
    //console.log("area do usuario", getNarratives);
    setNarratives(getNarratives.data);
  };

  useEffect(() => {
    getAllNarrativesByUser();
  }, []);

  return (
    <>
      {!openTimeLine && (
        <Content>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-4">
                <Link className="btn btn-primary" to={identificacao} role="button">
                  Fazer Relato
                </Link>
              </div>
              <p>
                Dúvidas de como fazer? <br />
                <Link to="/duvidas">Veja como funciona.</Link>
              </p>
            </div>
            <div className="col-md-9">
              <div className="page-header pb-3">
                <h1>Seus relatos</h1>
              </div>

              {narratives.length > 0 &&
                narratives.map((narrative) => (
                  <div key={narrative.id} className="card item-relato">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <span className="label-result">Relato Número:</span>

                            <button
                              to={`/${companyURL}/timeline`}
                              role="button"
                              className="btn btn-link"
                              onClick={() => {
                                setNarrative(narrative);
                                setTimeout(() => {
                                  setOpenTimeLine(true);
                                }, 200);
                              }}
                            >
                              {narrative.code}{" "}
                              <span className="status status-analise">
                                {narrative.status}
                              </span>
                            </button>

                            {/* <Link className="info-result" to={`/${companyURL}/limeline`}>
                              {narrative.code}{" "}
                              <span className="status status-analise">
                                {narrative.status}
                              </span>
                            </Link> */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <span className="label-result">Assunto:</span>
                            <span className="info-result">
                              {narrative.subject}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="label-result">Criado:</span>
                            <span className="info-result">
                              {narrative.createAt}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="label-result">Enviado:</span>
                            <span className="info-result">
                              {narrative.dateAnalysis}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="label-result">Atualizado:</span>
                            <span className="info-result">
                              {narrative.updateAt}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <button
                              to={`/${companyURL}/timeline`}
                              role="button"
                              className="btn btn-link"
                              onClick={() => {
                                setNarrative(narrative);
                                setTimeout(() => {
                                  setOpenTimeLine(true);
                                }, 200);
                              }}
                            >
                              Acompanhar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              {narratives.length === 0 && (
                <div className="card item-relato">
                  <div className="card-body">
                    <div className="px-4 py-4 my-4 text-center">
                      <p>Não possui relatos</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Content>
      )}

      {openTimeLine && <TimeLine props={narrative} />}
    </>
  );
}

export default AreaUsuario;
