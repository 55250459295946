import React from 'react';
import Content from "../../../components/Content/Content";


function Faq() {
  
  return (
    <Content>
      <h1 className="mb-4">Perguntas e respostas</h1> 
      <div className="card">															
		<div className="card-body">
            <p className="text-center mt-5 mb-5">Em Breve</p>
        </div>
      </div>
    </Content>
  );
}

export default Faq;