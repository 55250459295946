import React from 'react';
import Content from "../../../components/Content/Content";


function ConfiguracoesMaster() {
  
  return (
    <Content>
        <h1 className="mb-4">Configurações</h1>
        <div className="row">
            <div className="col-md-12">
                <div className="card mb-4">
                    <div className="card-body">
                        <p>configuraçoes</p>
                    </div>
                </div>
            </div>
        </div>

    </Content>
  );
}

export default ConfiguracoesMaster;