import { SET_USER } from "./types";

const setUser = (openUser) => ({
  type: SET_USER,
  payload: {
    openUser,
  },
});

export default {
  setUser,
};
