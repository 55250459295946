import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { StorageHelper } from "../helpers";
import Front from "../layout/Front/Front";
import { NotFoundPage } from "../pages/Errors";
import Login from "../pages/Login/Login";

import Home from "../pages/Home/Home";
import Identificacao from "../pages/Identificacao/Identificacao";
import Relacao from "../pages/Relacao/Relacao";
import Acompanhamento from "../pages/Acompanhamento/Acompanhamento";
import Relato from "../pages/Relato/Relato";
import Duvidas from "../pages/Duvidas/Duvidas";
import DefinirAcesso from "../pages/DefinirAcesso/DefinirAcesso";

import RecuperarAcessoEtapa1 from "../pages/RecuperarAcesso/RecuperarAcessoEtapa1";
import RecuperarAcessoEtapa2 from "../pages/RecuperarAcesso/RecuperarAcessoEtapa2";
import RedirectRespeiteme from "./RedirectRespeiteme";

const routes = StorageHelper.getItem("routes");
const params = window.location.pathname.split("/");
let urlDefault = params[1];

if (routes && routes.length > 0) {
  const newRoute = routes.filter((route) => route.url === params[1]);
  if (newRoute && newRoute.length > 0) {
    StorageHelper.setItem("company", newRoute[0]);
    urlDefault = newRoute[0].url;
    StorageHelper.setItem("url", {
      companyId: newRoute[0].id,
      companyURL: newRoute[0].url,
    });
  }
}

export const UnauthenticatedRoutes = createBrowserRouter([
  {
    path: "/",
    element: <RedirectRespeiteme />,
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}`,
    element: (
      <Front page="layout-front-home">
        <Home />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/login`,
    element: (
      <Front page="layout-front">
        <Login />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },

  {
    path: `/${urlDefault}/identificacao`,
    element: (
      <Front page="layout-front">
        <Identificacao />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/relacao`,
    element: (
      <Front page="layout-front">
        <Relacao />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/relato/*`,
    element: (
      <Front page="layout-front">
        <Relato />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/acompanhamento`,
    element: (
      <Front page="layout-front">
        <Acompanhamento />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/duvidas`,
    element: (
      <Front page="layout-front">
        <Duvidas />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/admin`,
    element: (
      <Front page="layout-front">
        <Login />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/master`,
    element: (
      <Front page="layout-front">
        <Login />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/master/clientes`,
    element: (
      <Front page="layout-front">
        <Login />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/recuperar-acesso/*`,
    element: (
      <Front page="layout-front">
        <RecuperarAcessoEtapa1 />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/redefine-senha/*`,
    element: (
      <Front page="layout-front">
        <RecuperarAcessoEtapa2 />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/acesso`,
    element: (
      <Front page="layout-front">
        <DefinirAcesso />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  }
  /*{
    path: `/${urlDefault}`,
    element: (
      <Front page="layout-front-home">
        <Home />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/duvidas`,
    element: (
      <Front page="layout-front">
        <Duvidas />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/termos`,
    element: (
      <Front page="layout-front">
        <Termos />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/solicitar-cadastro`,
    element: (
      <Front page="layout-front">
        <SolicitarCadastro />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/recuperar-acesso-envio-email`,
    element: (
      <Front page="layout-front">
        <RecuperarAcessoEtapa1 />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  
  {
    path: `/${urlDefault}/acesso`,
    element: (
      <Front page="layout-front">
        <DefinirAcesso />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/identificacao`,
    element: (
      <Front page="layout-front">
        <Identificacao />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/relacao`,
    element: (
      <Front page="layout-front">
        <Relacao />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/acompanhamento`,
    element: (
      <Front page="layout-front">
        <Acompanhamento />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/timeline`,
    element: (
      <Front page="layout-front">
        <TimeLine />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/areausuario`,
    element: (
      <Front page="layout-front">
        <p align="center">
          Por favor realizar login para ter acesso ao sistema.
        </p>
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: `/${urlDefault}/relato/*`,
    element: (
      <Front page="layout-front">
        <Relato />
      </Front>
    ),
    errorElement: <NotFoundPage />,
  },*/
  //   {
  //     path: '/cadastrar',
  //     element: <SignUpPage />,
  //     errorElement: <NotFoundPage />,
  //   },
]);
