import "./Header.css";
import logo from "../../assets/img/simbolo-respeiteme.svg";
import React from "react";
import NavAdmin from "../NavAdmin/NavAdmin";
import { Link } from "react-router-dom";
import { StorageHelper } from "../../helpers";

function HeaderAdmin() {
  const { companyURL } = StorageHelper.getItem("url") || "";
  const admin = `/${companyURL}`;

  return (
    <header className="header">
      <div className="logo">
        <Link to={admin} className="d-block">
          <img src={logo} alt="Respeite.me" className="simbolo-respeiteme" />
        </Link>
      </div>
      <nav className="nav">
        <NavAdmin />
      </nav>
    </header>
  );
}

export default HeaderAdmin;
