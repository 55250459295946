import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StorageHelper } from "../helpers";
import { ApiService } from "../services/axios-config";
import { CompanysService } from "../services/empresas";

import { AuthService } from "../services/login";

const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(StorageHelper.getItem("user"));
  const [token, setToken] = useState(StorageHelper.getItem("token"));

  async function login(email, password) {
    const data = await AuthService.login(email, password);
    if (!!data) {
      setToken(data.token);
      setUser(data.user);
      StorageHelper.setItem("user", data.user);
      StorageHelper.setItem("token", data.token);
      StorageHelper.setItem("url", data.user);
      StorageHelper.setItem("type", data.user.type);

      await CompanysService.getAllRoutesCompanys();
    }
  }

  async function signUp(payload) {
    const { status, data } = await AuthService.signUp(payload);
    if (status === 201) {
      setUser(data);
    }
  }

  useEffect(() => {
    if (token) {
      ApiService.setBearerToken(token);
    }
  }, [token]);

  return (
    <AuthContext.Provider
      value={{ user, setUser, token, setToken, login, signUp }}
    >
      {children}
    </AuthContext.Provider>
  );
}
