import { ApiService } from "../axios-config";
import { toast } from "react-toastify";
import { StorageHelper } from "../../helpers";

/**
 * Get all narratives
 * @param page
 * @param filter
 * @returns
 */
const getAll = async (skip, take, filter, sortDirection, idUser) => {
  try {
    const url = `/narrative`;

    const { companyId } = StorageHelper.getItem("url");

    const { data } = await ApiService.get(url, {
      params: { skip, take, filter, sortDirection, idUser, companyId },
    });
    if (data) {
      return {
        data: data.data,
        totalCount: data.headers,
      };
    }

    return new Error("Erro ao listar os registros.");
  } catch (error) {
    handleApiErrors(error, "Erro ao listar os registros.");
    throw error;
  }
};

/**
 * Get all narratives
 * @param page
 * @param filter
 * @returns
 */
const findByCodeNarrative = async (skip, take, filter, sortDirection) => {
  try {
    const url = `/narrative/findByCodeNarrative`;

    const { data } = await ApiService.get(url, {
      params: { skip, take, filter, sortDirection },
    });
    if (data) {
      return {
        data: data.data[0],
        totalCount: data.headers,
      };
    }

    return new Error("Erro ao buscar relato.");
  } catch (error) {
    handleApiErrors(error, "Erro ao listar os registros.");
    throw error;
  }
};

/**
 * Get all narratives by user
 * @param page
 * @param filter
 * @returns
 */
const findNarrativeByUser = async () => {
  try {
    const { id: userId, companyId } = StorageHelper.getItem("user");
    const url = `/narrative/findNarrativeByUser`;

    const { data } = await ApiService.get(url, {
      params: { userId, companyId },
    });
    if (data) {
      return {
        data: data,
      };
    }

    return new Error("Erro ao buscar relato.");
  } catch (error) {
    handleApiErrors(error, "Erro ao listar os registros.");
    throw error;
  }
};

/**
 * Create narrative
 * @param narrative
 * @returns
 */
const create = async (narrative) => {
  try {
    const params = window.location.pathname.split("/");
    const routes = StorageHelper.getItem("routes");
    const newRoute = routes.filter((route) => route.url === params[1]);
    const companyId = newRoute[0].id;
    const { id: idUser } = StorageHelper.getItem("user") || {id: null};

    narrative.userId = idUser;
    narrative.companyId = companyId;

    const { data } = await ApiService.post("/narrative", narrative);

    if (data) {
      toast.success("Relato cadastrado com sucesso.");
      return data.id;
    }

    return new Error("Erro ao criar o registro.");
  } catch (error) {
    handleApiErrors(error, "Erro ao criar o registro.");
    throw error;
  }
};

/**
 * Update narrative
 * @param id
 * @param narrative
 */
const updateById = async (id, narrative) => {
  try {
    const user = StorageHelper.getItem("user");
    let idUser;
    let companyId;
    if(user) {
      idUser = user.id;
      companyId = user.companyId;
    } else {
      const company = StorageHelper.getItem("company");
      idUser = null;
      companyId = company.id;
    }

    delete narrative.id;
    delete narrative.edit;
    delete narrative.createAt;
    delete narrative.updateAt;
    delete narrative.deleteAt;
    delete narrative.dateAnalysis;
    delete narrative.dateClosed;
    delete narrative.dateFiled;

    narrative.userId = idUser;
    narrative.companyId = companyId;

    await ApiService.put(`/narrative/${id}`, narrative);
    narrative.id = id;
    toast.success("Relato atualizado com sucesso.");
  } catch (error) {
    handleApiErrors(error, "Erro ao atualizar o registro.");
    throw error;
  }
};

/**
 * Delete narrative
 * @param id
 */
const deleteById = async (id) => {
  try {
    const { id: idUser, companyId } = StorageHelper.getItem("user");

    await ApiService.delete(`/narrative/${id}`, {
      params: { idUser, companyId },
    });
    toast.success("Relato deletado com sucesso.");
  } catch (error) {
    handleApiErrors(error, "Erro ao apagar o registro.");
    throw error;
  }
};

/**
 * Sendd upload S3
 * @param UPLOAD
 * @returns
 */
const uploadS3 = async (upload) => {
  try {
    const formData = new FormData();
    const hasNewFile = upload instanceof File;
    if (!hasNewFile) {
      formData.append("file", upload[0]);
      const { data } = await ApiService.post("/upload", formData);
      if (data) {
        toast.success("Upload cadastrado com sucesso.");
        return data;
      }
    }

    return new Error("Erro ao enviar upload ao S3.");
  } catch (error) {
    handleApiErrors(error, "Erro ao enviar para o S3.");
    throw error;
  }
};

/**
 * Get all logs
 * @param page
 * @param filter
 * @returns
 */
const getAllLogs = async (skip, take, filter, sortDirection) => {
  try {
    const url = `/log`;

    const { data } = await ApiService.get(url, {
      params: { skip, take, filter, sortDirection },
    });
    if (data) {
      return {
        data: data.data,
        totalCount: data.headers,
      };
    }

    return new Error("Erro ao listar os registros.");
  } catch (error) {
    handleApiErrors(error, "Erro ao listar os registros.");
    throw error;
  }
};

/**
 * Create log edit
 * @param narrative
 * @returns
 */
const createLog = async (idUser, companyId, narrative, type, info) => {
  try {
    const log = {
      registration: narrative.code,
      changedInformation: info,
      type: type,
      companyId: companyId,
      userId: idUser,
      narrativeId: narrative.id,
      plansId: null,
      departmentsId: null,
    };
    await ApiService.post("/log", log);
  } catch (error) {
    handleApiErrors(error, "Erro ao criar o registro.");
    throw error;
  }
};

/**
 * Send email notification
 * @param email
 * @returns
 */
const sendEmail = async (to, from, subject, text) => {
  try {
    const emailBody = {
      to,
      from,
      subject,
      text,
    };
    await ApiService.post("/email", emailBody);
    toast.success("Email enviado com sucesso!");
  } catch (error) {
    handleApiErrors(error, "Erro ao enviar email.");
    throw error;
  }
};

const sendEmailMessage = async (request) => {
  try {
    await ApiService.post('/email/messages', request)
  } catch (error) {
    handleApiErrors(error, "Erro ao enviar email.");
    throw error;
  }
}

/**
 * updateStatusView narrative
 * @param id
 * @param narrative
 */
const updateStatusView = async (id, narrative) => {
  try {
    const { id: idUser, companyId } = StorageHelper.getItem("user");

    delete narrative.id;
    delete narrative.edit;
    delete narrative.createAt;
    delete narrative.updateAt;
    delete narrative.deleteAt;
    delete narrative.dateClosed;
    delete narrative.dateAnalysis;
    delete narrative.dateFiled;

    narrative.userId = idUser;
    narrative.companyId = companyId;

    await ApiService.put(`/narrative/updateStatusView/${id}`, narrative);
    narrative.id = id;
    toast.success("Relato status atualizado para visualizado.");
  } catch (error) {
    handleApiErrors(error, "Erro ao atualizar status do registro.");
    throw error;
  }
};


/**
 * Handle api errors
 * @param error
 */
export const handleApiErrors = (error, message) => {
  if (error && error.response && error.response.data) {
    switch (error.response.data.statusCode) {
      case 400:
        toast.error(
          "Erro ao processar a requisição, verifique os dados enviados e tente novamente!"
        );
        break;
      case 404:
        toast.error("Empresa não encontrada");
        break;
      case 500:
        toast.error(
          "Erro, o servidor não conseguiu processar a requisição, por favor tente novamente mais tarde ou contate o suporte!"
        );
        break;
      default:
        toast.error(message);
        break;
    }
  } else {
    toast.error(message);
    console.log("Erro de conexão.");
  }
};

export const NarrativeService = {
  getAll,
  create,
  updateById,
  deleteById,
  uploadS3,
  getAllLogs,
  createLog,
  sendEmail,
  sendEmailMessage,
  findByCodeNarrative,
  updateStatusView,
  findNarrativeByUser
};
