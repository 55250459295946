import './BlurText.css'
import React from 'react';
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function BlurText(props) {

    const [isActive, setActive] = useState("false");
    const ToggleClass = () => {
    setActive(!isActive); 
    };

    return (
        <>
            <span className={isActive ? "blur" : null }>
                {props.children}
            </span>
            <span className={isActive ? null : "visible" }>
                <button className="btn btn-link btn-icon" onClick={ToggleClass}>
                    <span className="icon-visible"><FaEye /></span>
                    <span className="icon-blur"><FaEyeSlash /></span>
                </button>
            </span>
        </>
    );

}
  
export default BlurText;