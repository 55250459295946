import { SET_NOTIFICATION } from "./types";

const setNotification = (notification) => ({
  type: SET_NOTIFICATION,
  payload: {
    notification,
  },
});

export default {
  setNotification,
};
