import { SET_LOADING } from "./types";

const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: {
    loading,
  },
});

export default {
  setLoading,
};
