import "./Identificacao.css";
import IconeAnonimo from "../../assets/img/icone-anonimo.png";
import IconeIdentificado from "../../assets/img/icone-identificado.png";
import React, { useState } from "react";
import Content from "../../components/Content/Content";
import TipoRelato from "../../components/Helper/TipoRelato";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Relacao from "../Relacao/Relacao";
import allActions from "../../store/actions";
import { StorageHelper } from "../../helpers";

function Identificacao() {
  const params = window.location.pathname.split("/");
  const { companyURL } = StorageHelper.getItem("url") || {companyURL: params[1]};
  const urlRelation = `/${companyURL}/relacao`;

  const { name } = !StorageHelper.getItem("user")
    ? ""
    : StorageHelper.getItem("user");

  const [showRelation, setShowRelation] = useState(false);
  const dispatch = useDispatch();  

  function onHandleClickNarrative(value) {
    const narrativeNew = {
      id: "",
      code: "",
      relationshipCompany: "",
      classification: "",
      subject: "",
      description: "",
      profile: "",
      recurrent: "",
      involved: "",
      nameInvolved: "",
      someOneElseInvolved: "",
      dateOccurred: "",
      noDate: "",
      partDay: "",
      spaceOccurred: "",
      evidence: "",
      evidenceURL: "",
      type: value,
      status: "",
      messages: "",
      companyId: "",
      company: "Teste ",
      userId: "",
    };

    StorageHelper.setItem('relatation', {isLoginRelacao: true});

    dispatch(allActions.narrative.setNarrative(false, narrativeNew));
    setShowRelation(true);
  }

  return (
    <Content>
      <div className="identificacao column-container">
        <div className="card">
          <div className="card-title">
            <h2>Gostaria de se identificar no relato?</h2>
            <p>
              Dúvidas? veja como escolher o <TipoRelato />
            </p>
          </div>
          <div className="card-body">
            {!showRelation && (
              <>
                <Link
                  to={urlRelation}
                  className="area-link p-7 mb-4"
                  onClick={() => onHandleClickNarrative("ANONYMOUS")}
                >
                  <div className="me-4">
                    <img
                      src={IconeAnonimo}
                      alt="Anoniimo"
                      className="d-block"
                    />
                  </div>
                  <div className="text-link">
                    <h3>Relato Anônimo</h3>
                    <p>
                      O relato será feito sem revelar sua identidade. Seus dados
                      pessoais não serão registrados.
                    </p>
                  </div>
                </Link>

                <Link
                  to={name ? urlRelation : `/${companyURL}/login`}
                  className="area-link"
                  onClick={() => onHandleClickNarrative("IDENTIFIED")}
                >
                  <div className="me-4">
                    <img
                      src={IconeIdentificado}
                      alt="Identificado"
                      className="d-block"
                    />
                  </div>
                  <div className="text-link">
                    <h3>Relato Identificado</h3>
                    <p>
                      O relato é confidencial, sua identidade será revelada
                      apenas para os administradores.
                    </p>
                  </div>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>

      {showRelation && <Relacao data={companyURL} />}
    </Content>
  );
}

export default Identificacao;
