import React, { useEffect, useState } from "react";
import Content from "../../../../components/Content/Content";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UserService } from "../../../../services/users";
import { Environment } from "../../../../environment";
import { DepartamentService } from "../../../../services/departament";
import { StorageHelper } from "../../../../helpers";
// import { toast } from 'react-toastify';

function EditUsuario(data) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ value: data });

  const { companyId } = StorageHelper.getItem("url");

  const [dataResponse, setDataResponse] = useState(data);
  const [departaments = [], setDepartaments] = useState();
  const [selectedStatus, setSelectedStatus] = useState(dataResponse.user.status);
  const [selectedType, setSelectedType] = useState(dataResponse.user.type);
  const [selectedDepartment, setSelectedDepartment] = useState(dataResponse.user.departmentId);
  
  const onSubmit = async (data) => {
    const user = {
      id: data.id,
      branch: data.branch,
      companyId: companyId,
      departmentId: Number(selectedDepartment),
      email: data.email,
      name: data.name,
      office: data.office,
      phone: data.phone,
      role: data.role,
      status: selectedStatus,
      type: selectedType,
    }

    if (data.id !== "") {
      await UserService.updateById(data.id, user);
    } else if (data.id === "") {
      await UserService.create(user);
    }
  };

  const getAllDepartament = async () => {
    const response = await DepartamentService.getAll();
    setDepartaments(response.data);
  };

  useEffect(() => {
    getAllDepartament();
  }, []);

  const handleChangeType = (e) => {
    dataResponse.user.type = e.target.value;
    setDataResponse(dataResponse);
    setSelectedType(e.target.value);
  };
  
  const handleChangeStatus = (e) => {
    dataResponse.user.status = e.target.value;
    setDataResponse(dataResponse);
    setSelectedStatus(e.target.value);
  };
  
  const handleChangeDepartment = (e) => {
    dataResponse.user.departmentId = Number(e.target.value);
    setDataResponse(dataResponse);
    setSelectedDepartment(e.target.value);
  };


  const handleEmail = () => {
    // toast.success("E-mail enviado!");
  };

  return (
    <>
      <h1 className="mb-4">Usuário</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card">
          <div className="card-body">
            <div className="row g-3">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    {...register("id")}
                    type="text"
                    defaultValue={dataResponse.user.id}
                    hidden
                  />

                  <label className="label-small mb-3">Status do Usuário</label>
                  <div className="form-check">
                    <input
                      {...register("status")}
                      type="radio"
                      className="form-check-input"
                      name="statusUsuario"
                      id="statusAtivo"
                      value="ACTIVE"
                      onChange={handleChangeStatus}
                      checked={selectedStatus === "ACTIVE"}
                    />
                    <label className="form-check-label" htmlFor="statusAtivo">
                      Ativo
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      {...register("status")}
                      type="radio"
                      className="form-check-input"
                      name="statusUsuario"
                      id="statusInativo"
                      value="INACTIVE"
                      onChange={handleChangeStatus}
                      checked={selectedStatus === "INACTIVE"}
                    />
                    <label className="form-check-label" htmlFor="statusInativo">
                      Inativo
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small mb-3">Tipo de Perfil</label>
                  <div className="form-check">
                    <input
                      {...register("type")}
                      type="radio"
                      className="form-check-input"
                      name="tipoUsuario"
                      id="perfilUsuario"
                      value="USER"
                      onChange={handleChangeType}
                      checked={selectedType === "USER"}
                    />
                    <label className="form-check-label" htmlFor="perfilUsuario">
                      Usuário
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      {...register("type")}
                      type="radio"
                      className="form-check-input"
                      name="tipoUsuario"
                      id="perfilAdmin"
                      value="ADMINISTRATOR"
                      onChange={handleChangeType}
                      checked={selectedType === "ADMINISTRATOR"}
                    />
                    <label className="form-check-label" htmlFor="perfilAdmin">
                      Administrador
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="label-small" htmlFor="nomeUsuario">
                    Nome <span className="label-msg">*</span>
                  </label>
                  <input
                    type="text"
                    {...register("name", { required: true })}
                    defaultValue={dataResponse.user.name}
                    className="form-control"
                    id="nomeUsuario"
                  />
                  {errors.nome && errors.nome.type === "required" && (
                    <div role="alert" className="form-alert mb-3">
                      Este campo é obrigatório
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small" htmlFor="departamentoUsuario">
                    Departamento
                  </label>
                  <div className="form-group">
                    <select
                      {...register("departmentId")}
                      defaultValue={dataResponse.user.departmentId}
                      className="form-select"
                      id="departamentoUsuario"
                      onChange={handleChangeDepartment}
                    >
                      {data.user.departmentId === "" && (
                        <option value="" key="">
                          Selecione
                        </option>
                      )}

                      {departaments &&
                        departaments.map((departament) => (
                          <option value={departament.id} key={departament.id}>
                            {departament.description}
                          </option>
                        ))}
                      
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small" htmlFor="cargoUsuario">
                    Cargo
                  </label>
                  <input
                    type="text"
                    {...register("office")}
                    defaultValue={dataResponse.user.office}
                    className="form-control"
                    id="cargoUsuario"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small" htmlFor="celularUsuario">
                    Celular
                  </label>
                  <InputMask
                    mask="(99) 99999-9999"
                    type="tel"
                    {...register("phone")}
                    defaultValue={dataResponse.user.phone}
                    className="form-control"
                    id="celularUsuario"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small" htmlFor="ramalUsuario">
                    Ramal
                  </label>
                  <input
                    type="number"
                    {...register("branch")}
                    defaultValue={dataResponse.user.branch}
                    className="form-control"
                    id="ramalUsuario"
                  />
                </div>
              </div>
              <h3>Acesso</h3>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-small" htmlFor="emailUsuario">
                    E-mail <span className="label-msg">*</span>
                  </label>
                  <input
                    type="email"
                    {...register("email", { required: true })}
                    defaultValue={dataResponse.user.email}
                    className="form-control"
                    id="emailUsuario"
                  />
                  {errors.email && errors.email.type === "required" && (
                    <div role="alert" className="form-alert mb-3">
                      É obrigatório um e-mail válido
                    </div>
                  )}
                </div>
              </div>
              {/* Apenas no Edit */}.
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="password" className="form-label label-small">
                    Enviar um link para o usuário{" "}
                    <strong>redefinir a senha</strong>
                  </label>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm d-block mt-1"
                    onClick={handleEmail}
                  >
                    Enviar link para redefinição
                  </button>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <button type="submit" className="btn btn-primary">
                  { dataResponse.user.id === '' ? 'Cadastrar' : 'Atualizar' }
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditUsuario;
